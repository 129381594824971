import { createSelector } from 'reselect'

import { vector, rangeValue, boxCenter } from '../utils/mathFunctions'
import {
  getChildOffset,
  getChildTopLeft2,
  globalPointToLocalPoint,
  localPointToGlobalPoint2,
  getChildCenter,
  getFitChildScale,
  getCoverChildScale,
  zoomChildAtGlobalPoint,
  centerAtLocalPoint,
  setLocalBoxVisible
} from '../utils/centeredChild'

export const ROTATOR_SET_CHILD_FIT_MODE = 'ROTATOR_SET_CHILD_FIT_MODE'
export const ROTATOR_SET_MODE = 'ROTATOR_SET_MODE'
export const ROTATOR_RESET = 'ROTATOR_RESET'
export const ROTATOR_SET_N_STEPS = 'ROTATOR_SET_N_STEPS'
export const ROTATOR_SET_STEP = 'ROTATOR_SET_STEP'
export const ROTATOR_SET_SCALE = 'ROTATOR_SET_SCALE'
export const ROTATOR_SET_SCALE_MIN = 'ROTATOR_SET_SCALE_MIN'
export const ROTATOR_SET_SCALE_MAX = 'ROTATOR_SET_SCALE_MAX'
export const ROTATOR_ZOOM_AT_POINT = 'ROTATOR_ZOOM_AT_POINT'
export const ROTATOR_SET_TRANSLATION = 'ROTATOR_SET_TRANSLATION'
export const ROTATOR_SET_TRANSLATION_X_MIN = 'ROTATOR_SET_TRANSLATION_X_MIN'
export const ROTATOR_SET_TRANSLATION_X_MAX = 'ROTATOR_SET_TRANSLATION_X_MAX'
export const ROTATOR_SET_TRANSLATION_Y_MIN = 'ROTATOR_SET_TRANSLATION_Y_MIN'
export const ROTATOR_SET_TRANSLATION_Y_MAX = 'ROTATOR_SET_TRANSLATION_Y_MAX'
export const ROTATOR_SET_BOUNDS = 'ROTATOR_SET_BOUNDS'
export const ROTATOR_SET_IMAGE_SIZE = 'ROTATOR_SET_IMAGE_SIZE'
export const ROTATOR_SHOW_BOX = 'ROTATOR_SHOW_BOX'
export const ROTATOR_CENTER_AT_POINT = 'ROTATOR_CENTER_AT_POINT'
export const ROTATOR_LOADING_CONTENT = 'ROTATOR_LOADING_CONTENT'
export const ROTATOR_SET_ENABLED = 'ROTATOR_SET_ENABLED'
export const ROTATOR_SET_SPEED = 'ROTATOR_SET_SPEED'
export const ROTATOR_MOUSE_INC = 'ROTATOR_MOUSE_INC'

export const ZOOM_FACTOR = 0.1
export const DEFAULT_SPEED = 1000
export const DEFAULT_MOUSE_INC = 10

export const MODE = {
  DISABLE: 'DISABLE',
  BUTTONS: 'BUTTONS',
  ROT_AUTOMATIC: 'ROT_AUTOMATIC',
  ROT_MOUSE: 'ROT_MOUSE',
  PAN: 'PAN',
  PANNING: 'PANNING'
}

export const CHILD_FIT_MODE = {
  FREE: 'FREE',
  COVER: 'COVER'
}

const initialState = {
  fitMode: CHILD_FIT_MODE.COVER,
  stepCount: 0,
  step: 0,
  scale: 1,
  scaleMin: 0.1,
  scaleMax: 100,
  translation: vector(), //Desplazamiento sin escala
  translationXMin: 0,
  translationXMax: 0,
  translationYMin: 0,
  translationYMax: 0,
  mode: MODE.BUTTONS,
  bounds: null,
  imageSize: { width: 0, height: 0 },
  loading: false,
  enabled: true,
  speed: DEFAULT_SPEED,
  mouseInc: DEFAULT_MOUSE_INC
}

const denormalizeTranslation = (translation, scale) => {
  return {
    x: translation.x * scale,
    y: translation.y * scale
  }
}

const normalizeTranslation = (translation, scale) => {
  return {
    x: translation.x / scale,
    y: translation.y / scale
  }
}

const truncateTranslation = (translation, rangeX, rangeY) => {
  const x = rangeValue(translation.x, rangeX[0], rangeX[1])
  const y = rangeValue(translation.y, rangeY[0], rangeY[1])
  return { x, y }
}

const translationCoverLimits = (bounds, imageSize, scale) => {
  let x = 0
  let y = 0
  if (bounds && imageSize) {
    x = (scale * imageSize.width - bounds.width) / (2 * scale)
    y = (scale * imageSize.height - bounds.height) / (2 * scale)
  }
  return {
    xMin: -x,
    xMax: x,
    yMin: -y,
    yMax: y
  }
}

const updateCover = (
  state,
  newBounds,
  newImageSize,
  newScale,
  newTranslation
) => {
  let scaleMin = getCoverChildScale(newImageSize, {
    width: newBounds ? newBounds.width : 0,
    height: newBounds ? newBounds.height : 0
  })
  const scale = rangeValue(newScale, scaleMin, state.scaleMax)
  const { xMin, xMax, yMin, yMax } = translationCoverLimits(
    newBounds,
    newImageSize,
    scale
  )
  return {
    ...state,
    bounds: newBounds,
    imageSize: newImageSize,
    scale,
    scaleMin: scaleMin,
    translation: truncateTranslation(
      newTranslation,
      [xMin, xMax],
      [yMin, yMax]
    ),
    translationXMin: xMin,
    translationXMax: xMax,
    translationYMin: yMin,
    translationYMax: yMax
  }
}

// const getNextStep = (stepCount, currentStep, stepInc) => {
//   if (stepCount <= 1) {
//     return 0
//   }
//   let step = currentStep
//   step += stepInc
//   step %= stepCount
//   if (step < 0) {
//     step += stepCount
//   }
//   return step
// }

const getNextStep = (stepCount, currentStep, stepInc) => {
  if (stepCount <= 1) {
    return 0
  }
  return Math.min(Math.max(0, currentStep + stepInc), stepCount - 1)
}

const changeState = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case ROTATOR_SET_MODE:
      return {
        ...state,
        mode: action.mode
      }
    case ROTATOR_SET_CHILD_FIT_MODE:
      if (state.fitMode === CHILD_FIT_MODE.COVER) {
        return {
          ...updateCover(
            state,
            state.bounds,
            state.imageSize,
            state.scale,
            state.translation
          ),
          fitMode: action.mode
        }
      }
      return { ...state, fitMode: action.mode }
    case ROTATOR_RESET: {
      if (state.fitMode === CHILD_FIT_MODE.COVER) {
        return {
          ...updateCover(state, state.bounds, state.imageSize, 0, vector()),
          step: 0
        }
      }
      return {
        ...state,
        step: 0,
        translation: vector(),
        scale: getFitChildScale(state.imageSize, {
          width: state.bounds.width,
          height: state.bounds.height
        })
      }
    }
    case ROTATOR_SET_N_STEPS:
      return {
        ...state,
        stepCount: action.nSteps,
        step: getNextStep(action.nSteps, state.step, 0)
      }
    case ROTATOR_SET_STEP:
      if (state.loading || !state.enabled) {
        return state
      }
      return {
        ...state,
        step: getNextStep(state.stepCount, action.step, 0)
      }
    case ROTATOR_SET_SCALE:
      return {
        ...state,
        scale: rangeValue(action.scale, state.scaleMin, state.scaleMax)
      }
    case ROTATOR_ZOOM_AT_POINT: {
      const currentTranslation = denormalizeTranslation(
        state.translation,
        state.scale
      )
      const { scale, translation } = zoomChildAtGlobalPoint(
        state.scale,
        [state.scaleMin, state.scaleMax],
        currentTranslation,
        action.factor,
        action.point,
        state.bounds
      )
      const normTranslation = normalizeTranslation(translation, scale)

      if (state.fitMode === CHILD_FIT_MODE.COVER) {
        return updateCover(
          state,
          state.bounds,
          state.imageSize,
          scale,
          normTranslation
        )
      }

      return {
        ...state,
        translation: normTranslation,
        scale
      }
    }
    case ROTATOR_SET_TRANSLATION:
      if (state.fitMode === CHILD_FIT_MODE.COVER) {
        return {
          ...state,
          translation: truncateTranslation(
            action.translation,
            [state.translationXMin, state.translationXMax],
            [state.translationYMin, state.translationYMax]
          )
        }
      }
      return {
        ...state,
        translation: action.translation
      }
    case ROTATOR_SET_BOUNDS: {
      if (state.fitMode === CHILD_FIT_MODE.COVER) {
        return updateCover(
          state,
          action.bounds,
          state.imageSize,
          state.scale,
          state.translation
        )
      }
      return {
        ...state,
        bounds: action.bounds
      }
    }

    case ROTATOR_SET_IMAGE_SIZE:
      if (state.fitMode === CHILD_FIT_MODE.COVER) {
        return updateCover(
          state,
          state.bounds,
          action.size,
          state.scale,
          state.translation
        )
      }
      return { ...state, imageSize: action.size }
    case ROTATOR_SHOW_BOX:
      //No esta adaptado a cover
      const currentTranslation = denormalizeTranslation(
        state.translation,
        state.scale
      )
      const { scale, translation } = setLocalBoxVisible(
        action.box,
        currentTranslation,
        state.scale,
        [state.scaleMin, state.scaleMax],
        state.imageSize,
        state.bounds
      )
      return {
        ...state,
        translation: normalizeTranslation(translation, scale),
        scale
      }
    case ROTATOR_CENTER_AT_POINT: {
      //No esta adaptado a cover
      const currentTranslation = denormalizeTranslation(
        state.translation,
        state.scale
      )
      const translation = centerAtLocalPoint(
        action.point,
        currentTranslation,
        state.scale,
        state.imageSize,
        state.bounds
      )
      return {
        ...state,
        translation: normalizeTranslation(translation, state.scale)
      }
    }
    case ROTATOR_LOADING_CONTENT:
      return { ...state, loading: action.loading }
    case ROTATOR_SET_ENABLED:
      return { ...state, enabled: action.enabled }
    case ROTATOR_SET_SPEED:
      return { ...state, speed: action.speed ? action.speed : DEFAULT_SPEED }
    case ROTATOR_MOUSE_INC:
      return {
        ...state,
        mouseInc: action.mouseInc
          ? Math.abs(action.mouseInc)
          : DEFAULT_MOUSE_INC
      }
    default:
      return state
  }
}
export default changeState

export const setChildFitMode = (mode) => ({
  type: ROTATOR_SET_CHILD_FIT_MODE,
  mode
})

export const setNSteps = (nSteps) => ({
  type: ROTATOR_SET_N_STEPS,
  nSteps
})

export const setStep = (step) => ({
  type: ROTATOR_SET_STEP,
  step
})

export const rotate = (steps) => (dispatch, getState) => {
  const state = getState()
  const currentStep = stepSelector(state)
  dispatch({
    type: ROTATOR_SET_STEP,
    step: currentStep + steps
  })
}

export const setMode = (mode) => ({
  type: ROTATOR_SET_MODE,
  mode
})

export const setScale = (scale) => ({
  type: ROTATOR_SET_SCALE,
  scale
})

export const setScaleMin = (min) => ({
  type: ROTATOR_SET_SCALE_MIN,
  min
})

export const setScaleMax = (max) => ({
  type: ROTATOR_SET_SCALE_MAX,
  max
})

export const setTranslation = (value) => ({
  type: ROTATOR_SET_TRANSLATION,
  translation: value
})

export const setTranslationXMin = (min) => ({
  type: ROTATOR_SET_TRANSLATION_X_MIN,
  min
})

export const setTranslationXMax = (max) => ({
  type: ROTATOR_SET_TRANSLATION_X_MAX,
  max
})

export const setTranslationYMin = (min) => ({
  type: ROTATOR_SET_TRANSLATION_Y_MIN,
  min
})

export const setTranslationYMax = (max) => ({
  type: ROTATOR_SET_TRANSLATION_Y_MAX,
  max
})

export const zoomAtGlobalPoint = (factor, globalPoint) => ({
  type: ROTATOR_ZOOM_AT_POINT,
  factor,
  point: globalPoint
})

export const zoomAtCenter = (factor) => (dispatch, getState) => {
  const state = getState()
  const bounds = boundsSelector(state)
  dispatch({
    type: ROTATOR_ZOOM_AT_POINT,
    factor,
    point: boxCenter(bounds.top, bounds.left, bounds.width, bounds.height)
  })
}

export const resetView = () => ({
  type: ROTATOR_RESET
})

export const setRotatorBounds = (bounds) => ({
  type: ROTATOR_SET_BOUNDS,
  bounds
})

export const setImageSize = (size) => ({
  type: ROTATOR_SET_IMAGE_SIZE,
  size
})

export const setBoxVisible = (box) => ({
  type: ROTATOR_SHOW_BOX,
  box
})

export const centerAtPoint = (point) => ({
  type: ROTATOR_CENTER_AT_POINT,
  point
})

export const setLoadingContent = (loading) => ({
  type: ROTATOR_LOADING_CONTENT,
  loading
})

export const setEnabled = (enabled) => ({
  type: ROTATOR_SET_ENABLED,
  enabled
})

export const setSpeed = (speed) => ({
  type: ROTATOR_SET_SPEED,
  speed
})

export const setMouseInc = (mouseInc) => ({
  type: ROTATOR_MOUSE_INC,
  mouseInc
})

export const stepSelector = (state) => state.rotator.step
export const scaleSelector = (state) => state.rotator.scale
export const scaleMinSelector = (state) => state.rotator.scaleMin
export const scaleMaxSelector = (state) => state.rotator.scaleMax
export const translationSelector = (state) => state.rotator.translation
export const stepCountSelector = (state) => state.rotator.stepCount
export const modeSelector = (state) => state.rotator.mode
export const boundsSelector = (state) => state.rotator.bounds
export const imageSizeSelector = (state) => state.rotator.imageSize
export const loadingSelector = (state) => state.rotator.loading
export const enabledSelector = (state) => state.rotator.enabled
export const speedSelector = (state) => state.rotator.speed
export const mouseIncSelector = (state) => state.rotator.mouseInc

export const getScaleRange = (state) => {
  return [scaleMinSelector(state), scaleMaxSelector(state)]
}
export const getStepLength = createSelector(
  [stepCountSelector],
  (stepCount) => {
    if (stepCount <= 1) {
      return 360
    }
    return 360 / stepCount
  }
)

//Desplazamiento con la escala aplicada
export const getTranslation = createSelector(
  [translationSelector, imageSizeSelector, scaleSelector],
  (translation, imageSize, scale) => {
    return denormalizeTranslation(translation, scale)
  }
)

//Centro del container
export const getGlobalCenter = createSelector([boundsSelector], (bounds) => {
  if (!bounds) {
    return { x: 0, y: 0 }
  }
  return boxCenter(bounds.top, bounds.left, bounds.width, bounds.height)
})

//Centro de la imagen
export const getGlobalImageCenter = createSelector(
  [boundsSelector, getTranslation],
  (bounds, translation) => {
    return getChildCenter(bounds, translation)
  }
)

//Desplazamiento de la imagen para que este centrada en el canvas.
export const getImageOffset = createSelector(
  [boundsSelector, scaleSelector, getTranslation, imageSizeSelector],
  (bounds, scale, translation, imageSize) => {
    return getChildOffset(scale, translation, imageSize, bounds)
  }
)

//Punto topLeft de la imagen
export const getGlobalImageTopLeft = createSelector(
  [getImageOffset, boundsSelector],
  (offset, bounds) => {
    return getChildTopLeft2(offset, bounds)
  }
)

//Punto de la imagen en coordenadas globales
export const imagePointToGlobalPoint = createSelector(
  [boundsSelector, getImageOffset],
  (bounds, offset) => (x, y) => {
    return localPointToGlobalPoint2({ x, y }, offset, bounds)
  }
)

//Punto global a coordenadas imagen
export const globalPointToImagePoint = createSelector(
  [scaleSelector, getGlobalImageTopLeft],
  (scale, topLeft) => (x, y) => {
    return globalPointToLocalPoint({ x, y }, scale, topLeft)
  }
)
