export const settings = {
  wordpress: {
    // url: 'http://localhost/wordpress/',
    // url: 'http://laslomasdelhigueron.atwebpages.com/',
    // url: 'http://areadesigndemo3d.epizy.com/',
    // url: 'http://areadesigndemo3d.atwebpages.com/',
    url: 'https://asde.basuravariada2.online/root/wordpress/',
    sendEmail: 'wp-json/contact/v1/send'
  },
  map: {
    apiKey: 'AIzaSyDA4CxNwYLG-eFZ0uAc-8o87865EF93ePY',
    defaultPoint: { lat: 36.71928741648208, lng: -4.415700032540511 }
  }
}
