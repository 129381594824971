import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import List from '@material-ui/core/List'

import MenuPanelContent from '../Menu/MenuPanelContent'
import DownloadItem from './DownloadItem'

import { downloadSelector } from '../../modules/reducerDocuments'

const useStyles = makeStyles((theme) => ({
  list: {
    color: theme.palette.primary.contrastText,
    paddingTop: 0
  },
  emptyText: { color: theme.palette.primary.contrastText }
}))

const DownloadMenu = () => {
  const classes = useStyles()
  const downloads = useSelector(downloadSelector)

  return (
    <MenuPanelContent className={classes.root}>
      {downloads.length > 0 ? (
        <List className={classes.list}>
          {downloads.map((docId, idx) => (
            <DownloadItem key={idx} id={docId} />
          ))}
        </List>
      ) : (
        <div className={classes.emptyText}>Nada que descargar</div>
      )}
    </MenuPanelContent>
  )
}

export default DownloadMenu
