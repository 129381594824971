import { useCallback, useEffect } from 'react'
// import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'

import FilterSlider from './FilterSlider'
import { getCurrentPromocion } from '../../../modules/reducerPromocion'
import {
  priceRangeSelector,
  setPriceRange,
  getSliderPriceRange
} from '../../../modules/reducerHousesFilter'

// const useStyles = makeStyles((theme) => ({}))

const Price = ({ id }) => {
  // const classes = useStyles()
  const priceRange = useSelector(priceRangeSelector)
  const sliderPriceRange = useSelector(getSliderPriceRange)
  const promocion = useSelector(getCurrentPromocion)
  const currency = promocion ? promocion.currency : ''
  const dispatch = useDispatch()

  useEffect(() => {
    //Ajusta priceRange al principio
    if (
      priceRange[0] < sliderPriceRange[0] ||
      priceRange[1] > sliderPriceRange[1]
    ) {
      dispatch(setPriceRange(sliderPriceRange))
    }
  }, [dispatch, sliderPriceRange, priceRange])

  const handleChange = useCallback(
    (e, newValue) => {
      dispatch(setPriceRange(newValue))
    },
    [dispatch]
  )

  return (
    <FilterSlider
      title="Precio"
      min={sliderPriceRange[0]}
      max={sliderPriceRange[1]}
      range={priceRange}
      step={1000}
      unit={currency}
      onRangeChange={handleChange}
    />
  )
}
export default Price
