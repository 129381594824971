import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'

import RemoveFiltersFooter from '../../Common/RemoveFiltersFooter'
import { resetFilter } from '../../../modules/reducerHousesFilter'

// const useStyles = makeStyles((theme) => ({
// }))

const FilterMenuFooter = () => {
  // const classes = useStyles()
  const dispatch = useDispatch()

  const handleRemoveFilters = useCallback(
    (selection) => {
      dispatch(resetFilter())
    },
    [dispatch]
  )

  return <RemoveFiltersFooter onClick={handleRemoveFilters} />
}
export default FilterMenuFooter
