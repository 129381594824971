import { useCallback } from 'react'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import GradientText from '../Common/GradientText'
import NextIcon from '../Icons/NextIcon'
import GeneralIcon from '../Icons/GeneralIcon'
import MapIcon from '../Icons/MapIcon'
import HouseIcon from '../Icons/HouseIcon'
import ImagesIcon from '../Icons/ImagesIcon'
import DocumentIcon from '../Icons/DocumentIcon'
import DownloadMenuOption from '../Downloads/DownloadMenuOption'

import {
  currentMenuOptionExpandedSelector,
  optionClicked,
  getMenuOption,
  currentMenuOptionSelector,
  MENU_OPTIONS
} from '../../modules/reducerMain'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: theme.componentsData.menuOption.width,
    height: theme.componentsData.menuOption.height,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column',
    backgroundColor: ({ selected }) =>
      selected
        ? theme.palette.secondary.hover
        : theme.palette.primary.mainTransparent,
    color: ({ selected }) =>
      selected
        ? theme.palette.secondary.main
        : theme.palette.primary.contrastText,
    // opacity: ({ selected }) => (selected ? 0.85 : ''),
    '&:hover': {
      color: theme.palette.secondary.main,

      // backgroundImage: theme.palette.secondary.gradientHorizontal
      backgroundColor: theme.palette.secondary.hover
    }
  },
  iconWrapper: {
    width: '40px',
    height: '40px',
    flexShrink: 0,
    margin: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    stroke: 'currentColor',
    width: 32,
    height: '100%'
  },
  nextIcon: {
    position: 'absolute',
    right: 8,
    fill: 'currentColor',
    width: 20,
    transform: ({ expanded }) => (expanded ? 'rotate(180deg)' : 'rotate(0deg)')
  },
  title: { color: 'currentColor', paddingTop: 4 }
}))

const MenuOption = ({ optionId }) => {
  const currentMenuOption = useSelector(currentMenuOptionSelector)
  const currentMenuOptionExpanded = useSelector(
    currentMenuOptionExpandedSelector
  )
  const selected = currentMenuOption === optionId
  const expanded = selected && currentMenuOptionExpanded
  const gMenuOption = useMemo(getMenuOption, [])
  const option = useSelector((state) => gMenuOption(state, optionId))
  const dispatch = useDispatch()
  const classes = useStyles({ selected, expanded })

  const getIcon = () => {
    switch (optionId) {
      case MENU_OPTIONS.VISTA_GENERAL:
        return <GeneralIcon className={classes.icon} />
      case MENU_OPTIONS.PLANO_SITUACION:
        return <MapIcon className={classes.icon} />
      case MENU_OPTIONS.SELECCIONA_VIVIENDA:
        return <HouseIcon className={classes.icon} />
      case MENU_OPTIONS.GALERIA_IMAGENES:
        return <ImagesIcon className={classes.icon} />
      case MENU_OPTIONS.DOCUMENTOS:
        return <DocumentIcon className={classes.icon} />
      case MENU_OPTIONS.DESCARGAS:
        return <DownloadMenuOption className={classes.icon} />
      default:
        return null
    }
  }

  const handleClick = useCallback(() => {
    dispatch(optionClicked(optionId))
  }, [dispatch, optionId])

  return option ? (
    <div className={classes.root} onClick={handleClick}>
      <div className={classes.iconWrapper}>{getIcon()}</div>
      <GradientText normal={!selected} variant="h5" className={classes.title}>
        {option.name}
      </GradientText>
      {option.expandable && <NextIcon className={classes.nextIcon} />}
    </div>
  ) : null
}
export default MenuOption
//
