const GeneralIcon = (props) => {
  return (
    <svg fill="none" viewBox="0 0 32 39" {...props}>
      <path
        stroke="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M17.667 14.043H1V38.5h16.667V14.044z"
      />
      <path
        stroke="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M31 38.5H17.667V14.044h-3.334V1H31v37.5z"
      />
      <path
        stroke="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M27.667 33.609h-5v4.89h5v-4.89zm-18.334 0h-5v4.89h5v-4.89zM1 17.304h6.667M1 20.565h5m8.333-13.043H21M14.333 4.26h10m-10 6.523h5M1 23.826h3.333"
      />
    </svg>
  )
}
export default GeneralIcon
