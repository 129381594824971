import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

// import HousesTable from '../HousesTable/HousesTable'
// import HousesGallery from '../HousesGallery/HousesGallery'
import HousesRotator from '../House/Rotator/HousesRotator'
import House3DView from '../House/3DView/House3DView'
import GeneralView from '../GeneralView/GeneralView'
import Mapa from '../Map/Map'
// import AerialView from '../AerialView/AerialView'
// import PersonView from '../PersonView/PersonView'
// import Plano3D from '../Plano3D/Plano3D'
// import VisitaVirtual from '../VisitaVirtual/VisitaVirtual'
import DocumentViewer from '../Documents/DocumentViewer'
import Gallery from '../Gallery/Gallery'
// import VideoPlayer from '../VideoPlayer/VideoPlayer'
// import VirtualTour from '../VirtualTour/VirtualTour'

import {
  currentMenuOptionSelector,
  currentSubmenuSelector,
  houseDetails3dModeSelector,
  getMobileMode,
  menuOpenSelector,
  currentMenuOptionExpandedSelector,
  MENU_OPTIONS,
  SUBMENUS
} from '../../modules/reducerMain'

import { openSelector } from '../../modules/reducerContact'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%'
  },
  fondo: { width: '100%', height: '100%', objectFit: 'cover' }
}))

const Center = () => {
  const menuOpen = useSelector(menuOpenSelector)
  const contactFormOpen = useSelector(openSelector)
  const mobileMode = useSelector(getMobileMode)
  const currentSubmenu = useSelector(currentSubmenuSelector)
  const currentMenuOption = useSelector(currentMenuOptionSelector)
  const currentMenuOptionExpanded = useSelector(
    currentMenuOptionExpandedSelector
  )
  const houseDetails3dMode = useSelector(houseDetails3dModeSelector)

  const classes = useStyles({ menuOpen })
  const theme = useTheme()

  const getCenter = () => {
    if (currentMenuOption === MENU_OPTIONS.PLANO_SITUACION) {
      return <Mapa />
    } else if (currentSubmenu === SUBMENUS.GALERIAS_PROMOCION) {
      return <Gallery mobile={mobileMode} />
    } else if (currentSubmenu === SUBMENUS.DOCUMENTOS_VIVIENDA) {
      return <DocumentViewer />
    } else if (currentSubmenu === SUBMENUS.DOCUMENTOS_PROMOCION) {
      return <DocumentViewer />
    } else if (currentSubmenu === SUBMENUS.DETALLES_CASA) {
      return houseDetails3dMode ? (
        <House3DView />
      ) : (
        <Gallery mobile={mobileMode} />
      )
    } else if (currentMenuOption === MENU_OPTIONS.SELECCIONA_VIVIENDA) {
      return (
        <HousesRotator
          tooltipsMargins={[
            120,
            contactFormOpen
              ? theme.componentsData.contactForm.width +
                theme.componentsData.contactForm.right
              : 0,
            0,
            menuOpen && currentMenuOptionExpanded
              ? theme.componentsData.menuMainButton.left +
                theme.componentsData.menuOption.width +
                theme.componentsData.menuPanel.width
              : menuOpen
              ? theme.componentsData.menuMainButton.left +
                theme.componentsData.menuOption.width
              : 0
          ]}
        />
      )
    }
    return (
      <GeneralView />
      // <img className={classes.fondo} src={'./images/fondo.jpg'} alt="fondo" />
    )
    // if (currentSubmenu === SUBMENUS.DETALLES_CASA) {
    //   return houseDetailsPlano3DMode ? <Plano3D /> : <VisitaVirtual />
    // } else if (currentSubmenu === SUBMENUS.DOCUMENTOS_VIVIENDA) {
    //   return <PdfViewer mobile={mobileMode} />
    // } else if (currentSubmenu === SUBMENUS.GALERIAS_VIVIENDA) {
    //   return mobileMode ? <GalleryMobile /> : <Gallery />
    // } else if (currentMenuOption === MENU_OPTIONS.PLANO_SITUACION) {
    //   return <Mapa />
    // } else if (currentMenuOption === MENU_OPTIONS.VISTA_AEREA) {
    //   return <AerialView />
    // } else if (currentMenuOption === MENU_OPTIONS.VISITAS_VIRTUALES) {
    //   return <VirtualTour />
    // } else if (currentMenuOption === MENU_OPTIONS.VISTA_PEATONAL) {
    //   return <PersonView />
    // } else if (currentMenuOption === MENU_OPTIONS.SELECCIONA_VIVIENDA) {
    //   if (filterListViewMode) {
    //     return mobileMode ? <HousesGallery /> : <HousesTable />
    //   } else {
    //     return <HousesRotator />
    //   }
    // } else if (currentMenuOption === MENU_OPTIONS.DOCUMENTOS) {
    //   return <PdfViewer mobile={mobileMode} />
    // } else if (currentMenuOption === MENU_OPTIONS.GALERIA_IMAGENES) {
    //   return mobileMode ? <GalleryMobile /> : <Gallery />
    // } else if (currentMenuOption === MENU_OPTIONS.VIDEOS) {
    //   return <VideoPlayer master={true} />
    // }
    //
    // return <GeneralView />
  }

  return <div className={classes.root}>{getCenter()}</div>
}
export default Center
