const MenuIcon = (props) => {
  return (
    <svg fill="none" viewBox="0 0 27 17" {...props}>
      <path
        stroke="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M26 1H1m25 15H1"
      />
    </svg>
  )
}
export default MenuIcon
