const MapIcon = (props) => {
  return (
    <svg fill="none" viewBox="0 0 32 32" {...props}>
      <path
        stroke="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M10.13 7.522v20.87m9.131-14.349V1m-1.305 21.783L10.13 28.39 1 21.87V1l9.13 6.522L19.26 1l9.131 6.522v6.521"
      />
      <path
        stroke="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M31 21.87c0 2.87-5.217 9.13-5.217 9.13s-5.218-6.26-5.218-9.13c0-2.87 2.348-5.218 5.218-5.218S31 19 31 21.87z"
      />
      <path
        stroke="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M25.783 23.826a1.956 1.956 0 100-3.913 1.956 1.956 0 000 3.913z"
      />
    </svg>
  )
}
export default MapIcon
