// import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import HousesTable from '../Table/HousesTable'
import { getFinalTableHouses } from '../../../modules/reducerHousesTable'

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    padding: `24px ${0}px 0 ${theme.componentsData.menuPanel.paddingLeft}px`,
    width: '100%',
    overflow: 'auto',

    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    color: theme.palette.primary.contrastText
  },
  title: { paddingBottom: 12 }
}))

const FilterTable = () => {
  const classes = useStyles()
  const houses = useSelector(getFinalTableHouses)

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        Listado de viviendas
      </Typography>
      <HousesTable />
      {houses.length === 0 && (
        <Typography variant="h5" className={classes.title}>
          No hay ninguna vivienda con esas características
        </Typography>
      )}
    </div>
  )
}
export default FilterTable
