import { useEffect, useCallback, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'

import Rotator from '../Rotator/Rotator'
import SegmentsCanvas from './SegmentsCanvas'
import SegmentTooltip from './SegmentTooltip'
import {
  stepSelector,
  scaleSelector,
  setTranslation,
  getTranslation,
  setLoadingContent,
  setEnabled
} from '../../modules/reducerRotator'
// import { getMobileMode } from '../../modules/reducerMain'
import {
  getCurrentGroup,
  imagesSelector,
  setImages,
  mouseOverSegment
} from '../../modules/reducerRotatorGroups'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.other.backgroundColor,
    position: 'relative'
  },
  tooltipWindow: {
    position: 'fixed',
    top: ({ tooltipsMargins }) =>
      tooltipsMargins && tooltipsMargins[0] ? tooltipsMargins[0] : 0,
    right: ({ tooltipsMargins }) =>
      tooltipsMargins && tooltipsMargins[1] ? tooltipsMargins[1] : 0,
    bottom: ({ tooltipsMargins }) =>
      tooltipsMargins && tooltipsMargins[2] ? tooltipsMargins[2] : 0,
    left: ({ tooltipsMargins }) =>
      tooltipsMargins && tooltipsMargins[3] ? tooltipsMargins[3] : 0,
    zIndex: theme.zIndex.rotator - 1
  }
}))

const SegmentsRotator = ({
  tooltips, //[{ segmentId,content,poiId }]
  tooltipsMargins, //[top right bottom left]
  drawSegment,
  onSegmentClick
}) => {
  const scale = useSelector(scaleSelector)
  const translation = useSelector(getTranslation)
  const currentGroup = useSelector(getCurrentGroup)
  const currentStep = useSelector(stepSelector)
  const classes = useStyles({ tooltipsMargins })
  const dispatch = useDispatch()
  const images = useSelector(imagesSelector)
  const boundariesRef = useRef(null)
  const [canvasBounds, setCanvasBounds] = useState(null)
  // const small = useSelector(getMobileMode)

  // const [play] = useSound(ring)

  const loadImages = (images) => {
    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => {
          resolve(img)
        }
        img.onerror = img.onabort = () => {
          reject(src)
        }
        img.src = src
      })
    }
    const promises = []
    for (let i = 0; i < images.length; i++) {
      promises.push(loadImage(images[i]))
    }
    return Promise.all(promises)
  }

  useEffect(() => {
    if (images.length === 0 && currentGroup && currentGroup.images.length > 0) {
      dispatch(setTranslation({ x: 0, y: 0 }))
      dispatch(setLoadingContent(true))
      loadImages([currentGroup.images[0]]).then(
        (imgs) => {
          loadImages(currentGroup.images).then(
            (imgs) => {
              dispatch(setLoadingContent(false))
              dispatch(setImages(imgs))
            },
            (errImg) => {}
          )
        },
        (errImg) => {}
      )
    }
  }, [dispatch, currentGroup, images])

  const handleSegmentClick = useCallback(
    (segmentId) => {
      if (onSegmentClick) {
        onSegmentClick(segmentId)
      }
      dispatch(mouseOverSegment(segmentId))
    },
    [dispatch, onSegmentClick]
  )

  const handleSegmentHoverChanged = useCallback(
    (segmentId) => {
      dispatch(mouseOverSegment(segmentId))
      // if (segmentId) {
      //   play()
      // }
    },
    [dispatch]
  )

  const handleDrawStart = useCallback(() => {
    dispatch(setEnabled(false))
  }, [dispatch])

  const handleDrawEnd = useCallback(() => {
    dispatch(setEnabled(true))
  }, [dispatch])

  const handleBoundsChanged = useCallback((bounds) => {
    setCanvasBounds(bounds)
  }, [])

  return (
    <div className={classes.root}>
      {currentGroup ? (
        <>
          <Rotator>
            <SegmentsCanvas
              scale={scale}
              translation={translation}
              imageSrc={currentGroup.images[currentStep]}
              imageSize={currentGroup.imageSize}
              segments={currentGroup.segments[currentStep]}
              drawSegment={drawSegment}
              onBoundsChanged={handleBoundsChanged}
              onSegmentClick={handleSegmentClick}
              onSegmentHoverChanged={handleSegmentHoverChanged}
              onDrawStart={handleDrawStart}
              onDrawEnd={handleDrawEnd}
            />
          </Rotator>
          {tooltips.map((tooltip, idx) => (
            <SegmentTooltip
              key={idx}
              boundariesRef={boundariesRef}
              open={true}
              scale={scale}
              translation={translation}
              segmentId={tooltip.segmentId}
              poiId={tooltip.poiId ? tooltip.poiId : 'centroid'}
              childSize={currentGroup.imageSize}
              parentBounds={canvasBounds}
            >
              {tooltip.content}
            </SegmentTooltip>
          ))}
        </>
      ) : null}
      <div ref={boundariesRef} className={classes.tooltipWindow}></div>
    </div>
  )
}
export default SegmentsRotator
