import { useState, useRef, useEffect, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'

// import {} from '../../modules/reducerMain'

import { setPromocionByName } from '../../modules/reducerPromocion'
import { optionClicked, MENU_OPTIONS } from '../../modules/reducerMain'

import PanoWindow from './Window/PanoWindow'
const useStyles = makeStyles((theme) => ({
  root: { position: 'relative', width: '100%', height: '100%' },
  iframe: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    border: 0
  }
}))

const Krpano = ({ src }) => {
  const classes = useStyles()
  const [loaded, setLoaded] = useState(false)
  const krpano = useRef(null)
  const pano = useRef(null)
  const dispatch = useDispatch()

  const [windowOpen, setWindowOpen] = useState(false)
  const [pos, setPos] = useState([0, 0])
  const handleCloseWindow = useCallback(() => {
    setWindowOpen(false)
  }, [])

  const onLoad = () => {
    pano.current = null
    krpano.current = null
    const timerId = setInterval(() => {
      pano.current = document.getElementById('krpano')
      if (pano.current) {
        krpano.current = pano.current.contentWindow.document.getElementById(
          'krpanoSWFObject'
        )

        if (krpano.current) {
          clearInterval(timerId)
          setLoaded(true)
        }
      }
    }, 100)
  }

  useEffect(() => {
    if (!loaded) {
      return
    }

    // const visita = window.visitas.find((v) => v.id === iframeUrl)
    // console.log(visita)
    // if (visita) {
    //   visita.scenes.forEach((scene) => {
    //     krpano.current.set(
    //       `scene[${scene.id}].title`,
    //       idioma === ENGLISH ? scene.en : scene.es
    //     )
    //   })
    //
    //   if (visita.style) {
    //     setStyle(visita.style)
    //   }
    // }

    // const scene = krpano.current.get('xml.scene')
    // krpano.current.call(
    //   `loadscene("${scene ? scene : visita.scenes[0].id}",null,null,BLEND(0.5))`
    // )

    const messageEvent = (event) => {
      console.log(event)
      if (event.data.type === 'changePromocion') {
        dispatch(setPromocionByName(event.data.promocion))
        dispatch(optionClicked(MENU_OPTIONS.SELECCIONA_VIVIENDA))
      } else if (event.data.type === 'openWindow') {
      } else if (event.data.type === 'openWindowHotspot') {
        setWindowOpen(true)
        setPos([event.data.x, event.data.y])
      }
    }
    window.addEventListener('message', messageEvent)

    const panoEvent = () => {
      console.log('eventlistener')
      setLoaded(false)
    }
    pano.current.contentWindow.addEventListener('krpano', panoEvent)

    return () => {
      console.log('remove')
      window.removeEventListener('message', messageEvent)
      if (pano.current && pano.current.contentWindow) {
        pano.current.contentWindow.removeEventListener('krpano', panoEvent)
      }
    }
  }, [loaded, dispatch])

  return src ? (
    <div className={classes.root}>
      <iframe
        id="krpano"
        className={classes.iframe}
        title="Visita virtual"
        frameBorder="0"
        src={src}
        allow="xr-spatial-tracking"
        onLoad={onLoad}
      ></iframe>
      <PanoWindow
        open={windowOpen}
        imageSrc={'./images/fondo.jpg'}
        title={'Sala de estar'}
        text="Cras mattis consectetur purus sit amet fermentum.
              Cras justo odio, dapibus ac facilisis in, egestas eget quam.
              Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
              Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
               Cras mattis consectetur purus sit amet fermentum.
              Cras justo odio, dapibus ac facilisis in, egestas eget quam.
              Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
              Praesent commodo cursus magna, vel scelerisque nisl consectetur et"
        x={pos[0]}
        y={pos[1]}
        onClose={handleCloseWindow}
      />
    </div>
  ) : null
}
export default Krpano
