import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import EditorTextField from '../Common/Input/EditorTextField'
import {
  emailSelector,
  setEmail,
  getEmailError,
  submitClickedSelector
} from '../../modules/reducerContact'

const useStyles = makeStyles((theme) => ({
  textField: { width: '100%' }
}))

const CFEmailInput = () => {
  const classes = useStyles()
  const email = useSelector(emailSelector)
  const error = useSelector(getEmailError)
  const submitClicked = useSelector(submitClickedSelector)
  const dispatch = useDispatch()

  const handleNameChange = useCallback(
    (value) => {
      dispatch(setEmail(value))
    },
    [dispatch]
  )

  return (
    <EditorTextField
      className={classes.textField}
      label="Email"
      value={email}
      required
      error={submitClicked && error !== ''}
      onChange={handleNameChange}
      helperText={submitClicked ? error : ''}
    />
  )
}
export default CFEmailInput
