// import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import IconButton from '@material-ui/core/IconButton'

import ArrowIcon from '../Icons/ArrowIcon'

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 10,
    opacity: ({ show }) => (show ? 1 : 0),
    borderRadius: 0,
    padding: 0,
    height: 54,
    width: 28,
    color: theme.palette.primary.contrastText,
    backgroundColor: '#000000A0',
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: '#000000A0'
    }
  },
  icon: { transform: 'scale(1,2)', fill: 'currentColor' },
  iconRotate: { transform: 'rotate(180deg) scale(1,2)', fill: 'currentColor' },

  absolute: {
    position: 'absolute',
    left: ({ next }) => (next ? null : 10),
    right: ({ next }) => (next ? 10 : null),
    top: '50%',
    transform: 'translateY(-50%)'
  }
}))

const GalleryButton = ({ next, absolute, onClick, count, currentIdx }) => {
  const show = next ? currentIdx < count - 1 : currentIdx > 0
  const classes = useStyles({ show, absolute, next })

  return (
    <IconButton
      className={`${classes.root} ${absolute ? classes.absolute : ''}`}
      classes={{ label: classes.label }}
      disabled={!show}
      onClick={onClick}
    >
      {next ? (
        <ArrowIcon width={24} className={classes.icon} />
      ) : (
        <ArrowIcon
          width={24}
          className={`${classes.icon} ${classes.iconRotate}`}
        />
      )}
    </IconButton>
  )
}
export default GalleryButton
