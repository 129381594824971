import { useState, useEffect, useCallback, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useGesture } from 'react-use-gesture'
import { useSpring, animated } from 'react-spring'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'

import GalleryBarThumbnail from './GalleryBarThumbnail'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1 0 auto',
    backgroundColor: theme.palette.primary.contrastText,
    width: '100%',
    height: ({ height }) => height,
    overflow: 'hidden',
    touchAction: 'none',
    position: 'relative'
  },
  thumbnailsWrapper: {
    display: 'flex',
    flexFlow: 'row',
    position: 'absolute',
    height: ({ height }) => height
  }
}))

const GalleryBar = ({
  height,
  aspectRatio,
  imagesSrcs,
  currentImageIdx,
  onChangeImageIdx
}) => {
  const [imageWidth, setImageWidth] = useState(0)
  const [imageHeight, setImageHeight] = useState(0)
  const [maxX, setMaxX] = useState(0)
  const [localLeft, setLocalLeft] = useState(0)
  const dragging = useRef()
  const [rootBoundsRef, rootBounds] = useMeasure({ polyfill: ResizeObserver })
  const classes = useStyles({ height: imageHeight })

  const getImageVisible = useCallback(
    (idx) => {
      return localLeft + idx * imageWidth <= rootBounds.width
    },
    [localLeft, imageWidth, rootBounds]
  )

  const getImageFullyVisible = useCallback(
    (idx) => {
      const left = localLeft + idx * imageWidth
      const right = left + imageWidth
      return right <= rootBounds.width && left >= 0
    },
    [localLeft, imageWidth, rootBounds]
  )

  //Cambio dimensiones imagen
  useEffect(() => {
    if (
      rootBounds &&
      rootBounds.width > 0 &&
      height > 0 &&
      aspectRatio > 0 &&
      imagesSrcs &&
      imagesSrcs.length > 1
    ) {
      const w = aspectRatio * height
      setImageWidth(w)
      setImageHeight(height)
      setMaxX(Math.max(0, w * imagesSrcs.length - rootBounds.width))
    }
  }, [rootBounds, height, aspectRatio, imagesSrcs])

  //Cambio imagen actual
  useEffect(() => {
    if (!dragging.current && !getImageFullyVisible(currentImageIdx)) {
      setLocalLeft(
        Math.max(
          -maxX,
          Math.min(
            0,
            -currentImageIdx * imageWidth +
              rootBounds.width / 2 -
              imageWidth / 2
          )
        )
      )
    }
  }, [currentImageIdx, imageWidth, rootBounds, maxX, getImageFullyVisible])

  const despAnimation = useSpring({
    left: localLeft
  })

  const bind = useGesture({
    onDragStart: () => (dragging.current = true),
    onDrag: ({ cancel, distance, active, direction: [xDir], delta, tap }) => {
      if (rootBounds) {
        if (active) {
          if (localLeft > imageWidth) {
            setLocalLeft(0)
            cancel(true)
          } else if (localLeft < -maxX - imageWidth) {
            setLocalLeft(-maxX)
            cancel(true)
          } else {
            setLocalLeft(localLeft + delta[0])
          }
        }
      }
    },
    onDragEnd: () => {
      dragging.current = false
      if (rootBounds) {
        setLocalLeft(Math.max(-maxX, Math.min(0, localLeft)))
      }
    }
  })

  return imagesSrcs && imagesSrcs.length > 1 ? (
    <div ref={rootBoundsRef} {...bind()} className={classes.root}>
      <animated.div className={classes.thumbnailsWrapper} style={despAnimation}>
        {imagesSrcs.map((img, idx) => (
          <GalleryBarThumbnail
            key={idx}
            imgIdx={idx}
            imgSrc={img}
            visible={getImageVisible(idx)}
            currentImageIdx={currentImageIdx}
            width={imageWidth}
            height={imageHeight}
            onChangeImageIdx={onChangeImageIdx}
          />
        ))}
      </animated.div>
    </div>
  ) : null
}
export default GalleryBar
