import { createMuiTheme } from '@material-ui/core/styles'
// import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
// const breakpoints = createBreakpoints({})

let Demo3DTheme = createMuiTheme({
  componentsData: {
    menuMainButton: { width: 64, height: 54, top: 20, left: 20 },
    menuOption: { width: 150, height: 100 },
    menuPanel: { width: 352, paddingLeft: 32, paddingRight: 38 },
    contactForm: { width: 352, top: 20, right: 20 }
  },
  zIndex: { contactForm: 200, menu: 200, rotator: 100 },

  typography: {
    fontFamily: `Metropolis-Regular ,Roboto, Helvetica, Arial, sans-serif`,
    h1: { fontSize: '28px' },
    h2: { fontSize: '24px' },
    h3: { fontSize: '20px' },
    h4: { fontSize: '16px' },
    h5: { fontSize: '12px' },
    h6: { fontSize: '8px' }
  },
  palette: {
    primary: {
      main: '#000000',
      mainTransparent: 'rgba(0, 0, 0, 0.85)',
      light: '#333333',
      contrastText: '#FFFFFF',
      contrastText2: '#828282'
    },
    secondary: {
      main: '#D3AC5E',
      contrastText: '#FFFFFF',
      gradient: 'linear-gradient(to top, #d3ac5e, #ead98a)',
      gradientHorizontal: 'linear-gradient(to right, #d3ac5e, #ead98a)',
      hover: '#575343E0'
    },
    other: { background: 'white', sendButton: '#e0e0e0' }
  }
})

Demo3DTheme.palette = {
  ...Demo3DTheme.palette,
  pdf: { background: Demo3DTheme.palette.other.background },
  gallery: {
    background: Demo3DTheme.palette.other.background,
    barBackground: Demo3DTheme.palette.primary.main + 'A0'
  }
}

Demo3DTheme.props = {
  MuiInputLabel: {
    shrink: true,
    error: false
  },
  MuiInput: {
    disableUnderline: true
  },
  MuiButton: {
    disableElevation: true
    // disableFocusRipple: true,
    // disableRipple: true
  },
  MuiTooltip: { arrow: true }
}
//

Demo3DTheme.overrides = {
  MuiTooltip: {
    arrow: {
      color: Demo3DTheme.palette.primary.mainTransparent
    },
    tooltip: {
      color: Demo3DTheme.palette.primary.contrastText,
      backgroundColor: Demo3DTheme.palette.primary.mainTransparent
    }
  },
  MuiTextField: {
    root: { marginTop: 32 }
  },
  MuiInputBase: {
    root: {
      backgroundColor: Demo3DTheme.palette.primary.light,
      color: Demo3DTheme.palette.primary.contrastText2
      // height: 36
    }
  },
  MuiInputLabel: {
    root: {
      color: Demo3DTheme.palette.primary.contrastText,
      '&$focused': {
        background: Demo3DTheme.palette.secondary.gradient,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
        // color: Demo3DTheme.palette.secondary.main
      }
    },
    shrink: { transform: 'scale(1) translate(0, -12px)' }
  },
  MuiInput: {
    root: { padding: '0 8px' }
  },
  MuiTableSortLabel: {
    root: {
      color: Demo3DTheme.palette.primary.contrastText,

      // if you want to have icons visible permanently
      '& $icon': {
        opacity: 1,
        color: Demo3DTheme.palette.primary.contrastText
      },

      '&:hover': {
        color: Demo3DTheme.palette.secondary.main,

        '&& $icon': {
          opacity: 1,
          color: Demo3DTheme.palette.secondary.main
        }
      },
      '&$active': {
        color: Demo3DTheme.palette.secondary.main,
        // && instead of & is a workaround for https://github.com/cssinjs/jss/issues/1045
        '&& $icon': {
          opacity: 1,
          color: Demo3DTheme.palette.secondary.main
        }
      }
    }
  }
}
export default Demo3DTheme
