// import { useCallback } from 'react'
import { useSelector } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'
import MenuMainButton from './MenuMainButton'
import Menu from './Menu'
import { expandedSelector } from '../../modules/reducerGallery'
// const useStyles = makeStyles((theme) => ({}))

const MenuMain = () => {
  // const classes = useStyles()
  const expanded = useSelector(expandedSelector)
  return expanded ? null : (
    <>
      <MenuMainButton />
      <Menu />
    </>
  )
}
export default MenuMain
