import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles((theme) => ({
  customScroll: {
    overflow: 'auto',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '6px',
      height: '8px',
      background: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.contrastText2,
      borderRadius: 10
    }
  }
}))

const ScrollDiv = ({ children, className, ...props }) => {
  const classes = useStyles()
  return (
    <div className={`${classes.customScroll} ${className}`} {...props}>
      {children}
    </div>
  )
}
export default ScrollDiv
