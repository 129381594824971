import { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

import Typography from '@material-ui/core/Typography'

import { getHouseDetails } from '../../../modules/reducerHouses'

const useStyles = makeStyles((theme) => ({
  detailWrapper: { display: 'flex', flexFlow: 'row', padding: '12px 0' },
  detailName: {
    width: '50%',
    color: theme.palette.primary.contrastText2,
    paddingRight: 4
  },
  detailValue: { width: '50%', color: theme.palette.primary.contrastText }
}))

const DetailItem = ({ name, value }) => {
  const classes = useStyles()
  return (
    <div className={classes.detailWrapper}>
      <Typography variant="h4" className={classes.detailName}>
        {name}
      </Typography>
      <Typography variant="h4" className={classes.detailValue}>
        {value}
      </Typography>
    </div>
  )
}

const HouseDetails = ({ houseId }) => {
  const gHouseDetails = useMemo(getHouseDetails, [])
  const houseDetails = useSelector((state) => gHouseDetails(state, houseId))
  // const classes = useStyles()

  return (
    <>
      {houseDetails.details.map((detail, idx) => (
        <DetailItem key={idx} name={detail[0]} value={detail[1]} />
      ))}
    </>
  )
}
export default HouseDetails
