const RemoveIcon = (props) => {
  return (
    <svg fill="none" viewBox="0 0 17 20" {...props}>
      <path
        stroke="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M13.614 3.348H2.704V19h10.91V3.348zM10.886 1H5.432v2.348h5.454V1zM1 3.348h15M5.432 6.087v9.391m2.727-9.391v9.391m2.727-9.391v9.391"
      />
    </svg>
  )
}
export default RemoveIcon
