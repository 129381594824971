import { makeStyles } from '@material-ui/core/styles'

import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import MultiSelectChip from './MultiSelectChip'

const useStyles = makeStyles((theme) => ({
  root: { width: '100%' },
  inputRoot: { padding: 0 },
  selectRoot: {
    padding: 0,
    minHeight: 36,
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center'
  },
  chips: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  title: { color: theme.palette.primary.contrastText },
  menuPaper: { backgroundColor: 'black', width: 200 },
  menuItem: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&$selected': {
      // backgroundImage: theme.palette.secondary.gradientHorizontal,
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText
    },
    '&$selected:hover': {
      // backgroundImage: theme.palette.secondary.gradientHorizontal,
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText
    },
    '&:hover': {
      // backgroundImage: theme.palette.secondary.gradientHorizontal,
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText
    },
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  selected: {},
  empty: { paddingLeft: 8 },
  expandIcon: { color: theme.palette.primary.contrastText }
}))

/*
options=[{id,name},{id,name}]
*/
const MultiSelect = ({
  label,
  emptyLabel,
  options,
  selection,
  onSelectionChange,
  ...props
}) => {
  // const [sel, setSel] = useState([])
  const classes = useStyles()
  const handleChange = (event) => {
    if (onSelectionChange) {
      onSelectionChange(
        event.target.value.filter((itemId) => itemId !== 'empty')
      )
    }
  }

  const handleDelete = (id) => {
    if (onSelectionChange) {
      onSelectionChange(selection.filter((itemId) => itemId !== id))
    }
  }

  return (
    <TextField
      className={classes.root}
      select
      label={label}
      value={selection.length === 0 ? ['empty'] : selection}
      SelectProps={{
        classes: { root: classes.selectRoot, icon: classes.expandIcon },
        onChange: handleChange,
        multiple: true,
        renderValue: (selected) => (
          <div className={classes.chips}>
            {selected.map((value) =>
              value === 'empty' ? (
                <div key={value} className={classes.empty}>
                  {emptyLabel}
                </div>
              ) : (
                <MultiSelectChip
                  key={value}
                  id={value}
                  label={options.find((op) => op.id === value).name}
                  onDelete={handleDelete}
                />
              )
            )}
          </div>
        ),
        MenuProps: {
          classes: { paper: classes.menuPaper },
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          }
        },
        IconComponent: (props) => <ExpandMoreIcon {...props} />
      }}
      InputProps={{ classes: { root: classes.inputRoot } }}
      {...props}
    >
      {options.map((op) => (
        <MenuItem
          classes={{
            root: classes.menuItem,
            selected: classes.selected
          }}
          key={op.id}
          value={op.id}
        >
          {op.name}
        </MenuItem>
      ))}
    </TextField>
  )
}
export default MultiSelect
