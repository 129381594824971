// import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import ScrollDiv from '../../Common/ScrollDiv'
import FilterPrice from './FilterPrice'
import FilterArea from './FilterArea'
import FilterRooms from './FilterRooms'
import FilterTypologySelect from './FilterTypologySelect'
import FilterOrientationSelect from './FilterOrientationSelect'

import { rootSizeSelector } from '../../../modules/reducerMain'
import { getFinalTableHouses } from '../../../modules/reducerHousesTable'

const useStyles = makeStyles((theme) => ({
  filter: {
    flex: '0 0 auto',
    height: ({ h }) => h,
    padding: `0 ${theme.componentsData.menuPanel.paddingRight}px 0 ${theme.componentsData.menuPanel.paddingLeft}px`
  }
}))

const FilterMain = () => {
  const rootSize = useSelector(rootSizeSelector)
  const houses = useSelector(getFinalTableHouses)

  const getHeight = (height) => {
    //Lo calculo a mano porque no he encontrado la forma de hacer lo que queria
    //con el flexbox
    let h = Math.min(500, height - (houses.length <= 1 ? 330 : 400))
    return h
  }
  let h = getHeight(rootSize.height)

  const classes = useStyles({ h })
  // const dispatch = useDispatch()

  return (
    <ScrollDiv className={classes.filter}>
      <FilterTypologySelect />
      <FilterOrientationSelect />
      <FilterRooms />
      <FilterArea />
      <FilterPrice />
    </ScrollDiv>
  )
}
export default FilterMain
