import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import HouseTooltipContent from './HouseTooltipContent'

import { getHouse, setCurrentHouse } from '../../../modules/reducerHouses'

import { SUBMENUS, setCurrentSubmenu } from '../../../modules/reducerMain'
const useStyles = makeStyles((theme) => ({
  root: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    display: 'flex',
    flexFlow: 'column',
    width: 280,
    height: '100%',
    backgroundColor: theme.palette.primary.mainTransparent
  },
  button: {
    textTransform: 'none',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.secondary.main
    }
  }
}))

const HouseTooltip = ({ houseId }) => {
  const classes = useStyles()
  const gHouse = useMemo(getHouse, [])
  const house = useSelector((state) => gHouse(state, houseId))
  const dispatch = useDispatch()

  // const handleRemoveFilters = useCallback(
  //   (selection) => {
  //     dispatch(setSelection([]))
  //   },
  //   [dispatch]
  // )
  const handleClick = useCallback(
    (e) => {
      dispatch(setCurrentHouse(house.id))
      dispatch(setCurrentSubmenu(SUBMENUS.DETALLES_CASA))
    },
    [dispatch, house]
  )

  return house ? (
    <div className={classes.root}>
      <HouseTooltipContent houseId={houseId} />
      <Button className={classes.button} onClick={handleClick}>
        Ver detalle
      </Button>
    </div>
  ) : null
}
export default HouseTooltip
