import { useEffect, useCallback } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'

import HouseTooltip from '../Tooltip/HouseTooltip'
import SegmentsRotator from '../../SegmentsCanvas/SegmentsRotator'

import { drawHouse } from '../../../utils/draw'
import { getCurrentFloor } from '../../../modules/reducerHousesFilter'
import { setCurrentHouse } from '../../../modules/reducerHouses'
import {
  setGroup,
  hoverSegmentSelector,
  mouseOverSegment,
  getCurrentGroupFilteredHousesData
} from '../../../modules/reducerRotatorGroups'
import { getCurrentPromocion } from '../../../modules/reducerPromocion'
// const useStyles = makeStyles((theme) => ({
//   root: {}
// }))

const HousesRotator = ({ tooltipsMargins }) => {
  // const classes = useStyles()
  const dispatch = useDispatch()
  const hoverSegmentId = useSelector(hoverSegmentSelector)
  const currentGroupFilteredHousesData = useSelector(
    getCurrentGroupFilteredHousesData
  )
  const currentPromocion = useSelector(getCurrentPromocion)
  const currentFloor = useSelector(getCurrentFloor)

  // const small = useSelector(getMobileMode)
  const theme = useTheme()
  // const [play] = useSound(ring)

  useEffect(() => {
    if (currentPromocion) {
      if (currentPromocion.name === 'Selecta Ares II') {
        dispatch(setGroup('aresII'))
      } else if (currentPromocion.name === 'Selecta Ares') {
        dispatch(setGroup('g0'))
      } else if (currentPromocion.name === 'Selecta Apolo') {
        dispatch(setGroup('aresII'))
      } else if (currentPromocion.name === 'Selecta Mykonos') {
        dispatch(setGroup('aresII'))
      } else {
        dispatch(setGroup(''))
      }
    } else {
      dispatch(setGroup(''))
    }
  }, [dispatch, currentPromocion])

  const getHouse = useCallback(
    (segmentId) => {
      const segmentHouse = currentGroupFilteredHousesData.data.find(
        (sh) => sh.segmentId === segmentId
      )
      return segmentHouse ? segmentHouse.house : null
    },
    [currentGroupFilteredHousesData]
  )

  const getTooltips = useCallback(() => {
    const house = getHouse(hoverSegmentId)
    if (house) {
      return [
        {
          segmentId: hoverSegmentId,
          content: <HouseTooltip houseId={house.id} />,
          poiId: 'centroid'
        }
      ]
    }
    return []
  }, [getHouse, hoverSegmentId])

  const drawSegment = useCallback(
    (ctx, segment) => {
      if (segment.color === hoverSegmentId) {
        const house = getHouse(segment.color)
        if (house) {
          drawHouse(
            ctx,
            segment,
            '',
            //house.stateObject.color,
            theme.palette.secondary.main,
            segment.color === hoverSegmentId
          )
        }
      }
    },
    [hoverSegmentId, getHouse, theme]
  )

  const handleSegmentClick = useCallback(
    (segmentId) => {
      const house = getHouse(segmentId)
      if (house) {
        dispatch(setCurrentHouse(house.id))
      }
      dispatch(mouseOverSegment(segmentId))
    },
    [dispatch, getHouse]
  )

  return currentPromocion ? (
    <>
      {currentGroupFilteredHousesData.group ? (
        <SegmentsRotator
          tooltipsMargins={tooltipsMargins}
          drawSegment={drawSegment}
          tooltips={getTooltips()}
          onSegmentClick={handleSegmentClick}
        />
      ) : (
        currentFloor && (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            VISTA DE LA PLANTA {currentFloor.name}
          </div>
        )
      )}
    </>
  ) : null
}
export default HousesRotator
