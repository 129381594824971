import { useCallback, useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import AddDownloadButton from '../Downloads/AddDownloadButton'
import RemoveDownloadButton from '../Downloads/RemoveDownloadButton'
import HexagonIcon from '../Icons//HexagonIcon'
import { removeDownload, getDocument } from '../../modules/reducerDocuments'

const useStyles = makeStyles((theme) => ({
  listItem: {
    display: 'flex',
    flexFlow: 'row',
    height: '32px',
    color: ({ selected }) => (selected ? theme.palette.secondary.main : ''),
    '&:hover': { color: theme.palette.secondary.main }
  },
  itemText: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  itemIcon: {
    minWidth: 0,
    paddingRight: 4,
    fill: theme.palette.secondary.main,
    width: 12
  },

  buttonsWrapper: { display: 'flex', flexFlow: 'row', height: '100%' }
}))

const DownloadItem = ({ id }) => {
  const [hover, setHover] = useState(false)
  const gDocument = useMemo(getDocument, [])
  const doc = useSelector((state) => gDocument(state, id))

  const selected = hover
  const classes = useStyles({ selected })
  const dispatch = useDispatch()

  const handleDownloadClick = useCallback(
    (e) => {
      const a = document.createElement('a')
      a.href = doc.src
      a.download = `${doc.name}.pdf`
      a.click()
      a.remove()
      dispatch(removeDownload(id))
    },
    [dispatch, doc, id]
  )

  const handleMouseOver = useCallback(() => {
    setHover(true)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setHover(false)
  }, [])

  return doc ? (
    <div onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
      <ListItem
        className={classes.listItem}
        role={undefined}
        dense
        disableGutters
      >
        <ListItemIcon className={classes.itemIcon}>
          <HexagonIcon />
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classes.itemText,
            secondary: classes.itemText
          }}
          primary={doc.name}
          primaryTypographyProps={{ variant: 'h4' }}
        />
        <div className={classes.buttonsWrapper}>
          <AddDownloadButton
            ids={[id]}
            width={20}
            onDownload={handleDownloadClick}
          />
          <RemoveDownloadButton ids={[id]} width={18} />
        </div>
      </ListItem>
    </div>
  ) : null
}

export default DownloadItem
