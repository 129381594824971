import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { useTransition, animated } from 'react-spring'

import MenuOption from './MenuOption'
import MenuPanel from './MenuPanel'

import {
  menuOpenSelector,
  menuOptionsSelector
} from '../../modules/reducerMain'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top:
      theme.componentsData.menuMainButton.top +
      theme.componentsData.menuMainButton.height +
      8,
    left: theme.componentsData.menuMainButton.left,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    flexFlow: 'row',
    zIndex: theme.zIndex.contactForm
  },
  optionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column'
  }
}))

const Menu = () => {
  const classes = useStyles()
  const open = useSelector(menuOpenSelector)
  const menuOptions = useSelector(menuOptionsSelector)

  const transition = useTransition(open, {
    // onRest: () => {
    //   setAnimating(false)
    // },
    // onStart: (o) => {
    //   setAnimating(true)
    // },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  return transition((styles, item) =>
    item ? (
      <animated.div style={styles} className={classes.root}>
        <div className={classes.optionsWrapper}>
          {menuOptions.map((option) => (
            <MenuOption key={option.id} optionId={option.id} />
          ))}
        </div>
        <MenuPanel />
      </animated.div>
    ) : null
  )
}
export default Menu
