const BackIcon = (props) => {
  return (
    <svg fill="none" viewBox="0 0 27 20" {...props}>
      <path
        fill="#fff"
        stroke="stroke"
        d="M1.57 13.32l-.924.864h18.479c3.957 0 7.25-3.035 7.25-6.816 0-3.78-3.293-6.815-7.25-6.815H13.5a.158.158 0 01-.061-.009s0 0 0 0l-.002-.018.002-.018s0 0 0 0A.191.191 0 0113.5.5h5.625C23.268.5 26.5 3.57 26.5 7.368c0 3.799-3.232 6.869-7.375 6.869H.646l.925.865 4.699 4.397h-.002l-.08.001-.081-.001a.517.517 0 01-.067-.006l-5.493-5.14a.547.547 0 00-.031-.054L.5 14.276v-.164l5.51-5.155.068-.02a1.23 1.23 0 01.13-.024c-.007.066-.026.091-.026.091h.001l-4.612 4.315z"
      />
    </svg>
  )
}
export default BackIcon
