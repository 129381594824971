const CloseIcon = (props) => {
  return (
    <svg viewBox="0 0 378.303 378.303" {...props}>
      <path
        fill="fill"
        d="M378.303 28.285L350.018 0 189.151 160.867 28.285 0 0 28.285l160.867 160.866L0 350.018l28.285 28.284 160.866-160.866 160.867 160.866 28.285-28.284-160.867-160.867z"
      />
    </svg>
  )
}
export default CloseIcon
