// import { useEffect } from 'react'
import { useSelector } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'

import CFHexagon from './CFHexagon'
import CFForm from './CFForm'

import { expandedSelector } from '../../modules/reducerGallery'

// const useStyles = makeStyles((theme) => ({
//   root: { }
// }))

const ContactForm = () => {
  // const classes = useStyles()
  const expanded = useSelector(expandedSelector)
  return expanded ? null : (
    <>
      <CFHexagon />
      <CFForm />
    </>
  )
}
export default ContactForm
