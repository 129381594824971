const DownloadDocIcon = (props) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <path
        stroke="stroke"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M10.565 21.087H1V1h10.522l4.782 4.783v3.826"
      />
      <path
        stroke="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M11.521 1v4.783h4.783"
      />
      <path
        stroke="stroke"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M17.26 23a5.74 5.74 0 10-.002-11.48A5.74 5.74 0 0017.26 23z"
      />
      <path
        stroke="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M17.262 19.843v-5.165m-2.106 3.062l2.105 2.104 2.104-2.105"
      />
    </svg>
  )
}
export default DownloadDocIcon
