import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'

import MultiSelect from '../../Common/Input/MultiSelect/MultiSelect'
import { houseTypologiesSelector } from '../../../modules/reducerHouses'
import {
  typologiesSelector,
  setTypologySelection
} from '../../../modules/reducerHousesFilter'

// const useStyles = makeStyles((theme) => ({}))

const FilterTypologySelect = () => {
  const typologies = useSelector(houseTypologiesSelector)
  const selectedTypologies = useSelector(typologiesSelector)
  // const classes = useStyles()
  const dispatch = useDispatch()

  const handleChange = useCallback(
    (selection) => {
      dispatch(setTypologySelection(selection))
    },
    [dispatch]
  )

  return (
    <MultiSelect
      label={'Tipología'}
      emptyLabel={'Selecciona la tipología'}
      options={typologies}
      selection={selectedTypologies}
      onSelectionChange={handleChange}
    />
  )
}
export default FilterTypologySelect
