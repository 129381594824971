export const findById = (data, id) => {
  return data ? data.find((d) => d.id === id) : null
}

export const findByProperty = (data, value, property) => {
  return data ? data.find((d) => d[property] === value) : null
}

export const selectItem = (itemsIdArray, itemId, select) => {
  let newItemsIdArray = [...itemsIdArray]
  if (select) {
    if (newItemsIdArray.indexOf(itemId) <= 0) {
      newItemsIdArray.push(itemId)
    }
  } else {
    newItemsIdArray = newItemsIdArray.filter((id) => id !== itemId)
  }
  return newItemsIdArray
}

/*
  objectList: Lista objetos
  nameProperty: Nombre de la propiedad que guarda el nombre
  name: Cadena base desde la  que crear el nuevo nombre
*/
export const getNotUsedNameObjects = (
  objectList,
  nameProperty,
  name,
  separator = '_'
) => {
  let idx = 0
  let newName = name
  const nameUsed = (tags, name) => {
    return objectList.findIndex((o) => o[nameProperty] === name) >= 0
  }
  while (nameUsed(objectList, newName)) {
    idx++
    newName = name + separator + idx
  }
  return newName
}

export const swap = (array, fromIndex, toIndex) => {
  const item = array[fromIndex]
  const length = array.length
  const diff = fromIndex - toIndex

  if (diff > 0) {
    // move left
    return [
      ...array.slice(0, toIndex),
      item,
      ...array.slice(toIndex, fromIndex),
      ...array.slice(fromIndex + 1, length)
    ]
  } else if (diff < 0) {
    // move right
    const targetIndex = toIndex + 1
    return [
      ...array.slice(0, fromIndex),
      ...array.slice(fromIndex + 1, targetIndex),
      item,
      ...array.slice(targetIndex, length)
    ]
  }
  return array
}

export const clamp = (value, min, max) => {
  return Math.min(Math.max(min, value), max)
}
