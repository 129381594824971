import { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

import HouseDetailsBackButton from './HouseDetailsBackButton'
import HouseDetailsGalleryButton from './HouseDetailsGalleryButton'
import FavoriteButton from '../FavoriteButton'
import AddDownloadButton from '../../Downloads/AddDownloadButton'
import { getHouse } from '../../../modules/reducerHouses'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'row',
    boxSizing: 'border-box',
    height: 32,
    width: '100%',
    color: theme.palette.primary.contrastText
  },
  button: {
    color: theme.palette.primary.contrastText,
    padding: '0 4px',
    '&:hover': { color: theme.palette.secondary.main }
  }
}))

const HouseDetailsBar = ({ houseId }) => {
  const classes = useStyles()

  const gHouse = useMemo(getHouse, [])
  const house = useSelector((state) => gHouse(state, houseId))

  return house ? (
    <div className={classes.root}>
      <HouseDetailsBackButton />
      <div style={{ flexGrow: 1 }} />
      <FavoriteButton houseId={houseId} className={classes.button} />
      <AddDownloadButton ids={house.documents} width={20} />
      {house.gallery && <HouseDetailsGalleryButton houseId={houseId} />}
    </div>
  ) : null
}
export default HouseDetailsBar
