const MaximizeIcon = (props) => {
  return (
    <svg fill="none" viewBox="0 0 18 18" {...props}>
      <path
        fill="#fff"
        stroke="stroke"
        d="M18 .3c-.1-.1-.1-.2-.3-.3h-7.2c-.3 0-.5.2-.5.5s.2.5.5.5h5.8L1 16.3v-5.8c0-.3-.2-.5-.5-.5s-.5.2-.5.5v7.2c.1.1.1.2.3.3h7.2c.3 0 .5-.2.5-.5s-.2-.5-.5-.5H1.7L17 1.7v5.8c0 .3.2.5.5.5s.5-.2.5-.5V.3z"
      />
    </svg>
  )
}
export default MaximizeIcon
