import { makeStyles } from '@material-ui/core/styles'

import ScrollDiv from '../Common/ScrollDiv'
const useStyles = makeStyles((theme) => ({
  menuPanelContent: {
    boxSizing: 'border-box',
    width: '100%',
    minHeight: 0,
    padding: `0 ${theme.componentsData.menuPanel.paddingRight}px 0 ${theme.componentsData.menuPanel.paddingLeft}px`,

    display: 'flex',
    flexFlow: 'column'
  }
}))

const MenuPanelContent = ({ children }) => {
  const classes = useStyles()

  return <ScrollDiv className={classes.menuPanelContent}>{children}</ScrollDiv>
}
export default MenuPanelContent
