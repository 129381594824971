import { createSelector } from 'reselect'
// import { findById } from '../utils/utils'
import { isPossiblePhoneNumber } from 'react-phone-number-input'

export const CONTACT_FORM_SET_OPEN = 'CONTACT_FORM_SET_OPEN'
export const CONTACT_FORM_SET_NAME = 'CONTACT_FORM_SET_NAME'
export const CONTACT_FORM_SET_LAST_NAME = 'CONTACT_FORM_SET_LAST_NAME'
export const CONTACT_FORM_SET_PHONE = 'CONTACT_FORM_SET_PHONE'
export const CONTACT_FORM_SET_EMAIL = 'CONTACT_FORM_SET_EMAIL'
export const CONTACT_FORM_SET_COMMENTS = 'CONTACT_FORM_SET_COMMENTS'
export const CONTACT_FORM_SET_AGREE = 'CONTACT_FORM_SET_AGREE'
export const CONTACT_FORM_SUBMIT_CLICKED = 'CONTACT_FORM_SUBMIT_CLICKED'

const initialState = {
  open: false,
  name: '',
  lastName: '',
  phone: '',
  email: '',
  comments: '',
  agree: false,
  submitClicked: false
}

const changeState = (state = initialState, action) => {
  switch (action.type) {
    case CONTACT_FORM_SET_OPEN:
      return { ...state, open: action.open }
    case CONTACT_FORM_SET_NAME:
      return { ...state, name: action.name }
    case CONTACT_FORM_SET_LAST_NAME:
      return { ...state, lastName: action.lastName }
    case CONTACT_FORM_SET_PHONE:
      return { ...state, phone: action.phone }
    case CONTACT_FORM_SET_EMAIL:
      return { ...state, email: action.email }
    case CONTACT_FORM_SET_COMMENTS:
      return { ...state, comments: action.comments }
    case CONTACT_FORM_SET_AGREE:
      return { ...state, agree: action.agree }
    case CONTACT_FORM_SUBMIT_CLICKED:
      return {
        ...state,
        submitClicked: action.value
      }
    default:
      return state
  }
}
export default changeState

export const setOpen = (open) => ({
  type: CONTACT_FORM_SET_OPEN,
  open
})
export const setName = (name) => ({
  type: CONTACT_FORM_SET_NAME,
  name
})
export const setLastName = (lastName) => ({
  type: CONTACT_FORM_SET_LAST_NAME,
  lastName
})
export const setPhone = (phone) => ({
  type: CONTACT_FORM_SET_PHONE,
  phone
})
export const setEmail = (email) => ({
  type: CONTACT_FORM_SET_EMAIL,
  email
})
export const setComments = (comments) => ({
  type: CONTACT_FORM_SET_COMMENTS,
  comments
})
export const setAgree = (agree) => ({
  type: CONTACT_FORM_SET_AGREE,
  agree
})
export const setSendClicked = (value) => ({
  type: CONTACT_FORM_SUBMIT_CLICKED,
  value
})

export const openSelector = (state) => state.contact.open
export const nameSelector = (state) => state.contact.name
export const lastNameSelector = (state) => state.contact.lastName
export const phoneSelector = (state) => state.contact.phone
export const emailSelector = (state) => state.contact.email
export const commentsSelector = (state) => state.contact.comments
export const agreeSelector = (state) => state.contact.agree
export const submitClickedSelector = (state) => state.contact.submitClicked

export const getNameError = createSelector([nameSelector], (name) => {
  return name === '' ? 'Indique su nombre' : null
})

export const getLastNameError = createSelector(
  [lastNameSelector],
  (lastName) => {
    return lastName === '' ? 'Indique sus apellidos' : ''
  }
)

function validateEmail(text) {
  const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  return text.match(mailformat)
}

export const getEmailError = createSelector([emailSelector], (email) => {
  return !email || !validateEmail(email) ? 'Debe introducir email válido' : ''
})

export const getPhoneError = createSelector([phoneSelector], (phoneNumber) => {
  return !phoneNumber || !isPossiblePhoneNumber(phoneNumber)
    ? 'Debe introducir un número de teléfono válido'
    : ''
})

export const getPolicyError = createSelector([agreeSelector], (agree) => {
  return !agree
})

export const getOk = createSelector(
  [
    getNameError,
    getLastNameError,
    getEmailError,
    getPhoneError,
    getPolicyError
  ],
  (nameError, lastNameError, emailError, phoneNumberError, policyError) => {
    return (
      !nameError &&
      !lastNameError &&
      !emailError &&
      !phoneNumberError &&
      !policyError
    )
  }
)

/*
export const openSelector = (state) => state.contact.open
export const nameSelector = (state) => state.contact.name
export const lastNameSelector = (state) => state.contact.lastName
export const phoneSelector = (state) => state.contact.phone
export const emailSelector = (state) => state.contact.email
export const commentsSelector = (state) => state.contact.comments
export const agreeSelector = (state) => state.contact.agree
export const submitClickedSelector = (state) => state.contact.submitClicked
*/

export const getFormData = createSelector(
  [
    nameSelector,
    lastNameSelector,
    phoneSelector,
    emailSelector,
    commentsSelector
  ],
  (name, lastName, phone, email, comments) => {
    return { name, lastName, phone, email, comments }
  }
)
