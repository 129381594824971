import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

import { loadingSelector } from '../../modules/reducerRotator'

const PROGRESS_RADIUS = 50
const useStyles = makeStyles((theme) => ({
  progress: {
    position: 'absolute',
    zIndex: theme.zIndex.rotator + 1,
    top: `calc(50% - ${PROGRESS_RADIUS}px)`,
    left: `calc(50% - ${PROGRESS_RADIUS}px)`,
    width: 80,
    height: 80
  }
}))

const RotatorProgress = () => {
  const loading = useSelector(loadingSelector)

  const classes = useStyles()

  return loading ? (
    <CircularProgress className={classes.progress} size={PROGRESS_RADIUS * 2} />
  ) : null
}
export default RotatorProgress
