import { useTheme } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'

const GradientText = ({ normal, children, variant, ...props }) => {
  const theme = useTheme()

  return normal ? (
    <Typography variant={variant} {...props}>
      {children}
    </Typography>
  ) : (
    <Typography
      variant={variant}
      style={{
        background: theme.palette.secondary.gradient,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
      }}
      {...props}
    >
      {children}
    </Typography>
  )
}
export default GradientText
