const DownloadIcon = (props) => {
  return (
    <svg viewBox="0 0 39 39" fill="none" {...props}>
      <path
        stroke="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M29.956 7.435V4.217H9.043V1H1v24.935c0 1.287 1.126 2.413 2.413 2.413s2.413-1.126 2.413-2.413v-18.5h27.348v7.239M3.413 28.348h11.261"
      />
      <path
        stroke="stroke"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M28.348 38C33.678 38 38 33.679 38 28.348c0-5.33-4.322-9.652-9.652-9.652a9.652 9.652 0 00-9.652 9.652c0 5.33 4.321 9.652 9.652 9.652z"
      />
      <path
        stroke="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M28.348 32.691v-8.687m-3.539 5.148l3.539 3.54 3.539-3.54"
      />
    </svg>
  )
}
export default DownloadIcon
