export const drawDotPoint = (ctx, x, y, length, CSSColor) => {
  const halfLength = length / 2
  ctx.fillStyle = CSSColor
  ctx.fillRect(x - halfLength, y - halfLength, length, length)
}

export const drawEquis = (ctx, x, y, length, width, CSSColor) => {
  const halfLength = length / 2
  ctx.strokeStyle = CSSColor
  ctx.lineWidth = width
  ctx.beginPath()
  ctx.moveTo(x - halfLength, y - halfLength)
  ctx.lineTo(x + halfLength, y + halfLength)
  ctx.stroke()
  //
  ctx.beginPath()
  ctx.moveTo(x + halfLength, y - halfLength)
  ctx.lineTo(x - halfLength, y + halfLength)
  ctx.stroke()
}

export const drawCross = (ctx, x, y, length, width, CSSColor) => {
  const halfLength = length / 2
  ctx.strokeStyle = CSSColor
  ctx.lineWidth = width
  ctx.beginPath()
  ctx.moveTo(x, y - halfLength)
  ctx.lineTo(x, y + halfLength)
  ctx.stroke()
  //
  ctx.beginPath()
  ctx.moveTo(x + halfLength, y)
  ctx.lineTo(x - halfLength, y)
  ctx.stroke()
}

export const drawAsterisk = (ctx, x, y, length, width, CSSColor) => {
  drawCross(ctx, x, y, length, width, CSSColor)
  drawEquis(ctx, x, y, length, width, CSSColor)
}

export const drawPolygon = (
  ctx,
  points, //[[x,y],[x,y]...,[x,y]]
  fill,
  fillCSSColor,
  stroke = false,
  strokeCSSColor = 'black',
  strokeWidth = 1
) => {
  ctx.lineWidth = strokeWidth
  if (points.length > 0 && (fill || stroke)) {
    ctx.beginPath()
    ctx.lineJoin = 'round'
    ctx.moveTo(points[0][0], points[0][1])
    for (let i = 1; i < points.length; i++) {
      ctx.lineTo(points[i][0], points[i][1])
    }
    ctx.closePath()
    if (fill) {
      ctx.fillStyle = fillCSSColor
      ctx.fill()
    }
    if (stroke) {
      ctx.strokeStyle = strokeCSSColor
      ctx.stroke()
    }
  }
}

export const drawShape = (
  ctx,
  polygons,
  fill,
  fillCSSColor,
  stroke = false,
  strokeCSSColor = 'black',
  strokeWidth = 1
) => {
  ctx.lineWidth = strokeWidth
  ctx.lineJoin = 'round'
  ctx.beginPath()
  for (let polygon of polygons) {
    if (polygon.length > 0 && (fill || stroke)) {
      ctx.moveTo(polygon[0][0], polygon[0][1])
      for (let i = 1; i < polygon.length; i++) {
        ctx.lineTo(polygon[i][0], polygon[i][1])
      }
      ctx.closePath()
    }
  }
  if (fill) {
    ctx.fillStyle = fillCSSColor
    ctx.fill('evenodd')
  }
  if (stroke) {
    ctx.strokeStyle = strokeCSSColor
    ctx.stroke()
  }
}

export const drawCenterText = (
  ctx,
  x,
  y,
  text,
  size,
  color,
  font = 'Arial'
) => {
  ctx.font = `${size}px ${font}`
  ctx.fillStyle = color
  ctx.textAlign = 'center'
  ctx.textBaseline = 'middle'
  ctx.fillText(text, x, y)
}

// export const SELECTED_COLOR = '#FF0000FF'
// export const HOVER_COLOR = '#00FF00FF'
// export const SELECTED_AND_HOVER_COLOR = '#FFFF00FF'
export const drawHouse = (ctx, segment, name, houseHexColor, hover) => {
  if (!segment) {
    return
  }

  let color = houseHexColor + 'A0'
  if (hover) {
    color = houseHexColor + 'E0'
  }
  // if (selected && !hover) {
  //   color = SELECTED_COLOR
  // } else if (selected && hover) {
  //   color = SELECTED_AND_HOVER_COLOR
  // } else if (!selected && hover) {
  //   color = HOVER_COLOR
  // } else {
  //   return
  // }
  //Pois
  for (let point of segment.pois) {
    drawCross(ctx, point[0], point[1], 10, 2, 'white')
  }
  //Polygons
  drawShape(ctx, segment.polygons, true, color, false, 'white', 1)
  //Text
  // const centroid = segment.pois.find((poi) => poi.id === 'centroid')
  // if (centroid) {
  //   drawCenterText(ctx, centroid.point[0], centroid.point[1], name, 20, 'black')
  // }
}

export const drawGeneralViewSegment = (
  ctx,
  segment,
  labelPosition,
  fill,
  fillCSSColor,
  strokeCSSColor
) => {
  ctx.lineWidth = 3
  ctx.setLineDash([15, 5])
  ctx.lineJoin = 'round'
  ctx.beginPath()
  for (let polygon of segment.polygons) {
    if (polygon.length > 0) {
      ctx.moveTo(polygon[0][0], polygon[0][1])
      for (let i = 1; i < polygon.length; i++) {
        ctx.lineTo(polygon[i][0], polygon[i][1])
      }
      ctx.closePath()
    }
  }
  if (fill) {
    ctx.fillStyle = fillCSSColor
    ctx.fill('evenodd')
  }

  ctx.strokeStyle = strokeCSSColor
  ctx.stroke()

  // drawCenterText(
  //   ctx,
  //   segment.pois[0].point[0],
  //   segment.pois[0].point[1],
  //   'FASE',
  //   32,
  //   fillCSSColor
  // )
}
