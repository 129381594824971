import { useCallback, useEffect } from 'react'
// import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'

import FilterSlider from './FilterSlider'
import { getCurrentPromocion } from '../../../modules/reducerPromocion'
import {
  areaRangeSelector,
  setAreaRange,
  getSliderAreaRange
} from '../../../modules/reducerHousesFilter'

// const useStyles = makeStyles((theme) => ({}))

const FilterArea = ({ id }) => {
  // const classes = useStyles()
  const areaRange = useSelector(areaRangeSelector)
  const sliderAreaRange = useSelector(getSliderAreaRange)
  const promocion = useSelector(getCurrentPromocion)
  const areaUnit = promocion ? promocion.areaUnit : ''
  const dispatch = useDispatch()

  useEffect(() => {
    //Ajusta areaRange al principio
    if (
      areaRange[0] < sliderAreaRange[0] ||
      areaRange[1] > sliderAreaRange[1]
    ) {
      dispatch(setAreaRange(sliderAreaRange))
    }
  }, [dispatch, sliderAreaRange, areaRange])

  const handleChange = useCallback(
    (e, newValue) => {
      console.log(newValue)
      dispatch(setAreaRange(newValue))
    },
    [dispatch]
  )

  return (
    <FilterSlider
      title="Superficie"
      min={sliderAreaRange[0]}
      max={sliderAreaRange[1]}
      range={areaRange}
      step={1}
      unit={areaUnit}
      onRangeChange={handleChange}
    />
  )
}
export default FilterArea
