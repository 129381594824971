import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import Typography from '@material-ui/core/Typography'

import {
  floorSelector,
  setFloor
} from '../../../../modules/reducerHousesFilter'
import { getHouseFloorsDescOrder } from '../../../../modules/reducerHouses'

const useStyles = makeStyles((theme) => ({
  labelsWrapper: {
    boxSizing: 'border-box',
    paddingRight: 12,
    maxWidth: 80,
    width: 68,
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },
  labelWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 32,
    width: '100%',
    cursor: 'pointer'
  },
  label: {
    color: theme.palette.primary.contrastText,
    fontSize: 12,
    fontWeight: 'normal',

    display: 'inline',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  labelSelected: {
    color: theme.palette.secondary.main,
    fontSize: 14,
    fontWeight: 500
  }
}))

const FloorSelectLabel = ({ id, name }) => {
  const classes = useStyles()
  const currentFloor = useSelector(floorSelector)
  const dispatch = useDispatch()

  const handleClick = useCallback(
    (e, value) => {
      dispatch(setFloor(id))
    },
    [dispatch, id]
  )
  return (
    <div className={classes.labelWrapper} onClick={handleClick}>
      <Typography
        variant="h4"
        className={`${classes.label} ${
          id === currentFloor ? classes.labelSelected : ''
        }`}
      >
        {name}
      </Typography>
    </div>
  )
}

const FloorSelectLabels = () => {
  const houseFloors = useSelector(getHouseFloorsDescOrder)
  const classes = useStyles()

  return (
    <div className={classes.labelsWrapper}>
      {houseFloors.map((floor) => (
        <FloorSelectLabel key={floor.id} id={floor.id} name={floor.name} />
      ))}
    </div>
  )
}
export default FloorSelectLabels
