import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/core/styles'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'

import Demo3DTheme from './Demo3DTheme'
import ContactForm from './ContactForm/ContactForm'
import MenuMain from './Menu/MenuMain'
import Center from './Center/Center'

import { setRootSize } from '../modules/reducerMain'
import { readAllData } from '../modules/reducerRead'
import {
  currentPromocionSelector,
  promocionesSelector,
  setPromocion,
  setPromocionByName
} from '../modules/reducerPromocion'
import { getCurrentFloor, setFloor } from '../modules/reducerHousesFilter'
import { houseFloorsSelector } from '../modules/reducerHouses'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    position: 'relative',
    overflow: 'hidden'
  },
  fondo: { width: '100%', height: '100%', objectFit: 'cover' },
  centerWrapper: {
    // backgroundColor: 'transparent',
    // paddingTop: Demo3DTheme.sizes.toolbar.height,
    // boxSizing: 'border-box',
    // width: '100%',
    // height: '100%',
    // display: 'flex',
    // flexFlow: 'row',
    // position: 'relative'
  }
}))

const Demo3DMain = () => {
  const classes = useStyles()
  const [rootBoundsRef, rootBounds] = useMeasure({ polyfill: ResizeObserver })
  const currentPromocion = useSelector(currentPromocionSelector)
  const promociones = useSelector(promocionesSelector)
  const houseFloors = useSelector(houseFloorsSelector)
  const currentFloor = useSelector(getCurrentFloor)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(readAllData())
  }, [dispatch])

  useEffect(() => {
    if (houseFloors.length > 0 && !currentFloor) {
      dispatch(setFloor(houseFloors[0].id))
    }
  }, [dispatch, houseFloors, currentFloor])

  useEffect(() => {
    dispatch(
      setRootSize({ width: rootBounds.width, height: rootBounds.height })
    )
  }, [dispatch, rootBounds])

  useEffect(() => {
    if (currentPromocion === '' && promociones.length > 0) {
      dispatch(setPromocionByName('Selecta Ares II'))
      // dispatch(setPromocion(promociones[0].id))
    }
  }, [dispatch, promociones, currentPromocion])

  return (
    <ThemeProvider theme={Demo3DTheme}>
      <div ref={rootBoundsRef} className={classes.root}>
        <Center />
        <ContactForm />
        <MenuMain />
      </div>
    </ThemeProvider>
  )
}
export default Demo3DMain
/*
<div
  style={{
    position: 'absolute',
    width: 300,
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.85)'
  }}
>
  <div
    style={{
      width: '100%',
      height: 100,
      opacity: 0.1,
      backgroundImage: 'linear-gradient(to top, #d3ac5e, #ead98a)'
    }}
  ></div>
</div>
*/
