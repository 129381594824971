const DocumentIcon = (props) => {
  return (
    <svg fill="none" viewBox="0 0 35 29" {...props}>
      <path
        stroke="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M4.587 28.26h25.109c2.439 0 4.304-1.864 4.304-4.304V6.74H8.174v17.935a3.552 3.552 0 01-3.587 3.587zm0 0A3.55 3.55 0 011 24.675V1h10.043v2.87h18.653v2.87"
      />
      <path
        stroke="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M16.783 15.348v7.174h8.608v-7.174"
      />
      <path
        stroke="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M13.913 18.217l7.174-7.173 7.174 7.173"
      />
      <path
        stroke="stroke"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M22.522 18.217h-2.87v4.305h2.87v-4.305z"
      />
    </svg>
  )
}
export default DocumentIcon
