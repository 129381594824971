// import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { useTransition, animated } from 'react-spring'

import MapMenu from '../Map/MapMenu'
import MapMenuFooter from '../Map/MapMenuFooter'
import FilterMenu from '../House/Filter/FilterMenu'
import FilterMenuFooter from '../House/Filter/FilterMenuFooter'

import HouseDetailsMenu from '../House/Details/HouseDetailsMenu'
import PromotionGalleriesMenu from '../Galleries/PromotionGalleriesMenu'
import PromotionDocumentsMenu from '../Documents/PromotionDocumentsMenu'
import DownloadMenu from '../Downloads/DownloadMenu'
import DownloadAllButton from '../Downloads/DownloadAllButton'
import GradientText from '../Common/GradientText'
import {
  currentMenuOptionExpandedSelector,
  getCurrentSubmenu,
  SUBMENUS
} from '../../modules/reducerMain'

const PADDING_LEFT = 32
const PADDING_RIGHT = 38
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    zIndex: theme.zIndex.contactForm,
    left: theme.componentsData.menuOption.width,
    backgroundColor: theme.palette.primary.mainTransparent,
    cursor: 'default',

    boxSizing: 'border-box',
    padding: '20px 0px 20px 0px',
    width: theme.componentsData.menuPanel.width,
    maxHeight: `calc(100vh - ${theme.componentsData.menuMainButton.top}px - ${theme.componentsData.menuMainButton.height}px - 20px)`,

    display: 'flex',
    flexFlow: 'column'
  },
  title: {
    fontWeight: 'bold',
    padding: `0 ${PADDING_RIGHT}px 12px ${PADDING_LEFT}px`
  },
  // contentWrapper: {
  //   boxSizing: 'border-box',
  //   padding: `0 ${PADDING_RIGHT}px 0 ${PADDING_LEFT}px`,
  //   width: '100%',
  //   minHeight: 0,
  //
  //   display: 'flex',
  //   flexFlow: 'column'
  //   // overflow: 'auto',
  //   // scrollbarWidth: 'thin',
  //   // '&::-webkit-scrollbar': {
  //   //   width: '6px',
  //   //   height: '8px',
  //   //   background: 'transparent'
  //   // },
  //   // '&::-webkit-scrollbar-thumb': {
  //   //   background: theme.palette.primary.contrastText2
  //   // }
  // },
  footer: {
    // flex: '1 0 auto',
    padding: `0 ${PADDING_RIGHT}px 0 ${PADDING_LEFT}px`
  }
}))

const MenuPanel = () => {
  const currentMenuOptionExpanded = useSelector(
    currentMenuOptionExpandedSelector
  )
  const currentSubmenu = useSelector(getCurrentSubmenu)
  const classes = useStyles()

  const getCenter = () => {
    switch (currentSubmenu.id) {
      case SUBMENUS.PLANO_SITUACION:
        return <MapMenu />
      case SUBMENUS.FILTRO:
        return <FilterMenu />
      case SUBMENUS.DETALLES_CASA:
        return <HouseDetailsMenu />
      case SUBMENUS.DOCUMENTOS_PROMOCION:
        return <PromotionDocumentsMenu />
      case SUBMENUS.DOCUMENTOS_VIVIENDA:
      case SUBMENUS.GALERIAS_PROMOCION:
        return <PromotionGalleriesMenu />
      case SUBMENUS.GALERIAS_VIVIENDA:
      case SUBMENUS.DESCARGAS:
        return <DownloadMenu />
      // case SUBMENUS.VIDEOS:
      default:
        return null
    }
  }
  const getFooter = () => {
    switch (currentSubmenu.id) {
      case SUBMENUS.PLANO_SITUACION:
        return <MapMenuFooter />
      case SUBMENUS.FILTRO:
        return <FilterMenuFooter />
      // case SUBMENUS.DETALLES_CASA:
      // case SUBMENUS.DOCUMENTOS_PROMOCION:
      // case SUBMENUS.DOCUMENTOS_VIVIENDA:
      // case SUBMENUS.GALERIAS_PROMOCION:
      // case SUBMENUS.GALERIAS_VIVIENDA:
      case SUBMENUS.DESCARGAS:
        return <DownloadAllButton />
      // case SUBMENUS.VIDEOS:
      default:
        return null
    }
  }

  const transition = useTransition(currentMenuOptionExpanded, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  return currentSubmenu
    ? transition((styles, item) =>
        item ? (
          <animated.div style={styles} className={classes.root}>
            {currentSubmenu.title && (
              <GradientText variant="h4" className={classes.title}>
                {currentSubmenu.title}
              </GradientText>
            )}
            {getCenter()}
            <div className={classes.footer}>{getFooter()}</div>
          </animated.div>
        ) : null
      )
    : null
}
export default MenuPanel
//<div className={classes.contentWrapper}>{getCenter()}</div>
