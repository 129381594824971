// import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import MenuPanelContent from '../Menu/MenuPanelContent'
import MapCategorySelect from './MapCategorySelect'

import { categoriesSelector } from '../../modules/reducerMap'
const useStyles = makeStyles((theme) => ({
  root: {}
}))

const MapMenu = () => {
  const classes = useStyles()
  const categories = useSelector(categoriesSelector)

  return (
    <MenuPanelContent className={classes.root}>
      {categories.map((cat) => {
        return <MapCategorySelect key={cat.id} catId={cat.id} />
      })}
    </MenuPanelContent>
  )
}
export default MapMenu
