import { useCallback, useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'

const EditorTextField = ({
  label,
  value,
  warning,
  error,
  onChange,
  ...props
}) => {
  const [localValue, setLocalValue] = useState(value)
  useEffect(() => {
    setLocalValue(value) //Deja esto
  }, [value])

  const updateExternalValue = useCallback(() => {
    if (onChange) {
      onChange(localValue)
    }
    setLocalValue(value)
  }, [onChange, localValue, value])

  const handleBlur = useCallback(
    (e) => {
      updateExternalValue()
    },
    [updateExternalValue]
  )

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        updateExternalValue()
      }
    },
    [updateExternalValue]
  )

  const handleChange = useCallback((e) => {
    setLocalValue(e.target.value)
  }, [])

  return (
    <TextField
      label={label}
      value={localValue}
      error={error}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyPress={handleKeyPress}
      {...props}
    />
  )
}
export default EditorTextField
