import { createSelector } from 'reselect'
import { dataSelector, DATA_TYPE } from './reducerRead'
import {
  getCurrentPromocion,
  currentPromocionSelector
} from './reducerPromocion'

import { findById, selectItem } from '../utils/utils'

export const MAP_SELECT_SUBCATEGORY = 'MAP_SELECT_SUBCATEGORY'
export const MAP_SET_SUBCATEGORY_SELECTION = 'MAP_SET_SUBCATEGORY_SELECTION'
export const MAP_SET_INFO_WINDOW_OPEN = 'MAP_SET_INFO_WINDOW_OPEN'
export const MAP_SET_MAIN_INFO_WINDOW_OPEN = 'MAP_SET_MAIN_INFO_WINDOW_OPEN'
export const MAP_CLOSE_ALL_INFO_WINDOW = 'MAP_CLOSE_ALL_INFO_WINDOW'

const initialState = {
  // categories: [
  //   { id: 'c0', name: 'Transporte', subcategories: ['s0', 's1', 's2'] },
  //   { id: 'c1', name: 'Educación', subcategories: ['s3', 's4', 's5', 's6'] },
  //   { id: 'c2', name: 'Salud', subcategories: ['s7', 's8'] },
  //   {
  //     id: 'c3',
  //     name: 'Servicios',
  //     subcategories: ['s9', 's10', 's11', 's12', 's13', 's14']
  //   },
  //   {
  //     id: 'c4',
  //     name: 'Ocio',
  //     subcategories: ['s15', 's16', 's17', 's18', 's19']
  //   }
  // ],
  // subcategories: [
  //   { id: 's0', name: 'Estacion autobuses/trenes' },
  //   { id: 's1', name: 'Aparcamiento' },
  //   { id: 's2', name: 'Parada de Taxis' },
  //   { id: 's3', name: 'Guarderías' },
  //   { id: 's4', name: 'Colegio' },
  //   { id: 's5', name: 'Instituto' },
  //   { id: 's6', name: 'Universidad' },
  //   { id: 's7', name: 'Farmacia' },
  //   { id: 's8', name: 'Hospital' },
  //   { id: 's9', name: 'Correos' },
  //   { id: 's10', name: 'Comisaría de policía' },
  //   { id: 's11', name: 'Ayuntamiento' },
  //   { id: 's12', name: 'Supermercado' },
  //   { id: 's13', name: 'Panadería' },
  //   { id: 's14', name: 'Gasolinera' },
  //   { id: 's15', name: 'Centro deportivo' },
  //   { id: 's16', name: 'Zona verde' },
  //   { id: 's17', name: 'Cine y teatro' },
  //   { id: 's18', name: 'Bibioteca' },
  //   { id: 's19', name: 'Restaurantes' }
  // ],
  // pois: [
  //   {
  //     id: 'p0',
  //     subcategory: '',
  //     longitud: 0,
  //     latitud: 0,
  //     content: null
  //   }
  // ],

  selection: [], //Subcategorias seleccionadas
  mainInfoWindowOpen: false,
  infoWindowsOpen: []
}

const changeState = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case MAP_SELECT_SUBCATEGORY:
      return {
        ...state,
        selection: selectItem(state.selection, action.id, action.select)
      }
    case MAP_SET_SUBCATEGORY_SELECTION:
      return { ...state, selection: action.selection }
    case MAP_SET_MAIN_INFO_WINDOW_OPEN:
      return { ...state, mainInfoWindowOpen: action.open }
    case MAP_SET_INFO_WINDOW_OPEN:
      return {
        ...state,
        infoWindowsOpen: selectItem(
          state.infoWindowsOpen,
          action.poiId,
          action.open
        )
      }
    case MAP_CLOSE_ALL_INFO_WINDOW:
      return { ...state, infoWindowsOpen: [], mainInfoWindowOpen: false }
    default:
      return state
  }
}
export default changeState

export const selectSubcategory = (id, select) => ({
  type: MAP_SELECT_SUBCATEGORY,
  id,
  select
})

export const setSelection = (selection) => ({
  type: MAP_SET_SUBCATEGORY_SELECTION,
  selection
})

export const setCategorySelection = (catId, selection) => (
  dispatch,
  getState
) => {
  const state = getState()
  const cat = findById(categoriesSelector(state), catId)
  if (cat) {
    let newSelection = selectionSelector(state).filter((subCatId) => {
      return cat.subcategories.indexOf(subCatId) < 0
    })
    newSelection = [...newSelection, ...selection]

    dispatch({
      type: MAP_SET_SUBCATEGORY_SELECTION,
      selection: newSelection
    })
  }
}

export const toggleSubcategory = (id) => (dispatch, getState) => {
  const state = getState()
  const select = selectionSelector(state).indexOf(id) < 0
  dispatch({
    type: MAP_SELECT_SUBCATEGORY,
    id,
    select
  })
}

export const setMainInfoWindowOpen = (open) => ({
  type: MAP_SET_MAIN_INFO_WINDOW_OPEN,
  open
})

export const setInfoWindowOpen = (poiId, open) => ({
  type: MAP_SET_INFO_WINDOW_OPEN,
  poiId,
  open
})

export const closeAllInfoWindow = () => ({
  type: MAP_CLOSE_ALL_INFO_WINDOW
})

// export const categoriesSelector = (state) => state.map.categories
// export const subcategoriesSelector = (state) => state.map.subcategories
// export const poisSelector = (state) => state.map.pois
export const selectionSelector = (state) => state.map.selection
export const mainInfoWindowOpenSelector = (state) =>
  state.map.mainInfoWindowOpen
export const infoWindowsOpenSelector = (state) => state.map.infoWindowsOpen

const getChildrenElements = (promocion, taxonomy) => {
  if (!promocion || !taxonomy) {
    return []
  }
  const root = taxonomy.data.find((t) => t.name === promocion.name)
  return taxonomy.data.filter((t) => t.parent === root.id)
}

export const categoriesSelector = createSelector(
  [dataSelector, getCurrentPromocion],
  (data, promocion) => {
    const categories = findById(data, DATA_TYPE.MAP_CATEGORIES)
    const mapCategories = getChildrenElements(promocion, categories)
    return mapCategories.map((cat) => {
      const subcategories = categories.data.reduce((acc, subcat) => {
        if (subcat.parent === cat.id) {
          acc.push(DATA_TYPE.MAP_CATEGORIES + subcat.id)
        }
        return acc
      }, [])
      return {
        id: DATA_TYPE.MAP_CATEGORIES + cat.id,
        name: cat.name,
        subcategories
      }
    })
  }
)

export const subcategoriesSelector = createSelector([dataSelector], (data) => {
  const categories = findById(data, DATA_TYPE.MAP_CATEGORIES)
  if (!categories) {
    return []
  }
  const mapSubcategories = categories.data.filter((cat) => cat.parent !== 0)
  const media = findById(data, DATA_TYPE.MEDIA)
  return mapSubcategories.map((subcat) => {
    const iconMedia = findById(media.data, subcat.acf.icono)
    return {
      id: DATA_TYPE.MAP_CATEGORIES + subcat.id,
      name: subcat.name,
      icon: iconMedia ? iconMedia.source_url : ''
    }
  })
})

export const poisSelector = createSelector(
  [dataSelector, subcategoriesSelector, currentPromocionSelector],
  (data, subcategories, promocion) => {
    const pois = findById(data, DATA_TYPE.POINT_OF_INTEREST)
    if (!pois || promocion === '') {
      return []
    }
    const mapCategories = findById(data, DATA_TYPE.MAP_CATEGORIES)

    return pois.data.reduce((acc, poi) => {
      if (DATA_TYPE.PROMOCION + poi.acf.promocion_punto_interes === promocion) {
        const category = mapCategories.data.find(
          (cat) => cat.id === poi.tipo_punto_interes[0]
        )
        if (category && category.parent !== 0) {
          //Solo se añaden puntos con una subcategoria marcada
          acc.push({
            id: DATA_TYPE.POINT_OF_INTEREST + poi.id,
            name: poi.title.rendered,
            category: DATA_TYPE.MAP_CATEGORIES + category.id,
            subcategory: DATA_TYPE.MAP_CATEGORIES + poi.tipo_punto_interes[0],
            lng: parseFloat(poi.acf.longitud),
            lat: parseFloat(poi.acf.latitud),
            address: poi.acf.direccion ? poi.acf.direccion : '',
            content: poi.acf.contenido ? poi.acf.contenido : ''
          })
        }
      }

      return acc
    }, [])
  }
)

export const getCategory = () =>
  createSelector(
    [
      categoriesSelector,
      subcategoriesSelector,
      selectionSelector,
      (_, id) => id
    ],
    (categories, subcategories, selection, id) => {
      const cat = findById(categories, id)
      if (cat) {
        let catSelectedSubcategories = selection.filter((subCatId) => {
          return cat.subcategories.indexOf(subCatId) >= 0
        })
        return {
          ...cat,
          subcategoriesObjects: cat.subcategories.map((subCatId) =>
            findById(subcategories, subCatId)
          ),
          selectedSubcategories: catSelectedSubcategories
        }
      }
      return cat
    }
  )

export const getSubcategory = () =>
  createSelector(
    [subcategoriesSelector, (_, id) => id],
    (subcategories, id) => {
      return findById(subcategories, id)
    }
  )

export const getSubcategoryIsSelected = () =>
  createSelector([selectionSelector, (_, id) => id], (selection, id) => {
    return selection.indexOf(id) >= 0
  })

export const getFilterPois = createSelector(
  [poisSelector, selectionSelector, subcategoriesSelector],
  (pois, selection, subcategories) => {
    // let res = pois
    // if (selected.size > 0) {
    //   res = res.filter((poi) => selected.has(poi.subcategory))
    // }
    const res = pois.filter((poi) => selection.indexOf(poi.subcategory) >= 0)
    return res.map((poi) => {
      const subcategory = findById(subcategories, poi.subcategory)
      return { ...poi, icon: subcategory ? subcategory.icon : '' }
    })
  }
)
