import { useEffect, useState, useCallback } from 'react'
// import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'

import { settings } from '../../settings/settings'
import { getCurrentPromocion } from '../../modules/reducerPromocion'
import {
  setMainInfoWindowOpen,
  mainInfoWindowOpenSelector,
  infoWindowsOpenSelector,
  setInfoWindowOpen,
  closeAllInfoWindow,
  getFilterPois
} from '../../modules/reducerMap'

import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow
} from '@react-google-maps/api'

// const useStyles = makeStyles((theme) => ({
//   root: {
//   }
// }))

const containerStyle = {
  width: '100%',
  height: '100%'
}

const Map = () => {
  // const classes = useStyles()
  const [map, setMap] = useState(null)
  const [mapOptions, setMapOptions] = useState(null)
  const pois = useSelector(getFilterPois)
  const currentPromocion = useSelector(getCurrentPromocion)
  const mainInfoWindowOpen = useSelector(mainInfoWindowOpenSelector)
  const infoWindowsOpen = useSelector(infoWindowsOpenSelector)
  const dispatch = useDispatch()

  const getMapOptions = (maps) => {
    return {
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: maps.ControlPosition.BOTTOM_CENTER
      },
      // zoomControl: true,
      // zoomControlOptions: {
      //   position: maps.ControlPosition.BOTTOM_RIGHT
      // },
      scaleControl: false,
      streetViewControl: false,
      // streetViewControlOptions: {
      //   position: maps.ControlPosition.RIGHT_CENTER
      // },
      fullscreenControl: false
    }
  }

  const onLoad = useCallback((map) => {
    setMap(map)
    setMapOptions(getMapOptions(window.google.maps))
  }, [])

  const handleMainMarkerClose = useCallback(
    (map) => {
      dispatch(setMainInfoWindowOpen(false))
    },
    [dispatch]
  )

  const handleMainMarkerOpen = useCallback(
    (map) => {
      dispatch(setMainInfoWindowOpen(true))
    },
    [dispatch]
  )

  const handleMarkerClose = useCallback(
    (poiId) => {
      dispatch(setInfoWindowOpen(poiId, false))
    },
    [dispatch]
  )

  const handleMarkerOpen = useCallback(
    (poiId) => {
      dispatch(setInfoWindowOpen(poiId, true))
    },
    [dispatch]
  )

  useEffect(() => {
    dispatch(closeAllInfoWindow())
    if (map) {
      if (currentPromocion) {
        map.setCenter({ lat: currentPromocion.lat, lng: currentPromocion.lng })
      } else {
        map.setCenter({
          lat: settings.map.defaultPoint.lat,
          lng: settings.map.defaultPoint.lng
        })
      }
    }
  }, [map, currentPromocion, dispatch])

  return (
    <LoadScript googleMapsApiKey={settings.map.apiKey}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={15}
        options={mapOptions}
        onLoad={onLoad}
      >
        {currentPromocion && (
          <Marker
            position={{ lat: currentPromocion.lat, lng: currentPromocion.lng }}
            onClick={handleMainMarkerOpen}
          >
            {mainInfoWindowOpen && (
              <InfoWindow onCloseClick={handleMainMarkerClose}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<b>${currentPromocion.name}</b><br>${currentPromocion.address}`
                  }}
                ></div>
              </InfoWindow>
            )}
          </Marker>
        )}
        {pois.map((poi) => (
          <Marker
            key={poi.id}
            position={{ lat: poi.lat, lng: poi.lng }}
            icon={poi.icon ? { url: poi.icon } : null}
            onClick={() => handleMarkerOpen(poi.id)}
          >
            {infoWindowsOpen.indexOf(poi.id) >= 0 && (
              <InfoWindow onCloseClick={() => handleMarkerClose(poi.id)}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<b>${poi.name}</b><br>${poi.address}<br>${poi.content}`
                  }}
                ></div>
              </InfoWindow>
            )}
          </Marker>
        ))}
      </GoogleMap>
    </LoadScript>
  )
}
export default Map
