import { createSelector } from 'reselect'
import { dataSelector, DATA_TYPE, MEDIA_TYPE } from './reducerRead'
import { setUrl } from './reducerPDFViewer'
import { findById, selectItem } from '../utils/utils'

export const DOC_SET_CURRENT_DOCUMENT = 'DOC_SET_CURRENT_DOCUMENT'
export const DOC_ADD_DOWNLOAD = 'DOC_ADD_DOWNLOAD'
export const DOC_REMOVE_DOWNLOAD = 'DOC_REMOVE_DOWNLOAD'

const initialState = {
  currentDocument: '',
  download: []
}

const changeState = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case DOC_SET_CURRENT_DOCUMENT:
      return { ...state, currentDocument: action.id }
    case DOC_ADD_DOWNLOAD:
      return { ...state, download: selectItem(state.download, action.id, true) }
    case DOC_REMOVE_DOWNLOAD:
      return {
        ...state,
        download: selectItem(state.download, action.id, false)
      }
    default:
      return state
  }
}
export default changeState

export const setCurrentDocument = (id) => (dispatch, getState) => {
  const state = getState()
  const doc = findById(documentsSelector(state), id)
  if (doc) {
    dispatch({
      type: DOC_SET_CURRENT_DOCUMENT,
      id
    })
    dispatch(setUrl(doc.src))
  }
}

export const addDownload = (id) => ({
  type: DOC_ADD_DOWNLOAD,
  id
})

export const removeDownload = (id) => ({
  type: DOC_REMOVE_DOWNLOAD,
  id
})

export const currentDocumentSelector = (state) =>
  state.documents.currentDocument
// export const documentsSelector = (state) => state.documents.documents
export const documentsSelector = createSelector([dataSelector], (data) => {
  const media = findById(data, DATA_TYPE.MEDIA)
  return media
    ? media.data.reduce((acc, doc) => {
        if (doc.mime_type === 'application/pdf') {
          acc.push({
            id: MEDIA_TYPE.PDF + doc.id,
            name: doc.title.rendered,
            src: doc.source_url
          })
        }
        return acc
      }, [])
    : []
})

export const downloadSelector = (state) => state.documents.download

export const getDocument = () =>
  createSelector([documentsSelector, (_, id) => id], (documents, id) => {
    return findById(documents, id)
  })

export const getDocumentBySrc = () =>
  createSelector([documentsSelector, (_, src) => src], (documents, src) => {
    return documents.find((v) => v.src === src)
  })

export const getDocuments = createSelector(
  [documentsSelector],
  (documents) => (ids) => {
    return ids ? documents.filter((doc) => ids.indexOf(doc.id) >= 0) : []
  }
)

//Devuelve cuantos documentos de ids estan en la lista de descarga
export const getNDocumentsToDownload = (state) => (ids) => {
  const downloads = downloadSelector(state)
  let n = 0
  if (ids) {
    for (let docId of ids) {
      if (downloads.indexOf(docId) >= 0) {
        n++
      }
    }
  }
  return n
}
