// import { useCallback } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import LogoIcon from '../Icons/LogoIcon'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 180,
    height: theme.componentsData.menuMainButton.height,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main
  },
  icon: {
    stroke: theme.palette.primary.contrastText,
    width: 130
  }
}))

const Logo = () => {
  const classes = useStyles()
  // const dispatch = useDispatch()
  // const handleClick = useCallback(() => {
  //   dispatch(setOpen(true))
  // }, [dispatch])

  return (
    <div className={classes.root}>
      <LogoIcon className={classes.icon} />
    </div>
  )
}
export default Logo
