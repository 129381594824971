import { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import List from '@material-ui/core/List'

import MenuPanelContent from '../Menu/MenuPanelContent'
import DocumentItem from './DocumentItem'

import {
  setCurrentDocument,
  getDocuments,
  currentDocumentSelector
} from '../../modules/reducerDocuments'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    paddingTop: 0
  }
}))

const DocumentsMenu = ({ docIds }) => {
  const classes = useStyles()
  const gDocuments = useSelector(getDocuments)
  const documents = gDocuments(docIds)
  const currentDocument = useSelector(currentDocumentSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    if (docIds.indexOf(currentDocument) < 0) {
      dispatch(setCurrentDocument(''))
    }
    if (documents && documents.length > 0 && !currentDocument) {
      dispatch(setCurrentDocument(documents[0].id))
    }
  }, [dispatch, documents, currentDocument, docIds])

  return documents && documents.length ? (
    <MenuPanelContent>
      <List className={classes.root}>
        {documents.map((doc, idx) => (
          <DocumentItem key={idx} id={doc.id} name={doc.name} />
        ))}
      </List>
    </MenuPanelContent>
  ) : null
}

export default DocumentsMenu
