import { useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import MultiSelect from '../Common/Input/MultiSelect/MultiSelect'
import { getCategory, setCategorySelection } from '../../modules/reducerMap'
const useStyles = makeStyles((theme) => ({
  root: {}
}))

const MapCategorySelect = ({ catId }) => {
  const classes = useStyles()
  const gCategory = useMemo(getCategory, [])
  const category = useSelector((state) => gCategory(state, catId))
  const dispatch = useDispatch()

  const handleChange = useCallback(
    (selection) => {
      dispatch(setCategorySelection(catId, selection))
    },
    [dispatch, catId]
  )

  return category ? (
    <MultiSelect
      className={classes.root}
      label={category.name}
      emptyLabel={'Selecciona'}
      selection={category.selectedSubcategories}
      options={category.subcategoriesObjects}
      onSelectionChange={handleChange}
    />
  ) : null
}
export default MapCategorySelect
