const ImageIcon = (props) => {
  return (
    <svg fill="none" viewBox="0 0 24 18" {...props}>
      <path
        fill="#fff"
        stroke="stroke"
        d="M19.485 4.792c0 .614-.487 1.1-1.097 1.1s-1.097-.486-1.097-1.1c0-.613.487-1.1 1.097-1.1.563 0 1.097.499 1.097 1.1z"
      />
      <path
        stroke="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M21.995 17H2.005A.998.998 0 011 15.993V2.007A.998.998 0 012.005 1h19.99A.998.998 0 0123 2.007v14.045c0 .533-.473.948-1.005.948z"
      />
      <path
        stroke="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M1 16.585l6.505-9.777 5.382 6.577 3.43-3.733 6.506 6.933"
      />
    </svg>
  )
}
export default ImageIcon
