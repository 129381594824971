import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.contrastText,
    padding: '16px 0px 12px 0px'
  }
}))

const FilterOptionTitle = ({ title }) => {
  const classes = useStyles()

  return (
    <Typography variant="h4" className={classes.title}>
      {title}
    </Typography>
  )
}
export default FilterOptionTitle
