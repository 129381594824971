import { useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import {
  setPhone,
  getPhoneError,
  submitClickedSelector
} from '../../modules/reducerContact'

const useStyles = makeStyles((theme) => ({
  formControl: { width: '100%' },
  root: {
    boxSizing: 'border-box',
    padding: '0px 8px',

    height: '32px',
    alignItems: 'stretch',
    // border: '1px solid grey',
    // borderRadius: '5px',
    backgroundColor: theme.palette.primary.light,
    '& .PhoneInputInput': {
      color: theme.palette.primary.contrastText2,
      backgroundColor: 'transparent',
      fontSize: '20px',
      border: 'none',
      '&:focus': { outline: 'none' }
    }
  },
  label: {
    color: theme.palette.primary.contrastText,
    textTransform: 'capitalize',
    fontSize: '1rem',
    fontWeight: 400,
    '&.Mui-focused': {
      // color: theme.palette.secondary.main
      background: theme.palette.secondary.gradient,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent'
    },
    paddingTop: '20px',
    paddingBottom: '12px'
  }
}))

const CFPhoneInput = () => {
  const [focused, setFocused] = useState(false)
  const [localNumber, setLocalNumber] = useState('')
  const phoneError = useSelector(getPhoneError)
  const submitClicked = useSelector(submitClickedSelector)
  const classes = useStyles({ focused })
  const dispatch = useDispatch()

  const handlePhoneChange = useCallback((value) => {
    setLocalNumber(value)
  }, [])

  const handleOnBlur = useCallback(
    (e) => {
      setFocused(false)
      dispatch(setPhone(localNumber))
    },
    [dispatch, localNumber]
  )

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        dispatch(setPhone(localNumber))
      }
    },
    [dispatch, localNumber]
  )

  return (
    <FormControl className={classes.formControl} focused={focused}>
      <FormLabel className={classes.label}>Teléfono *</FormLabel>
      <PhoneInput
        className={classes.root}
        defaultCountry="ES"
        international={false}
        initialValueFormat="national"
        value={localNumber}
        onChange={handlePhoneChange}
        onFocus={() => setFocused(true)}
        onBlur={handleOnBlur}
        onKeyPress={handleKeyPress}
      />
      {submitClicked && phoneError !== '' && (
        <FormHelperText error>{phoneError}</FormHelperText>
      )}
    </FormControl>
  )
}
export default CFPhoneInput
