import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'

import Typography from '@material-ui/core/Typography'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import FavoriteButton from '../FavoriteButton'
import HousesNameCell from './HousesNameCell'

import {
  columnsSelector,
  getFinalTableHouses,
  COLUMNS
} from '../../../modules/reducerHousesTable'
import {
  currentHouseSelector,
  setCurrentHouse
} from '../../../modules/reducerHouses'

import { SUBMENUS, setCurrentSubmenu } from '../../../modules/reducerMain'
import { mouseOverHouse } from '../../../modules/reducerRotatorGroups'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '32px',
    boxSizing: 'border-box',
    color: 'red'
  },
  tableRow: {
    cursor: 'pointer',
    height: 32,
    backgroundColor: ({ row }) =>
      row % 2 ? 'transparent' : theme.palette.primary.light,
    color: ({ selected }) =>
      selected
        ? theme.palette.secondary.main
        : theme.palette.primary.contrastText,
    '&:hover': { color: theme.palette.secondary.main }
  },
  cell: {
    color: 'currentColor', // theme.palette.primary.contrastText,
    textTransform: 'none',
    whiteSpace: 'nowrap',
    // paddingRight: '28px',
    padding: 0,
    borderColor: 'transparent',
    // maxWidth: '10%',
    width: 50
  },
  contentWrapper: {
    display: 'block',
    maxWidth: '800px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  heartCell: {
    width: 20,
    borderColor: 'transparent',
    color: 'currentColor'
  },
  stateIcon: { padding: '0 8px' }
}))

const HousesTableRow = ({ row }) => {
  const columns = useSelector(columnsSelector)
  const currentHouse = useSelector(currentHouseSelector)
  const houses = useSelector(getFinalTableHouses)
  const house = houses[row]
  const dispatch = useDispatch()

  const classes = useStyles({
    selected: house && house.id === currentHouse,
    row
  })

  const handleClick = useCallback(
    (e) => {
      dispatch(setCurrentHouse(house.id))
      dispatch(setCurrentSubmenu(SUBMENUS.DETALLES_CASA))
    },
    [dispatch, house]
  )

  const handleMouseOver = useCallback(() => {
    dispatch(mouseOverHouse(house.id))
    // setHover(true)
  }, [dispatch, house])

  const handleMouseLeave = useCallback(() => {
    dispatch(mouseOverHouse())
    // setHover(false)
  }, [dispatch])

  const getCell = (col) => {
    if (col.id === COLUMNS.NAME) {
      return (
        <TableCell key={col.id} className={classes.cell} align={col.align}>
          <HousesNameCell name={house[col.id]} id={house.id} />
        </TableCell>
      )
    } else if (col.id === COLUMNS.FAVORITE) {
      return (
        <TableCell
          key={col.id}
          className={classes.heartCell}
          align={col.align}
          padding={'none'}
        >
          <FavoriteButton houseId={house.id} />
        </TableCell>
      )
    }
    return (
      <TableCell key={col.id} className={classes.cell} align={col.align}>
        <Typography className={classes.contentWrapper} variant="h5">
          {house[col.id]}
        </Typography>
      </TableCell>
    )
  }

  return house ? (
    <TableRow
      className={classes.tableRow}
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      {columns.map((col, idx) => getCell(col))}
    </TableRow>
  ) : null
}
export default HousesTableRow
