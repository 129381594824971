import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import MenuPanelContent from '../../Menu/MenuPanelContent'
import HouseDetails from './HouseDetails'
import HouseDetailsBar from './HouseDetailsBar'

import { getCurrentHouse } from '../../../modules/reducerHouses'
import { setCurrentGallery } from '../../../modules/reducerGallery'

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    paddingBottom: 16
  },
  buttonWrapper: {
    display: 'flex',
    flexFlow: 'column',
    padding: '12px 0 32px 0'
  },
  button: {
    marginBottom: 8,
    textTransform: 'none',
    borderRadius: 0,
    backgroundColor: theme.palette.other.sendButton,
    color: theme.palette.primary.light2,
    backgroundImage: 'none',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      backgroundImage: theme.palette.secondary.gradientHorizontal
    }
  }
}))

const HouseDetailsMenu = () => {
  const classes = useStyles()
  const currentHouse = useSelector(getCurrentHouse)
  const dispatch = useDispatch()

  useEffect(() => {
    if (currentHouse) {
      dispatch(setCurrentGallery(currentHouse.gallery))
    }
  }, [currentHouse, dispatch])

  return currentHouse ? (
    <MenuPanelContent>
      <Typography variant="h4" className={classes.title}>
        {currentHouse.name}
      </Typography>
      <HouseDetails houseId={currentHouse.id} />
      <div className={classes.buttonWrapper}>
        <Button className={classes.button}>Tour virtual</Button>
        <Button className={classes.button}>Configura tu vivienda</Button>
      </div>

      <HouseDetailsBar houseId={currentHouse.id} />
    </MenuPanelContent>
  ) : null
}
export default HouseDetailsMenu
