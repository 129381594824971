import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import CloseIcon from '../../../Icons/CloseIcon'

const useStyles = makeStyles((theme) => ({
  chip: {
    height: 24,
    maxWidth: 150,
    margin: 4,
    borderRadius: 2,
    color: theme.palette.primary.main,
    backgroundImage: theme.palette.secondary.gradientHorizontal
    // backgroundColor: theme.palette.secondary.main
  },
  label: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  closeIcon: { fill: theme.palette.primary.main, width: 8, minWidth: 8 }
}))

const MultiSelectChip = ({ label, id, onDelete, ...props }) => {
  const classes = useStyles()
  const handleDelete = (e) => {
    if (onDelete) {
      onDelete(id)
    }
  }

  return (
    <Chip
      label={label}
      classes={{ root: classes.chip, label: classes.label }}
      deleteIcon={<CloseIcon className={classes.closeIcon} />}
      onDelete={handleDelete}
      onMouseDown={(event) => {
        event.stopPropagation()
      }}
    />
  )
}
export default MultiSelectChip
