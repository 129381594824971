import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import Button from '@material-ui/core/Button'

import DownloadAllIcon from '../Icons/DownloadAllIcon'
import {
  downloadSelector,
  getDocuments,
  removeDownload
} from '../../modules/reducerDocuments'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-end'
  },
  button: {
    textTransform: 'none',
    color: theme.palette.primary.contrastText,
    '&:hover': { color: theme.palette.secondary.main },
    '&:disabled': { color: theme.palette.primary.contrastText2 }
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    stroke: 'currentColor', //theme.palette.primary.contrastText,
    width: 'auto',
    height: 20,
    paddingRight: 8
  }
}))

const DownloadAllButton = () => {
  const classes = useStyles()
  const downloads = useSelector(downloadSelector)
  const gDocuments = useSelector(getDocuments)
  const dispatch = useDispatch()

  const handleDownloadAllClick = useCallback(
    (e) => {
      const docs = gDocuments(downloads)
      for (let doc of docs) {
        const a = document.createElement('a')
        a.href = doc.src
        a.download = `${doc.name}.pdf`
        a.click()
        a.remove()
        dispatch(removeDownload(doc.id))
      }
    },
    [downloads, gDocuments, dispatch]
  )

  return (
    <div className={classes.root}>
      <Button
        disabled={downloads.length === 0}
        classes={{ root: classes.button, label: classes.label }}
        color="secondary"
        onClick={handleDownloadAllClick}
      >
        <DownloadAllIcon className={classes.icon} />
        Descargar todo
      </Button>
    </div>
  )
}

export default DownloadAllButton
