import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import GradientText from '../Common/GradientText'
import CFPrivacyPolicyDialog from './CFPrivacyPolicyDialog'
// import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
// import CheckBoxIcon from '@material-ui/icons/CheckBox'

import {
  agreeSelector,
  setAgree,
  submitClickedSelector
} from '../../modules/reducerContact'
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    paddingTop: 40
  },
  policyButton: {
    color: theme.palette.secondary.main,
    cursor: 'pointer'
  },
  label: {
    color: ({ error }) => (error ? 'red' : theme.palette.primary.contrastText2),
    marginRight: 4
  },
  checkbox: {
    color: ({ error }) => (error ? 'red' : theme.palette.primary.contrastText2)
  }
}))

const CFPrivacyPolicy = () => {
  const [open, setOpen] = useState(false)
  const agree = useSelector(agreeSelector)
  const submitClicked = useSelector(submitClickedSelector)
  const dispatch = useDispatch()
  const classes = useStyles({ error: submitClicked && !agree })

  const handleClick = useCallback(
    (e) => {
      dispatch(setAgree(e.target.checked))
    },
    [dispatch]
  )

  const handleOpenPolicyDialog = useCallback((e) => {
    setOpen(true)
  }, [])

  const handleClosePolicyDialog = useCallback((e) => {
    setOpen(false)
  }, [])

  return (
    <div className={classes.root}>
      <FormControlLabel
        className={classes.label}
        control={
          <Checkbox
            className={classes.checkbox}
            checked={agree}
            onChange={handleClick}
          />
        }
        label={<Typography variant="h5">He leído y acepto la</Typography>}
      />
      <div className={classes.policyButton} onClick={handleOpenPolicyDialog}>
        <GradientText variant="h5">Política de privacidad.</GradientText>
      </div>
      <CFPrivacyPolicyDialog open={open} onClose={handleClosePolicyDialog} />
    </div>
  )
}
export default CFPrivacyPolicy
