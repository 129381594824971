import { useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
// import { useSelector, useDispatch } from 'react-redux'
import { animated, useTransition } from 'react-spring'
import HideBarButton from './HideBarButton'
import ShowBarButton from './ShowBarButton'
// import { barVisibleSelector } from '../../../modules/reducerMaster'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
    margin: 'auto',
    maxWidth: '500px',
    // height: 50,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap'
  }
}))

const BottomBar = ({ children }) => {
  const [barVisible, setBarVisible] = useState(true)
  const classes = useStyles()
  // const barVisible = useSelector(barVisibleSelector)

  const handleHideBar = useCallback((e) => {
    e.stopPropagation()
    setBarVisible(false)
  }, [])

  const handleShowBar = useCallback((e) => {
    e.stopPropagation()
    setBarVisible(true)
  }, [])

  const transition = useTransition(barVisible, {
    from: { bottom: -50 },
    enter: { bottom: 10 },
    leave: { bottom: -50 }
  })

  return transition((styles, item) =>
    item ? (
      <animated.div key={0} style={styles} className={classes.root}>
        {children}
        <HideBarButton barVisible={barVisible} onClick={handleHideBar} />
      </animated.div>
    ) : (
      <animated.div key={1} style={styles} className={classes.root}>
        <ShowBarButton barVisible={barVisible} onClick={handleShowBar} />
      </animated.div>
    )
  )
}
export default BottomBar
