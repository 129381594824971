import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'

import DownloadDocIcon from '../Icons/DownloadDocIcon'
import {
  getNDocumentsToDownload,
  addDownload
} from '../../modules/reducerDocuments'
const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: '0 4px',
    color: ({ selected }) =>
      selected ? theme.palette.secondary.main : 'currentColor',
    '&:hover': { color: theme.palette.secondary.main },
    '&:disabled': { color: '#707070' }
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

const AddDownloadButton = ({ ids, width, height, onDownload }) => {
  const gNDocumentsToDownload = useSelector(getNDocumentsToDownload)
  const allSelected = ids && gNDocumentsToDownload(ids) === ids.length
  const dispatch = useDispatch()
  const classes = useStyles({ selected: onDownload ? false : allSelected })

  const handleDownload = useCallback(
    (e) => {
      e.stopPropagation()
      for (let docId of ids) {
        if (onDownload) {
          onDownload()
        } else {
          dispatch(addDownload(docId))
        }
      }
    },
    [dispatch, ids, onDownload]
  )

  return ids && ids.length ? (
    <Tooltip title={onDownload ? 'Descargar' : 'Añadir a descargas'} arrow>
      <div className={classes.buttonWrapper}>
        <IconButton
          className={classes.iconButton}
          disabled={onDownload ? false : allSelected}
          onClick={handleDownload}
        >
          <DownloadDocIcon
            width={width}
            height={height}
            stroke={'currentColor'}
          />
        </IconButton>
      </div>
    </Tooltip>
  ) : null
}
export default AddDownloadButton
