import { useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'
import mergeRefs from 'react-merge-refs'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Backdrop from '@material-ui/core/Backdrop'
import Popper from '@material-ui/core/Popper'

const WIDTH = 320
const IMAGE_HEIGHT = 160
const useStyles = makeStyles((theme) => ({
  backdrop: { zIndex: 1000, position: 'absolute' },
  popper: {
    zIndex: 1001,
    backgroundColor: '#093D58', // theme.palette.primary.main,
    color: 'white', // theme.palette.primary.contrastText,
    borderTopLeftRadius: WIDTH / 3,
    borderBottomRightRadius: WIDTH / 3,
    width: WIDTH,
    maxHeight: ({ maxHeight }) => maxHeight,
    display: 'flex',
    flexFlow: 'column'
  },
  image: {
    width: '100%',
    height: IMAGE_HEIGHT,
    maxHeight: ({ maxHeight }) => maxHeight * 0.5,
    objectFit: 'cover',
    borderTopLeftRadius: WIDTH / 3
  },
  rainbow: { width: '100%', height: 4, backgroundColor: 'red' },
  contentWrapper: {
    boxSizing: 'border-box',
    padding: '12px 24px 24px 12px',
    width: '100%',
    maxHeight: ({ maxHeight }) => maxHeight * 0.5,
    display: 'flex',
    flexFlow: 'column',
    borderBottomRightRadius: WIDTH / 3
  },
  title: { paddingBottom: 12 },
  text: {
    height: '100%',
    maxHeight: 240,
    overflow: 'auto'
    // scrollbarWidth: 'thin',
    // '&::-webkit-scrollbar': {
    //   width: '6px',
    //   height: '8px',
    //   background: '#0C3145'
    // },
    // '&::-webkit-scrollbar-thumb': {
    //   background: '#6B818E',
    //   borderRadius: 10
    // }
  },
  button: {
    backgroundColor: '#20BAFC',
    color: 'white',
    textTransform: 'none',
    padding: 4,
    maxWidth: '50%',
    marginTop: 12
  }
}))

const PanoWindow = ({
  open,
  x, //Coordenadas de iframe
  y, //Coordenadas de iframe
  title,
  text,
  imageSrc,
  onClose
}) => {
  const [boundsRef, bounds] = useMeasure({ polyfill: ResizeObserver })
  const backdropRef = useRef()
  const classes = useStyles({
    x,
    y,
    maxHeight: bounds ? bounds.height - 24 : null
  })

  const virtualReference = () => {
    const width = 10
    const height = 10
    const globalX = x + bounds.left
    const globalY = y + bounds.top
    return {
      clientWidth: width,
      clientHeight: height,
      getBoundingClientRect: () => {
        return {
          top: globalY - height / 2,
          left: globalX - width / 2,
          bottom: globalY + height / 2,
          right: globalX + width / 2,
          width: width,
          height: height
        }
      }
    }
  }

  return open ? (
    <>
      <Backdrop
        ref={mergeRefs([backdropRef, boundsRef])}
        className={classes.backdrop}
        invisible
        open={open}
        onClick={onClose}
      />
      <Popper
        disablePortal
        className={classes.popper}
        anchorEl={virtualReference()}
        open={true}
        placement={'top'}
        popperOptions={{ positionFixed: true }}
        modifiers={{
          offset: {
            enabled: true,
            offset: '0%,-50%p'
          },
          keepTogether: {
            enabled: true
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: backdropRef.current
          }
        }}
      >
        <img className={classes.image} src={imageSrc} alt="Imagen fondo" />
        <div className={classes.rainbow}></div>
        <div className={classes.contentWrapper}>
          <Typography variant="h3" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="h4" className={classes.text}>
            {text}
          </Typography>
          <Button className={classes.button} variant="contained">
            No veo que pone
          </Button>
        </div>
      </Popper>
    </>
  ) : null
}
export default PanoWindow
