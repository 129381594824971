import { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'

import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import {
  sortColumnSelector,
  columnClicked,
  getColumn
} from '../../../modules/reducerHousesTable'

const useStyles = makeStyles((theme) => ({
  cell: {
    // maxWidth: '100%',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    textTransform: 'none',
    color: theme.palette.primary.contrastText,
    '&:hover': { color: theme.palette.secondary.main },

    padding: 0
    // width: 50,
    // minWidth: 0
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}))

const HousesTableHeadCell = ({ colId }) => {
  const gColumn = useMemo(getColumn, [])
  const column = useSelector((state) => gColumn(state, colId))
  const classes = useStyles()
  const sortColumn = useSelector(sortColumnSelector)
  const dispatch = useDispatch()

  const handleClick = useCallback(
    (colId) => {
      dispatch(columnClicked(colId))
    },
    [dispatch]
  )

  return column ? (
    <TableCell
      className={classes.cell}
      align={column.align}
      padding={column.padding}
      sortDirection={column.sortOrder}
    >
      {column.sortEnabled ? (
        <TableSortLabel
          active={sortColumn === column.id}
          direction={column.sortOrder}
          IconComponent={ArrowDropUpIcon}
          onClick={() => handleClick(column.id)}
        >
          {column.label}
          {sortColumn === column.id ? (
            <span className={classes.visuallyHidden}>
              {column.sortOrder === 'desc'
                ? 'sorted descending'
                : 'sorted ascending'}
            </span>
          ) : null}
        </TableSortLabel>
      ) : (
        <>{column.label}</>
      )}
    </TableCell>
  ) : null
}
export default HousesTableHeadCell
