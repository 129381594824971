import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

import ScrollDiv from '../../Common/ScrollDiv'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import HousesTableHead from './HousesTableHead'
import HousesTableRow from './HousesTableRow'

import { getFinalTableHouses } from '../../../modules/reducerHousesTable'

const useStyles = makeStyles((theme) => ({
  root: {
    // maxHeight: 200,
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    width: '100%',
    paddingRight: theme.componentsData.menuPanel.paddingRight
  },
  tableRoot: {
    overflow: 'visible'
  },
  table: {
    borderSpacing: '10px',
    border: 'none',
    borderCollapse: 'collapse'
  }
}))

export default function HousesTable() {
  const classes = useStyles()
  const houses = useSelector(getFinalTableHouses)

  // const dispatch = useDispatch()

  return (
    <ScrollDiv className={classes.root}>
      <TableContainer className={classes.tableRoot}>
        <Table className={classes.table}>
          <HousesTableHead />
          <TableBody>
            {houses.map((house, idx) => (
              <HousesTableRow key={house.id} row={idx} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ScrollDiv>
  )
}
