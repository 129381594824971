import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useTransition, animated } from 'react-spring'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

import ScrollDiv from '../Common/ScrollDiv'
import GradientText from '../Common/GradientText'
import CFPrivacyPolicy from './CFPrivacyPolicy'
import CFNameInput from './CFNameInput'
import CFLastNameInput from './CFLastNameInput'
import CFEmailInput from './CFEmailInput'
import CFPhoneInput from './CFPhoneInput'
import CFSendButton from './CFSendButton'
import CFCommentsInput from './CFCommentsInput'

import { openSelector, setOpen } from '../../modules/reducerContact'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: theme.componentsData.contactForm.top,
    backgroundColor: theme.palette.primary.mainTransparent,

    boxSizing: 'border-box',
    padding: '24px 0 0 28px',
    width: theme.componentsData.contactForm.width,
    maxHeight: `calc(100vh - ${theme.componentsData.menuMainButton.top}px - 20px)`,
    display: 'flex',
    flexFlow: 'column',
    zIndex: theme.zIndex.contactForm
  },
  closeButton: {
    padding: 0,
    position: 'absolute',
    top: 8,
    right: 8,
    color: theme.palette.primary.contrastText
  },
  titleWrapper: { boxSizing: 'border-box', width: '100%', paddingRight: 32 },
  subtitle: { color: theme.palette.primary.contrastText2 },
  inputsWrapper: { paddingRight: 32 }
}))

const CFForm = () => {
  const classes = useStyles()
  const theme = useTheme()
  const open = useSelector(openSelector)
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    dispatch(setOpen(false))
  }, [dispatch])

  const transition = useTransition(open, {
    from: { opacity: 0, right: -theme.componentsData.contactForm.width },
    enter: { opacity: 1, right: theme.componentsData.contactForm.right },
    leave: { opacity: 0, right: -theme.componentsData.contactForm.width }
  })

  return transition((styles, item) =>
    item ? (
      <animated.div style={styles} className={classes.root}>
        <IconButton className={classes.closeButton} onClick={handleClick}>
          <CloseIcon />
        </IconButton>
        <div className={classes.titleWrapper}>
          <GradientText variant="h2">Contacta con nosotros</GradientText>
          <Typography variant="h4" className={classes.subtitle}>
            Deja tus datos y nos pondremos en contacto contigo
          </Typography>
        </div>
        <ScrollDiv className={classes.inputsWrapper}>
          <CFNameInput />
          <CFLastNameInput />
          <CFPhoneInput />
          <CFEmailInput />
          <CFCommentsInput />
          <CFPrivacyPolicy />
        </ScrollDiv>
        <CFSendButton />
      </animated.div>
    ) : null
  )
}
export default CFForm
