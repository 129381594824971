import { createSelector } from 'reselect'
import {
  setNSteps,
  setImageSize,
  setSpeed,
  stepSelector,
  setMouseInc
} from './reducerRotator'
import { getFilterHouses } from './reducerHousesFilter'
import { housesSelector } from './reducerHouses'
import { findById } from '../utils/utils'

import { group0 } from '../rotatorData/group0'
import { aresII } from '../rotatorData/aresII'

export const ROTATOR_SET_GROUP = 'ROTATOR_SET_GROUP'
export const ROTATOR_SET_IMAGES = 'ROTATOR_SET_IMAGES'
export const ROTATOR_MOUSE_OVER_SEGMENT = 'ROTATOR_MOUSE_OVER_SEGMENT'
// export const ROTATOR_SELECT_SEGMENT = 'ROTATOR_SELECT_SEGMENT'

const initialState = {
  groups: [group0, aresII],
  currentGroup: '',
  images: [], //Imagenes descargadas
  hoverSegment: ''
  // selectedSegments: []
}

const changeState = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case ROTATOR_SET_GROUP: {
      if (action.id === state.currentGroup) {
        return state
      }

      return {
        ...state,
        currentGroup: action.id,
        images: [],
        hoverSegment: ''
        // selectedSegments: []
      }
    }
    case ROTATOR_SET_IMAGES:
      return { ...state, images: action.images }
    case ROTATOR_MOUSE_OVER_SEGMENT:
      return {
        ...state,
        hoverSegment: action.segment
      }
    // case ROTATOR_SELECT_SEGMENT: {
    //   return {
    //     ...state,
    //     selectedSegments: selectItem(
    //       state.selectedSegments,
    //       action.id,
    //       action.select
    //     )
    //   }
    // }
    default:
      return state
  }
}
export default changeState

export const setGroup = (groupId) => (dispatch, getState) => {
  const state = getState()
  const groups = groupsSelector(state)
  const group = findById(groups, groupId)
  if (group) {
    dispatch({ type: ROTATOR_SET_GROUP, id: groupId })
    dispatch(setNSteps(group.images.length))
    dispatch(setImageSize(group.imageSize))
    dispatch(setSpeed(group.rotatorSpeed))
    dispatch(setMouseInc(group.mouseInc))
  } else {
    dispatch({ type: ROTATOR_SET_GROUP, id: '' })
  }
}
export const setImages = (images) => ({
  type: ROTATOR_SET_IMAGES,
  images
})

export const mouseOverSegment = (segment) => ({
  type: ROTATOR_MOUSE_OVER_SEGMENT,
  segment
})

export const mouseOverHouse = (houseId) => (dispatch, getState) => {
  const state = getState()
  const segmentHouses = getCurrentGroupHousesData(state)(housesSelector(state))
  const segmentHouse = segmentHouses.data.find((sh) => sh.house.id === houseId)
  dispatch({
    type: ROTATOR_MOUSE_OVER_SEGMENT,
    segment: segmentHouse ? segmentHouse.segmentId : ''
  })
}

// export const setSegmentSelected = (id, select) => ({
//   type: ROTATOR_SELECT_SEGMENT,
//   id,
//   select
// })
//
// export const toggleSegmentSelection = (id) => (dispatch, getState) => {
//   const state = getState()
//   const select = selectedSegmentSelector(state).indexOf(id) < 0
//   dispatch({
//     type: ROTATOR_SELECT_SEGMENT,
//     id,
//     select
//   })
// }

export const currentGroupSelector = (state) => state.rotatorGroups.currentGroup
export const groupsSelector = (state) => state.rotatorGroups.groups
export const imagesSelector = (state) => state.rotatorGroups.images
export const hoverSegmentSelector = (state) => state.rotatorGroups.hoverSegment
// export const selectedSegmentSelector = (state) =>
//   state.rotatorGroups.selectedSegments

export const getGroup = createSelector(
  [groupsSelector],
  (groups) => (groupId) => {
    return groups.find((g) => g.id === groupId)
  }
)

export const getGroupById = () =>
  createSelector([groupsSelector, (_, id) => id], (groups, id) => {
    return findById(groups, id)
  })

//Grupo actual
export const getCurrentGroup = createSelector(
  [groupsSelector, currentGroupSelector],
  (groups, currentGroup) => {
    return findById(groups, currentGroup)
  }
)

//Segmento en el grupo y paso actual
export const getSegment = () =>
  createSelector(
    [getCurrentGroup, stepSelector, (_, id) => id],
    (group, step, id) => {
      return group.segments[step].find((segment) => segment.color === id)
    }
  )

export const getSegmentUserData = () =>
  createSelector([getCurrentGroup, (_, id) => id], (group, id) => {
    return group.userData.find((ud) => ud.segmentId === id)
  })

const getPoi = (group, step, color, id) => {
  if (!group || step >= group.segments.length || step < 0 || !color || !id) {
    return null
  }
  const segment = group.segments[step].find((s) => s.color === color)
  const pois = segment ? segment.pois : []
  return pois.find((poi) => poi.id === id)
}

//Centroid del segmento en el grupo y paso actuales
export const getCurrentLocalCentroid = createSelector(
  [getCurrentGroup, stepSelector],
  (currentGroup, currentStep) => (color) => {
    const poi = getPoi(currentGroup, currentStep, color, 'centroid')
    return poi ? poi.point : [0, 0]
  }
)

//Centroid del segmento en el grupo y paso
export const getLocalCentroid = createSelector(
  [groupsSelector],
  (groups) => (groupId, step, color) => {
    const poi = getPoi(findById(groups, groupId), step, color, 'centroid')
    return poi ? poi.point : [0, 0]
  }
)

//Considera a groupId como un grupo de casas e intenta obtener la informacion
//de las casas que se le pasan
export const getGroupHousesData = createSelector(
  [groupsSelector],
  (groups) => (groupId, houses) => {
    let data = []
    const group = findById(groups, groupId)
    if (group && group.userData) {
      data = group.userData.reduce((acc, ud) => {
        const house = houses.find((h) => h.name === ud.houseName)
        if (house) {
          acc.push({ segmentId: ud.segmentId, house })
        }
        return acc
      }, [])
    }
    return { group, data }
  }
)

//Considera al grupo actual como un grupo de casas e intenta obtener la informacion
//de las casas que se le pasan
export const getCurrentGroupHousesData = createSelector(
  [getGroupHousesData, currentGroupSelector],
  (gGroupHousesData, currentGroup) => (houses) => {
    return gGroupHousesData(currentGroup, houses)
  }
)

//Considera al grupo actual como un grupo de casas e intenta obtener la informacion
//de las casas que han pasado el filtro
export const getCurrentGroupFilteredHousesData = createSelector(
  [getCurrentGroupHousesData, getFilterHouses],
  (gCurrentGroupHousesData, houses) => {
    return gCurrentGroupHousesData(houses)
  }
)
