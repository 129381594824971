// import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import RemoveIcon from '../Icons/RemoveIcon'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 16,
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-end'
  },
  button: {
    textTransform: 'none',
    color: theme.palette.primary.contrastText,
    '&:hover': { color: theme.palette.secondary.main }
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  removeIcon: {
    stroke: 'currentColor', //theme.palette.primary.contrastText,
    width: 'auto',
    height: 16,
    paddingRight: 8
  }
}))

const RemoveFiltersFooter = ({ onClick }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Button
        classes={{ root: classes.button, label: classes.label }}
        color="secondary"
        onClick={onClick}
      >
        <RemoveIcon className={classes.removeIcon} />
        Eliminar filtros
      </Button>
    </div>
  )
}
export default RemoveFiltersFooter
