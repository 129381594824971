// import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'

import MinimizeIcon from '../../Icons/MinimizeIcon'
import MaximizeIcon from '../../Icons/MaximizeIcon'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    // boxShadow: `0 0 10px 0 ${theme.palette.primary.main}`,
    '&:hover': { color: theme.palette.secondary.main },
    position: 'absolute',
    right: 2,
    top: 2
  },
  icon: { fill: 'currentColor', stroke: 'currentColor' }
}))

const GalleryExpandButton = ({ expanded, currentIdx, onClick }) => {
  const classes = useStyles()

  return (
    <IconButton className={classes.root} onClick={onClick}>
      {expanded ? (
        <MinimizeIcon width={20} className={classes.icon} />
      ) : (
        <MaximizeIcon width={20} className={classes.icon} />
      )}
    </IconButton>
  )
}
export default GalleryExpandButton
