import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import EditorTextField from '../Common/Input/EditorTextField'

import { commentsSelector, setComments } from '../../modules/reducerContact'

const useStyles = makeStyles((theme) => ({
  textField: { width: '100%' }
}))

const CFCommentsInput = () => {
  const classes = useStyles()
  const comments = useSelector(commentsSelector)
  const dispatch = useDispatch()

  const handleCommentsChange = useCallback(
    (value) => {
      dispatch(setComments(value))
    },
    [dispatch]
  )

  return (
    <EditorTextField
      className={classes.textField}
      label="Comentarios"
      multiline
      rows={4}
      value={comments}
      onChange={handleCommentsChange}
    />
  )
}
export default CFCommentsInput
