const ContactIcon = (props) => {
  return (
    <svg fill="none" viewBox="0 0 27 20" {...props}>
      <path
        stroke="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M26 17.071C26 18.1 25.167 19 24.214 19H2.786C1.833 19 1 18.1 1 17.071V2.93C1 1.9 1.833 1 2.786 1h21.428C25.167 1 26 1.9 26 2.929V17.07z"
      />
      <path
        stroke="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M25.405 1.643L13.5 11.93 1.595 1.643"
      />
    </svg>
  )
}
export default ContactIcon
