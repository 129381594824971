import { createSelector } from 'reselect'
import { findById } from '../utils/utils'
import { CONTACT_FORM_SET_OPEN } from './reducerContact'
import { setOpen as setContactFormOpen } from './reducerContact'
const SMALL_WIDTH = 850

export const SET_MENU_OPEN = 'SET_MENU_OPEN'
export const SET_CURRENT_MENU_OPTION = 'SET_CURRENT_MENU_OPTION'
export const SET_CURRENT_SUBMENU = 'SET_CURRENT_SUBMENU'
export const HOME_CLICKED = 'HOME_CLICKED'
export const BACK_CLICKED = 'BACK_CLICKED'
export const OPTION_CLICKED = 'OPTION_CLICKED'
export const SET_MENU_OPTION_EXPANDED = 'SET_MENU_OPTION_EXPANDED'
export const SET_ROOT_SIZE = 'SET_ROOT_SIZE'
export const SET_DRAG_ENABLED = 'SET_DRAG_ENABLED'
export const SET_VIEW_3D_MODE = 'SET_VIEW_3D_MODE'

export const MENU_OPTIONS = {
  VISTA_GENERAL: 'VISTA_GENERAL',
  PLANO_SITUACION: 'PLANO_SITUACION',
  SELECCIONA_VIVIENDA: 'SELECCIONA_VIVIENDA',
  GALERIA_IMAGENES: 'GALERIA_IMAGENES',
  DOCUMENTOS: 'DOCUMENTOS',
  DESCARGAS: 'DESCARGAS'
}

export const SUBMENUS = {
  PLANO_SITUACION: 'PLANO_SITUACION',
  FILTRO: 'FILTRO',
  DETALLES_CASA: 'DETALLES_CASA',
  DOCUMENTOS_PROMOCION: 'DOCUMENTOS_PROMOCION',
  DOCUMENTOS_VIVIENDA: 'DOCUMENTOS_VIVIENDA',
  GALERIAS_PROMOCION: 'GALERIAS_PROMOCION',
  GALERIAS_VIVIENDA: 'GALERIAS_VIVIENDA',
  VIDEOS: 'VIDEOS',
  DESCARGAS: 'DESCARGAS'
}

const initialState = {
  menuOptions: [
    /*
    {
      id: 'id',
      name: 'name',
      submenu: '',  Menu al que se va cuando se selecciona
      expandable: true/false  Si true se expande el menu indicado por submenu
                              Si false y hay submenu se abre el submenu
    },
    */
    {
      id: MENU_OPTIONS.VISTA_GENERAL,
      name: 'General',
      submenu: '',
      expandable: false
    },
    {
      id: MENU_OPTIONS.PLANO_SITUACION,
      name: 'Mapa',
      submenu: SUBMENUS.PLANO_SITUACION,
      expandable: true
    },
    {
      id: MENU_OPTIONS.SELECCIONA_VIVIENDA,
      name: 'Vivienda',
      submenu: SUBMENUS.FILTRO,
      expandable: true
    },
    {
      id: MENU_OPTIONS.GALERIA_IMAGENES,
      name: 'Imágenes',
      submenu: SUBMENUS.GALERIAS_PROMOCION,
      expandable: true
    },
    {
      id: MENU_OPTIONS.DOCUMENTOS,
      name: 'Documentación',
      submenu: SUBMENUS.DOCUMENTOS_PROMOCION,
      expandable: true
    },
    {
      id: MENU_OPTIONS.DESCARGAS,
      name: 'Descargas',
      submenu: SUBMENUS.DESCARGAS,
      expandable: true
    }
  ],
  submenus: [
    /*
    {
      id: 'id',
      menuOption: '', Icono que se muestra arriba
      parentSubmenu: '' Submenu al que vuelve cuando se pulsa back. Si no hay,
                        se vuelve al menu principal
    },
    */
    {
      id: SUBMENUS.PLANO_SITUACION,
      title: 'Selecciona las opciones que quieras ver en el mapa',
      menuOption: MENU_OPTIONS.PLANO_SITUACION,
      parentSubmenu: ''
    },
    {
      id: SUBMENUS.FILTRO,
      title: 'Selecciona tu vivienda',
      menuOption: MENU_OPTIONS.SELECCIONA_VIVIENDA,
      parentSubmenu: ''
    },
    {
      id: SUBMENUS.DETALLES_CASA,
      title: '', //No poner nada. Depende de la casa elegida
      menuOption: MENU_OPTIONS.SELECCIONA_VIVIENDA,
      parentSubmenu: SUBMENUS.FILTRO
    },
    {
      id: SUBMENUS.DOCUMENTOS_VIVIENDA,
      menuOption: MENU_OPTIONS.DOCUMENTOS,
      parentSubmenu: SUBMENUS.DETALLES_CASA
    },
    {
      id: SUBMENUS.DOCUMENTOS_PROMOCION,
      title: 'Documentos disponibles',
      menuOption: MENU_OPTIONS.DOCUMENTOS,
      parentSubmenu: ''
    },
    {
      id: SUBMENUS.GALERIAS_VIVIENDA,
      menuOption: MENU_OPTIONS.GALERIA_IMAGENES,
      parentSubmenu: SUBMENUS.DETALLES_CASA
    },
    {
      id: SUBMENUS.GALERIAS_PROMOCION,
      title: 'Selecciona las imágenes que quieras ver',
      menuOption: MENU_OPTIONS.GALERIA_IMAGENES,
      parentSubmenu: ''
    },
    {
      id: SUBMENUS.DESCARGAS,
      title: 'Documentos para descargar',
      menuOption: MENU_OPTIONS.DESCARGAS,
      parentSubmenu: ''
    }
  ],
  rootSize: { width: 0, height: 0 },
  menuOpen: true,
  currentMenuOption: MENU_OPTIONS.VISTA_GENERAL,
  currentMenuOptionExpanded: false,
  currentSubmenu: '',
  dragEnabled: true,
  houseDetails3dMode: true
}

const changeState = (state = initialState, action) => {
  console.log(action)
  switch (action.type) {
    case SET_ROOT_SIZE:
      return {
        ...state,
        rootSize: action.size
        // menuOpen: action.size.width > SMALL_WIDTH ? true : state.menuOpen
      }
    case CONTACT_FORM_SET_OPEN:
      if (action.open) {
        return {
          ...state,
          menuOpen: false
        }
      }
      return state
    case SET_MENU_OPEN:
      return {
        ...state,
        menuOpen: action.open
      }

    case SET_CURRENT_MENU_OPTION: {
      const option = state.menuOptions.find((mo) => mo.id === action.option)
      return {
        ...state,
        currentMenuOption: option ? action.option : state.currentMenuOption,
        currentSubmenu: option && !option.expandable ? option.submenu : ''
      }
    }

    case SET_CURRENT_SUBMENU: {
      const submenu = state.submenus.find((sm) => sm.id === action.submenu)
      if (!submenu) {
        return state
      }
      return {
        ...state,
        currentMenuOption: submenu.menuOption,
        currentSubmenu: action.submenu
      }
    }

    case OPTION_CLICKED:
      const option = state.menuOptions.find((mo) => mo.id === action.option)
      if (!option) {
        return state
      }

      return {
        ...state,
        currentMenuOption: action.option,
        currentSubmenu:
          state.currentMenuOption !== action.option
            ? option.expandable
              ? option.submenu
              : ''
            : state.currentSubmenu,
        currentMenuOptionExpanded:
          state.currentMenuOption === action.option
            ? !state.currentMenuOptionExpanded && option.expandable
            : option.expandable
      }

    case HOME_CLICKED:
      return {
        ...state,
        currentMenuOption: MENU_OPTIONS.VISTA_GENERAL,
        currentSubmenu: ''
      }

    case BACK_CLICKED: {
      if (state.currentSubmenu !== '') {
        const submenu = state.submenus.find(
          (sm) => sm.id === state.currentSubmenu
        )
        if (submenu) {
          if (submenu.parentSubmenu !== '') {
            const parentSubmenu = state.submenus.find(
              (sm) => sm.id === submenu.parentSubmenu
            )
            return {
              ...state,
              currentMenuOption: parentSubmenu.menuOption,
              currentSubmenu: submenu.parentSubmenu
            }
          }
          return {
            ...state,
            currentMenuOption: MENU_OPTIONS.VISTA_GENERAL,
            currentSubmenu: ''
          }
        }
      }
      return { ...state, currentMenuOption: '', submenu: '' }
    }
    case SET_MENU_OPTION_EXPANDED:
      return {
        ...state,
        currentMenuOptionExpanded: action.id
      }
    case SET_DRAG_ENABLED:
      return { ...state, dragEnabled: action.enabled }
    case SET_VIEW_3D_MODE:
      return { ...state, houseDetails3dMode: action.on }
    default:
      return state
  }
}
export default changeState

export const setRootSize = (size) => ({
  type: SET_ROOT_SIZE,
  size
})

export const setMenuOpen = (open) => (dispatch, getState) => {
  // const state = getState()
  if (open) {
    dispatch(setContactFormOpen(false))
  }
  dispatch({
    type: SET_MENU_OPEN,
    open
  })
}

export const setCurrentMenuOption = (option) => ({
  type: SET_CURRENT_MENU_OPTION,
  option
})

export const setCurrentSubmenu = (submenu) => ({
  type: SET_CURRENT_SUBMENU,
  submenu
})

export const optionClicked = (option) => ({
  type: OPTION_CLICKED,
  option
})

export const homeClicked = () => ({
  type: HOME_CLICKED
})

export const backClicked = () => ({
  type: BACK_CLICKED
})

export const setView3DMode = (on) => ({
  type: SET_VIEW_3D_MODE,
  on
})

export const setMenuOptionExpanded = (id) => ({
  type: SET_MENU_OPTION_EXPANDED,
  id
})

export const setDragEnabled = (enabled) => ({
  type: SET_DRAG_ENABLED,
  enabled
})

export const rootSizeSelector = (state) => state.main.rootSize
export const menuOpenSelector = (state) => state.main.menuOpen
export const menuOptionsSelector = (state) => state.main.menuOptions
export const submenusSelector = (state) => state.main.submenus
export const currentMenuOptionSelector = (state) => state.main.currentMenuOption
export const currentSubmenuSelector = (state) => state.main.currentSubmenu
export const currentMenuOptionExpandedSelector = (state) =>
  state.main.currentMenuOptionExpanded
export const dragEnabledSelector = (state) => state.main.dragEnabled
export const houseDetails3dModeSelector = (state) =>
  state.main.houseDetails3dMode

export const getMenuOption = () =>
  createSelector([menuOptionsSelector, (_, id) => id], (options, id) => {
    return options.find((o) => o.id === id)
  })

export const getCurrentSubmenu = createSelector(
  [currentSubmenuSelector, submenusSelector],
  (currentSubmenu, submenus) => {
    return findById(submenus, currentSubmenu)
  }
)

export const getMenuOptionExpanded = createSelector(
  [currentMenuOptionExpandedSelector, currentMenuOptionSelector],
  (currentMenuOptionExpanded, currentMenuOption) => (optionId) => {
    return optionId === currentMenuOption && currentMenuOptionExpanded
  }
)

export const getMobileMode = createSelector(
  [rootSizeSelector],
  (rootSize) => rootSize.width < SMALL_WIDTH
)
