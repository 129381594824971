import { group_g0_segments } from './group_g0_segments'
const generateImages = () => {
  let images = []
  for (let i = 0; i < 24; ++i) {
    images.push(
      './rotator/group0/base/EDIFICIO_PLANTA_04.2_' +
        `${i}`.padStart(4, 0) +
        '.jpg'
    )
  }
  return images
}

const generateMaskImages = () => {
  let images = []
  for (let i = 0; i < 24; ++i) {
    images.push(
      './rotator/group0/mascara/EDIFICIO_PLANTA_04.2_' +
        `${i}`.padStart(4, 0) +
        '.png'
    )
  }
  return images
}
export const group0 = {
  id: 'g0',
  name: 'Group 0',
  imageSize: { width: 1500, height: 1125 },
  images: generateImages(),
  maskImages: generateMaskImages(),
  colors: [
    '#08ff00ff',
    '#ffa200ff',
    '#0012ffff',
    '#fffe00ff',
    '#00ffffff',
    '#01ffffff'
  ],
  segments: group_g0_segments,
  userData: [
    {
      segmentId: '#08ff00ff',
      houseName: 'Vivienda 1'
    },
    {
      segmentId: '#ffa200ff',
      houseName: 'Vivienda 2'
    },
    {
      segmentId: '#0012ffff',
      houseName: 'Vivienda 3'
    },
    {
      segmentId: '#fffe00ff',
      houseName: 'Vivienda 4'
    }
  ]
}
