import { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'

import GradientText from '../../Common/GradientText'
import HousesTooltipState from './HousesTooltipState'
import { getHouseDetails } from '../../../modules/reducerHouses'

const useStyles = makeStyles((theme) => ({
  root: {},
  img: {
    backgroundColor: theme.palette.primary.contrastText,
    boxSizing: 'border-box',
    padding: 4,
    width: '100%',
    height: 'auto',
    minHeight: 100,
    objectFit: 'contain',
    maxHeight: 80
  },
  contentWrapper: { padding: '8px 16px 8px 16px' },
  title: {
    // color: theme.palette.secondary.main,
    fontWeight: 'bold',
    paddingBottom: 16
  },
  detailWrapper: { display: 'flex', flexFlow: 'row', padding: '4px 0' },
  detailName: {
    width: '50%',
    color: theme.palette.primary.contrastText2,
    paddingRight: 4
  },
  detailValue: { width: '50%', color: theme.palette.primary.contrastText }
}))

const DetailItem = ({ name, value }) => {
  const classes = useStyles()
  return (
    <div className={classes.detailWrapper}>
      <Typography variant="h4" className={classes.detailName}>
        {name}
      </Typography>
      <Typography variant="h4" className={classes.detailValue}>
        {value}
      </Typography>
    </div>
  )
}

const HouseTooltipContent = ({ houseId }) => {
  const classes = useStyles()
  const gHouseDetails = useMemo(getHouseDetails, [])
  const houseDetails = useSelector((state) => gHouseDetails(state, houseId))
  // const classes = useStyles()

  return houseDetails ? (
    <div className={classes.root}>
      <HousesTooltipState
        id={houseDetails.house.id}
        name={houseDetails.house.stateObject.name}
      />
      <img className={classes.img} src={houseDetails.house.img} alt="House" />
      <div className={classes.contentWrapper}>
        <GradientText variant="h4" className={classes.title}>
          {houseDetails.house.name}
        </GradientText>
        {houseDetails.details.map((detail, idx) => (
          <DetailItem key={idx} name={detail[0]} value={detail[1]} />
        ))}
      </div>
    </div>
  ) : null
}
export default HouseTooltipContent
