import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import MenuIcon from '../Icons/MenuIcon'
import CloseIcon from '../Icons/CloseIcon'
import Logo from '../Logo/Logo'

import { menuOpenSelector, setMenuOpen } from '../../modules/reducerMain'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: theme.componentsData.menuMainButton.top,
    left: theme.componentsData.menuMainButton.left,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'row',
    zIndex: theme.zIndex.contactForm
  },
  menuButton: {
    width: theme.componentsData.menuMainButton.width,
    height: theme.componentsData.menuMainButton.height,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.secondary.hover
    }
  },
  icon: {
    stroke: 'currentColor',
    fill: 'currentColor',
    height: 16,
    width: 'auto'
  },
  title: { color: 'currentColor', paddingTop: 4 }
}))

const MenuMainButton = () => {
  const classes = useStyles()
  const open = useSelector(menuOpenSelector)
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    dispatch(setMenuOpen(!open))
  }, [dispatch, open])

  return (
    <div className={classes.root}>
      <div className={classes.menuButton} onClick={handleClick}>
        {open ? (
          <CloseIcon className={classes.icon} />
        ) : (
          <MenuIcon className={classes.icon} />
        )}
        <Typography variant="h5" className={classes.title}>
          Menu
        </Typography>
      </div>
      <Logo />
    </div>
  )
}
export default MenuMainButton
