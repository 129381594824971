import { useCallback, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { animated, useSpring } from 'react-spring'
import { useGesture } from 'react-use-gesture'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'

import GalleryButton from '../GalleryButton'
import GalleryExpandButton from './GalleryExpandButton'
import GalleryCenterImage from './GalleryCenterImage'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    position: 'relative',
    overflow: 'hidden',
    touchAction: 'none'
  },
  imagesWrapper: {
    display: 'flex',
    flexFlow: 'row',
    position: 'absolute',
    height: '100%'
  }
}))

const GalleryCenter = ({
  imagesSrcs,
  currentImageIdx,
  onChangeImageIdx,
  expanded,
  onChangeExpand
}) => {
  const classes = useStyles()
  const [rootBoundsRef, rootBounds] = useMeasure({ polyfill: ResizeObserver })
  const [localLeft, setLocalLeft] = useState(0)
  const [localCurrentIdx, setLocalCurrentIdx] = useState(0)
  const [fadeInitialIdx, setFadeInitialIdx] = useState(0)
  const [fade, setFade] = useState(false)

  const handleExpand = useCallback(() => {
    if (onChangeExpand) {
      onChangeExpand(!expanded)
    }
  }, [onChangeExpand, expanded])

  const handleNext = useCallback(() => {
    if (onChangeImageIdx) {
      onChangeImageIdx(currentImageIdx + 1)
    }
  }, [onChangeImageIdx, currentImageIdx])

  const handlePrev = useCallback(() => {
    if (onChangeImageIdx) {
      onChangeImageIdx(currentImageIdx - 1)
    }
  }, [onChangeImageIdx, currentImageIdx])

  const handleFadeEnd = useCallback(() => {
    setFade(false)
  }, [])

  useEffect(() => {
    if (rootBounds) {
      if (Math.abs(currentImageIdx - localCurrentIdx) > 1) {
        setFade(true)
        setFadeInitialIdx(localCurrentIdx)
      } else {
        setLocalLeft(-currentImageIdx * rootBounds.width)
      }
      setLocalCurrentIdx(currentImageIdx)
    }
  }, [currentImageIdx, rootBounds, localCurrentIdx])

  const despAnimation = useSpring({
    left: localLeft,
    immediate: fade
  })

  const bind = useGesture({
    onDrag: ({ cancel, distance, active, direction: [xDir], delta }) => {
      if (rootBounds) {
        if (active && distance > rootBounds.width / 2) {
          xDir > 0 ? handlePrev() : handleNext()
          cancel(true)
        }
        setLocalLeft(localLeft + delta[0])
      }
    },
    onDragEnd: () => {
      if (rootBounds) {
        setLocalLeft(-currentImageIdx * rootBounds.width)
      }
    }
  })

  return imagesSrcs && imagesSrcs.length > 0 ? (
    <div ref={rootBoundsRef} {...bind()} className={classes.root}>
      <animated.div style={despAnimation} className={classes.imagesWrapper}>
        {imagesSrcs.map((img, idx) => (
          <GalleryCenterImage
            key={idx}
            width={rootBounds.width}
            height={rootBounds.height}
            imagesSrcs={imagesSrcs}
            imgIdx={idx}
            currentImageIdx={currentImageIdx}
            modeFade={fade}
            fadeInitialIdx={fadeInitialIdx}
            onFadeEnd={handleFadeEnd}
          />
        ))}
      </animated.div>
      {expanded && (
        <>
          <GalleryButton
            absolute
            onClick={handlePrev}
            count={imagesSrcs.length}
            currentIdx={currentImageIdx}
          />
          <GalleryButton
            next
            absolute
            onClick={handleNext}
            count={imagesSrcs.length}
            currentIdx={currentImageIdx}
          />
        </>
      )}
      <GalleryExpandButton expanded={expanded} onClick={handleExpand} />
    </div>
  ) : null
}
export default GalleryCenter
