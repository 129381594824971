import { useCallback, useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'
import mergeRefs from 'react-merge-refs'
import { useGesture } from 'react-use-gesture'

import RotatorBar from './RotatorBar'
import RotatorProgress from './RotatorProgress'

import { vector, addVectors } from '../../utils/mathFunctions'

import {
  modeSelector,
  setMode,
  zoomAtGlobalPoint,
  MODE,
  rotate,
  translationSelector,
  setTranslation,
  scaleSelector,
  imageSizeSelector,
  ZOOM_FACTOR,
  setRotatorBounds,
  resetView,
  mouseIncSelector
} from '../../modules/reducerRotator'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    zIndex: theme.zIndex.rotator
  },
  rotator: {
    touchAction: 'none',
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    cursor: ({ mode }) => {
      switch (mode) {
        case MODE.PAN:
          return 'grab'
        case MODE.PANNING:
          return 'grabbing'
        case MODE.ROT_MOUSE:
        default:
          return ''
      }
    },

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'column'
  }
}))

const Rotator = ({ children, small }) => {
  const domTarget = useRef(null)
  const [rootRef, rootBounds] = useMeasure({ polyfill: ResizeObserver })
  const [firstImage, setFirstImage] = useState(true)
  const [deltaX, setDeltaX] = useState(0)

  const dispatch = useDispatch()
  const mode = useSelector(modeSelector)
  const scale = useSelector(scaleSelector)
  const translation = useSelector(translationSelector)
  const imageSize = useSelector(imageSizeSelector)
  const mouseInc = useSelector(mouseIncSelector)
  const classes = useStyles({ mode, translation, scale })

  useEffect(() => {
    dispatch(setMode(MODE.BUTTONS))
  }, [dispatch])

  //Cambio tamaño contenedor
  useEffect(() => {
    dispatch(setRotatorBounds(rootBounds))
    if (firstImage) {
      if (
        rootBounds.width > 0 &&
        rootBounds.height > 0 &&
        imageSize.width > 0 &&
        imageSize.height > 0
      ) {
        //Primera imagen en cargarse
        setFirstImage(false)
        dispatch(resetView())
      }
    }
  }, [dispatch, rootBounds, firstImage, imageSize])

  const setModeD = useCallback(
    (value) => {
      dispatch(setMode(value))
    },
    [dispatch]
  )

  const handleWheel = (e) => {
    //Se usa onWheel en lugar de useGesture porque en useGesture no se puede
    //leer en que punto esta el cursor cuando se activa la rueda
    if (e.deltaY) {
      dispatch(
        zoomAtGlobalPoint(
          e.deltaY > 0 ? 1 - ZOOM_FACTOR : 1 + ZOOM_FACTOR,
          vector(e.pageX, e.pageY)
        )
      )
    }
  }

  useGesture(
    {
      onDragStart: ({ pinching }) => {
        setDeltaX(0)
        if (pinching) {
          return
        }
        if (mode === MODE.BUTTONS) {
          setModeD(MODE.ROT_MOUSE)
        } else if (mode === MODE.PAN) {
          setModeD(MODE.PANNING)
        }
      },
      onDragEnd: ({ pinching }) => {
        if (pinching) {
          return
        }
        if (mode === MODE.ROT_MOUSE) {
          setModeD(MODE.BUTTONS)
        } else if (mode === MODE.PANNING) {
          setModeD(MODE.PAN)
        }
      },
      onDrag: ({ movement, dragging, first, last, delta, pinching }) => {
        if (pinching) {
          return
        }
        if (mode === MODE.PANNING) {
          let inc = { x: delta[0], y: delta[1] }
          inc.x /= scale
          inc.y /= scale
          dispatch(setTranslation(addVectors(translation, inc)))
        } else if (mode === MODE.ROT_MOUSE) {
          //Giro con el raton.
          const newDeltaX = deltaX + delta[0]
          if (newDeltaX > mouseInc) {
            setDeltaX(0)
            dispatch(rotate(1))
          } else if (newDeltaX < -mouseInc) {
            setDeltaX(0)
            dispatch(rotate(-1))
          } else {
            setDeltaX(newDeltaX)
          }
        }
      },
      onPinch: ({ delta: [d], origin }) => {
        if (mode === MODE.ROT_MOUSE) {
          setModeD(MODE.BUTTONS)
        } else if (mode === MODE.PANNING) {
          setModeD(MODE.PAN)
        }
        dispatch(
          zoomAtGlobalPoint(
            d > 0 ? 1 + ZOOM_FACTOR / 2 : 1 - ZOOM_FACTOR / 2,
            vector(origin[0], origin[1])
          )
        )
      }
      /*
      El pinch funciona tal y como esta. El problema esta con los cartelitos.
      Si mientras se esta haciendo el gesto se abre uno, deja de leer el gesto
      en el rotator y pasa a hacer el zoom a toda la aplicacion. Lo mismo si se
      hace el gesto en un cartel abierto.
        Fallo gordo:
          Cartel abierto.
          Zoom de la aplicacion hasta que se pierde la zona que abre el cartel.
          Se cierra el cartel.
          En pantalla ya solo queda el rotator.
          Ya no hay forma de achicar la aplicacion. Si se hace pinch negativo
          achica rotator.
      Hay que impedir pinch en los carteles.
      */
      // onPinch: ({ delta: [d], origin }) => {
      //   if (mode === MODE.ROT_MOUSE) {
      //     setModeD(MODE.BUTTONS)
      //   } else if (mode === MODE.PANNING) {
      //     setModeD(MODE.PAN)
      //   }
      //   dispatch(
      //     zoomAtGlobalPoint(
      //       d > 0 ? 1 + ZOOM_FACTOR / 2 : 1 - ZOOM_FACTOR / 2,
      //       vector(origin[0], origin[1])
      //     )
      //   )
      // }
    },
    { domTarget, eventOptions: { passive: false } }
  )

  return (
    <div className={classes.root}>
      <div
        ref={mergeRefs([rootRef, domTarget])}
        onWheel={handleWheel}
        className={classes.rotator}
      >
        {children}
      </div>
      <RotatorProgress />
      <RotatorBar small={small} />
    </div>
  )
}
export default Rotator
