import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import StateIcon from '../StateIcon'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center'
  },
  iconWrapper: { padding: '0 8px', display: 'flex' },
  nameWrapper: {
    display: 'block',
    maxWidth: '88px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}))

const HousesNameCell = ({ id, name }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.iconWrapper}>
        <StateIcon houseId={id} width="16px" className={classes.stateIcon} />
      </div>
      <Typography className={classes.nameWrapper} variant="h5">
        {name}
      </Typography>
    </div>
  )
}
export default HousesNameCell
