import { useCallback, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import Slider from '@material-ui/core/Slider'

import {
  getCurrentFloor,
  setFloor
} from '../../../../modules/reducerHousesFilter'
import { houseFloorsSelector } from '../../../../modules/reducerHouses'

const useStyles = makeStyles((theme) => ({
  sliderWrapper: {
    padding: '16px 0',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  sliderRoot: {
    '&$vertical': {
      margin: 0
    }
  },
  rail: { opacity: 1 },
  mark: {
    color: theme.palette.primary.contrastText,
    width: 6,
    height: 6,
    borderRadius: 10,
    transform: 'translate(-2px,50%)'
  },
  thumb: {
    width: 16,
    height: 16,
    transform: 'translate(-2px,2px)',
    backgroundImage: theme.palette.secondary.gradientHorizontal,
    '&$focused,  &:hover': {
      boxShadow: 'none'
    }
  },
  markActive: { opacity: 1 },
  vertical: {},
  focused: {}
}))

const FloorSelectSlider = () => {
  const dispatch = useDispatch()
  const houseFloors = useSelector(houseFloorsSelector)
  const currentFloor = useSelector(getCurrentFloor)
  const classes = useStyles()
  const marks = houseFloors.map((floor, idx) => {
    return { value: idx, label: '' }
  })

  useEffect(() => {
    if (houseFloors.length > 0 && !currentFloor) {
      dispatch(setFloor(houseFloors[0].id))
    }
  }, [dispatch, houseFloors, currentFloor])

  const handleChange = useCallback(
    (e, value) => {
      dispatch(setFloor(houseFloors[value].id))
    },
    [dispatch, houseFloors]
  )

  return (
    <div className={classes.sliderWrapper}>
      <Slider
        classes={{
          root: classes.sliderRoot,
          vertical: classes.vertical,
          mark: classes.mark,
          markActive: classes.markActive,
          thumb: classes.thumb,
          rail: classes.rail,
          focusVisible: classes.focused
        }}
        disabled={!currentFloor}
        color="secondary"
        orientation="vertical"
        value={
          currentFloor
            ? houseFloors.findIndex((hf) => hf.id === currentFloor.id)
            : null
        }
        min={0}
        max={houseFloors.length - 1}
        marks={marks}
        step={null}
        onChange={handleChange}
      />
    </div>
  )
}
export default FloorSelectSlider
