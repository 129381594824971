const HexagonIcon = (props) => {
  return (
    <svg viewBox="0 0 106.42 122.88" {...props}>
      <path
        fill="fill"
        d="M53.21 0l26.6 15.36 26.61 15.36v61.44l-26.61 15.36-26.6 15.36-26.61-15.36L0 92.16V30.72l26.6-15.36L53.21 0z"
      />
    </svg>
  )
}
export default HexagonIcon
