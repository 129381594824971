import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { useTransition, animated } from 'react-spring'
import Typography from '@material-ui/core/Typography'

import HexagonIcon from '../Icons/HexagonIcon'
import ContactIcon from '../Icons/ContactIcon'
import { openSelector, setOpen } from '../../modules/reducerContact'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 20,
    right: 20,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.contrastText,
    fill: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
      fill: theme.palette.secondary.hover
    },
    zIndex: theme.zIndex.contactForm
  },
  hexagon: {
    fill: 'inherit',
    width: 84
  },
  iconWrapper: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column'
  },
  mailIcon: {
    stroke: 'currentColor', //theme.palette.primary.contrastText,
    width: 24
  },
  title: { color: 'currentColor', paddingTop: 4 }
}))

const CFHexagon = () => {
  const classes = useStyles()
  const open = useSelector(openSelector)
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    dispatch(setOpen(true))
  }, [dispatch])

  const transition = useTransition(!open, {
    // onRest: () => {
    //   setAnimating(false)
    // },
    // onStart: (o) => {
    //   setAnimating(true)
    // },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  return transition((styles, item) =>
    item ? (
      <animated.div
        style={styles}
        className={classes.root}
        onClick={handleClick}
      >
        <HexagonIcon className={classes.hexagon}></HexagonIcon>
        <div className={classes.iconWrapper}>
          <ContactIcon className={classes.mailIcon} />
          <Typography variant="h5" className={classes.title}>
            Contacto
          </Typography>
        </div>
      </animated.div>
    ) : null
  )
}
export default CFHexagon
