import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'

import RemoveFiltersFooter from '../Common/RemoveFiltersFooter'
import { setSelection } from '../../modules/reducerMap'

// const useStyles = makeStyles((theme) => ({
// }))

const MapMenuFooter = () => {
  // const classes = useStyles()
  const dispatch = useDispatch()

  const handleRemoveFilters = useCallback(
    (selection) => {
      dispatch(setSelection([]))
    },
    [dispatch]
  )

  return <RemoveFiltersFooter onClick={handleRemoveFilters} />
}
export default MapMenuFooter
