import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'

import BackIcon from '../../Icons/BackIcon'
import { backClicked } from '../../../modules/reducerMain'

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.primary.contrastText,
    padding: '0 4px',
    '&:hover': { color: theme.palette.secondary.main }
  }
}))

const HouseDetailsBackButton = ({ houseId }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    console.log('back')
    dispatch(backClicked())
  }, [dispatch])

  return (
    <IconButton className={classes.button} onClick={handleClick}>
      <BackIcon height={20} stroke={'currentColor'} />
    </IconButton>
  )
}
export default HouseDetailsBackButton
