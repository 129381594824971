const DownloadAllIcon = (props) => {
  return (
    <svg fill="none" viewBox="0 0 25 27" {...props}>
      <path
        stroke="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M9.762 26.19H3.19A2.196 2.196 0 011 24V3.738"
      />
      <path
        stroke="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M21.81 1H3.19C1.986 1 1 1.986 1 3.19c0 1.205.986 2.191 2.19 2.191h18.62v4.928M17.429 26.19a6.571 6.571 0 100-13.143 6.571 6.571 0 000 13.144zm-.001-3.833v-5.476"
      />
      <path
        stroke="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M15.238 20.166l2.19 2.191 2.191-2.19M3.191 3.19h17.524"
      />
    </svg>
  )
}
export default DownloadAllIcon
