import { useCallback, useMemo, useEffect } from 'react'
// import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import RadioGroup from '@material-ui/core/RadioGroup'
import LabelRadio from '../Common/Input/LabelRadio'

import MenuPanelContent from '../Menu/MenuPanelContent'
import {
  getGallery,
  currentGallerySelector,
  setCurrentGallery
} from '../../modules/reducerGallery'

// const useStyles = makeStyles((theme) => ({}))

const GalleryItem = ({ id }) => {
  const gGallery = useMemo(getGallery, [])
  const gallery = useSelector((state) => gGallery(state, id))
  return gallery ? (
    <LabelRadio
      style={{ padding: '4px 0px' }}
      label={gallery.name}
      value={id}
    />
  ) : null
}

const GalleriesMenu = ({ galleriesIds }) => {
  // const galleries = useSelector(galleriesSelector)
  const currentGallery = useSelector(currentGallerySelector)
  const dispatch = useDispatch()
  // const classes = useStyles()

  useEffect(() => {
    if (galleriesIds.indexOf(currentGallery) < 0) {
      dispatch(setCurrentGallery(''))
    }
    if (galleriesIds && galleriesIds.length > 0 && !currentGallery) {
      dispatch(setCurrentGallery(galleriesIds[0]))
    }
  }, [dispatch, galleriesIds, currentGallery])

  const handleChange = useCallback(
    (e) => {
      dispatch(setCurrentGallery(e.target.value))
    },
    [dispatch]
  )

  return galleriesIds && galleriesIds.length ? (
    <MenuPanelContent>
      <RadioGroup value={currentGallery} onChange={handleChange}>
        {galleriesIds.map((galleryId, index) => (
          <GalleryItem key={index} id={galleryId} />
        ))}
      </RadioGroup>
    </MenuPanelContent>
  ) : null
}
export default GalleriesMenu
