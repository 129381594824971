import { group_aresII_segments } from './group_aresII_segments'
const generateImages = () => {
  let images = []
  for (let i = 0; i < 17; ++i) {
    images.push('./rotator/aresII/AresII_' + `${i}`.padStart(5, 0) + '.jpg')
  }
  return images
}

const generateMaskImages = () => {
  let images = []
  for (let i = 0; i < 17; ++i) {
    images.push(
      './rotator/aresII/Ares2_mask.VRayWireColor.' +
        `${i}`.padStart(4, 0) +
        '.png'
    )
  }
  return images
}
export const aresII = {
  id: 'aresII',
  name: 'Ares II',
  imageSize: { width: 1920, height: 1080 },
  images: generateImages(),
  maskImages: generateMaskImages(),
  colors: [
    '#c0ed3aff',
    '#e4efcbff',
    '#9fc24cff',
    '#9fbbd7ff',
    '#2af6ccff',
    '#84d426ff'
  ],
  segments: group_aresII_segments,
  userData: [
    {
      segmentId: '#c0ed3aff',
      houseName: 'Vivienda 1'
    },
    {
      segmentId: '#e4efcbff',
      houseName: 'Vivienda 2'
    },
    {
      segmentId: '#9fc24cff',
      houseName: 'Vivienda 3'
    },
    {
      segmentId: '#9fbbd7ff',
      houseName: 'Vivienda 4'
    },
    {
      segmentId: '#2af6ccff',
      houseName: 'Vivienda 5'
    },
    {
      segmentId: '#84d426ff',
      houseName: 'Vivienda 6'
    }
  ],
  rotatorSpeed: 800,
  mouseInc: 40
}
