import { createSelector } from 'reselect'
import { getFitChildScale, getFitWidthChildScale } from '../utils/centeredChild'
import { rangeValue } from '../utils/mathFunctions'
export const SCALE_RANGE = { min: 0.1, max: 5 }
const SCROLL_BAR_WIDTH = 17 //17

export const PDF_SET_URL = 'PDF_SET_URL'
export const PDF_SET_SCALE = 'PDF_SET_SCALE'
export const PDF_SET_ROTATION = 'PDF_SET_ROTATION'
export const PDF_SET_PAGE_IDX = 'PDF_SET_PAGE_IDX'
export const PDF_SET_CONTAINER_SIZE = 'PDF_SET_MASTER_SIZE'
export const PDF_SET_ZOOM_MODE = 'PDF_SET_ZOOM_MODE'
export const PDF_SET_PAGE_SIZE = 'PDF_SET_PAGE_SIZE'
export const PDF_SET_PAGE_COUNT = 'PDF_SET_PAGE_COUNT'

export const ZOOM_MODE = {
  FIT_PAGE: 'FIT_PAGE',
  FIT_WIDTH: 'FIT_WIDTH',
  SCALE: 'SCALE'
}

export const ZOOM_FACTOR = 0.1

const initialState = {
  url: '',
  pageSize: { width: 0, height: 0 },
  pageIdx: -1,
  pageCount: 0,
  scale: 1,
  rotation: 0,
  containerSize: { width: 0, height: 0 },
  zoomMode: ZOOM_MODE.FIT_WIDTH
}

export const getIsPortrait = (rotation) => rotation === 0 || rotation === 180

export const getNewScale = (
  zoomMode,
  pageSize,
  containerSize,
  currentScale,
  rotation
) => {
  let newScale = currentScale
  if (zoomMode === ZOOM_MODE.FIT_PAGE) {
    newScale = getFitChildScale(
      pageSize,
      containerSize,
      getIsPortrait(rotation)
    )
  } else if (zoomMode === ZOOM_MODE.FIT_WIDTH) {
    newScale = getFitWidthChildScale(
      pageSize,
      containerSize,
      getIsPortrait(rotation),
      SCROLL_BAR_WIDTH
    )
  }
  return rangeValue(newScale, SCALE_RANGE.min, SCALE_RANGE.max)
}

const changeState = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case PDF_SET_URL:
      if (action.url === state.url) {
        return state
      }
      return {
        ...state,
        url: action.url,
        pageIdx: -1,
        pageCount: 0,
        pageSize: { width: 0, height: 0 }
      }
    case PDF_SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.size,
        scale: getNewScale(
          state.zoomMode,
          action.size,
          state.containerSize,
          state.scale,
          state.rotation
        )
      }
    case PDF_SET_PAGE_IDX:
      if (action.pageIdx === state.pageIdx) {
        return state
      }

      return {
        ...state,
        pageIdx: Math.min(Math.max(0, action.pageIdx), state.pageCount - 1),
        scale: getNewScale(
          state.zoomMode,
          state.pageSize,
          state.containerSize,
          state.scale,
          state.rotation
        )
      }
    case PDF_SET_CONTAINER_SIZE:
      if (action.size === state.containerSize) {
        return state
      }
      return {
        ...state,
        scale: getNewScale(
          state.zoomMode,
          state.pageSize,
          action.size,
          state.scale,
          state.rotation
        ),
        containerSize: action.size
      }

    case PDF_SET_SCALE:
      if (action.scale === state.scale) {
        return state
      }
      return {
        ...state,
        zoomMode: ZOOM_MODE.SCALE,
        scale: rangeValue(action.scale, SCALE_RANGE.min, SCALE_RANGE.max)
      }

    case PDF_SET_ROTATION:
      let rotation = (action.rotation + 360) % 360
      return {
        ...state,
        rotation: rotation,
        scale: getNewScale(
          state.zoomMode,
          state.pageSize,
          state.containerSize,
          state.scale,
          rotation
        )
      }
    case PDF_SET_ZOOM_MODE:
      return {
        ...state,
        zoomMode: action.mode,
        scale: getNewScale(
          action.mode,
          state.pageSize,
          state.containerSize,
          state.scale,
          state.rotation
        )
      }
    case PDF_SET_PAGE_COUNT:
      return {
        ...state,
        pageCount: action.pageCount,
        pageIdx: action.pageCount > 0 ? 0 : -1
      }
    default:
      return state
  }
}
export default changeState

export const setUrl = (value) => ({
  type: PDF_SET_URL,
  url: value
})

export const setContainerSize = (size) => ({
  type: PDF_SET_CONTAINER_SIZE,
  size
})

export const setPageSize = (size) => ({
  type: PDF_SET_PAGE_SIZE,
  size
})

export const setScale = (value) => ({
  type: PDF_SET_SCALE,
  scale: value
})

export const setRotation = (rotation) => ({
  type: PDF_SET_ROTATION,
  rotation
})

export const setZoomMode = (mode) => ({
  type: PDF_SET_ZOOM_MODE,
  mode
})

export const setPageCount = (pageCount) => ({
  type: PDF_SET_PAGE_COUNT,
  pageCount
})

export const setPageIdx = (pageIdx) => ({
  type: PDF_SET_PAGE_IDX,
  pageIdx
})

export const urlSelector = (state) => state.pdfViewer.url
export const pageSizeSelector = (state) => state.pdfViewer.pageSize
export const currentPageIdxSelector = (state) => state.pdfViewer.pageIdx
export const scaleSelector = (state) => state.pdfViewer.scale
export const rotationSelector = (state) => state.pdfViewer.rotation
export const containerSizeSelector = (state) => state.pdfViewer.containerSize
export const zoomModeSelector = (state) => state.pdfViewer.zoomMode
export const pageCountSelector = (state) => state.pdfViewer.pageCount

export const isPortrait = createSelector([rotationSelector], (rotation) =>
  getIsPortrait(rotation)
)
