import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import EditorTextField from '../Common/Input/EditorTextField'
import {
  nameSelector,
  setName,
  getNameError,
  submitClickedSelector
} from '../../modules/reducerContact'

const useStyles = makeStyles((theme) => ({
  textField: { width: '100%' }
}))

const CFNameInput = (props) => {
  const classes = useStyles()
  const name = useSelector(nameSelector)
  const error = useSelector(getNameError)
  const submitClicked = useSelector(submitClickedSelector)
  const dispatch = useDispatch()

  const handleNameChange = useCallback(
    (value) => {
      dispatch(setName(value))
    },
    [dispatch]
  )

  return (
    <EditorTextField
      className={classes.textField}
      label="Nombre"
      value={name}
      required
      error={submitClicked && error !== ''}
      onChange={handleNameChange}
      helperText={submitClicked ? error : ''}
      {...props}
    />
  )
}
export default CFNameInput
