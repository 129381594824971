import { makeStyles } from '@material-ui/core/styles'

import PDFViewer from '../PDFViewer/PDFViewer'

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    paddingTop: 160
  }
}))

const DocumentViewer = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <PDFViewer />
    </div>
  )
}

export default DocumentViewer
