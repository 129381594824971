import { useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDrag } from 'react-use-gesture'

const useStyles = makeStyles((theme) => ({
  img: {
    touchAction: 'none',
    boxSizing: 'border-box',
    minWidth: ({ width }) => width,
    width: ({ width }) => width,
    minHeight: ({ height }) => height,
    height: ({ height }) => height,
    objectFit: 'cover',
    border: ({ selected }) =>
      selected
        ? `4px solid ${theme.palette.secondary.main}`
        : `2px solid ${theme.palette.primary.contrastText}`,

    backgroundColor: theme.palette.primary.main
  },
  fakeImage: {
    width: ({ width }) => width,
    height: ({ height }) => height,
    flex: '1 0 auto'
  }
}))

const GalleryBarThumbnail = ({
  imgIdx,
  imgSrc,
  width,
  height,
  currentImageIdx,
  visible,
  onChangeImageIdx
}) => {
  const imgRef = useRef()
  const [loaded, setLoaded] = useState(false)
  const classes = useStyles({
    selected: imgIdx === currentImageIdx,
    width,
    height
  })

  const handleLoad = () => {
    setLoaded(true)
  }

  const bind = useDrag(
    ({ tap }) => {
      if (tap && onChangeImageIdx) {
        onChangeImageIdx(imgIdx)
      }
    },
    { filterTaps: true }
  )

  return visible || loaded ? (
    <img
      ref={imgRef}
      {...bind()}
      className={classes.img}
      src={imgSrc}
      alt={''}
      draggable="false"
      onLoad={handleLoad}
    />
  ) : (
    <div className={classes.fakeImage} />
  )
}
export default GalleryBarThumbnail
