const ImagesIcon = (props) => {
  return (
    <svg fill="none" viewBox="0 0 33 33" {...props}>
      <path
        stroke="stroke"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M32 7.74V32H7.74"
      />
      <path
        stroke="stroke"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M29.304 3.696v25.608H3.696"
      />
      <path
        stroke="stroke"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M26.609 1H1v25.609h25.609V1z"
      />
      <path
        stroke="stroke"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M23.24 21.217l-4.044-10.782-5.392 8.087-3.37-4.044-4.043 6.74M8.413 9.087a2.022 2.022 0 100-4.043 2.022 2.022 0 000 4.043zm18.196 12.13H1"
      />
    </svg>
  )
}
export default ImagesIcon
