import { makeStyles } from '@material-ui/core/styles'

import FilterOptionTitle from './FilterOptionTitle'
import FilterRoomsOption from './FilterRoomsOption'
import { ROOM_OPTIONS } from '../../../modules/reducerHousesFilter'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'start',
    alignItems: 'start',

    width: '100%'
  },
  title: {
    color: theme.palette.primary.contrastText,
    padding: '16px 0px 12px 0px'
  },
  optionsWrapper: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    marginLeft: -4
  }
}))

const FilterRooms = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <FilterOptionTitle title="Habitaciones" />
      <div className={classes.optionsWrapper}>
        {ROOM_OPTIONS.map((ro) => (
          <FilterRoomsOption key={ro.id} id={ro.id} name={ro.name} />
        ))}
      </div>
    </div>
  )
}
export default FilterRooms
