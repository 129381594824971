import { makeStyles } from '@material-ui/core/styles'
import { useTransition, animated } from 'react-spring'

const useStyles = makeStyles((theme) => ({
  image: {
    boxSizing: 'border-box',
    width: ({ width }) => width,
    height: ({ height }) => height,
    objectFit: 'contain',
    display: 'block'
  },
  fakeImage: {
    width: ({ width }) => width,
    height: ({ height }) => height
  },
  fadeContainer: { width: ({ width }) => width, height: ({ height }) => height }
}))

const GalleryCenterImage = ({
  width,
  height,
  imgIdx,
  currentImageIdx,
  imagesSrcs,
  modeFade,
  fadeInitialIdx,
  onFadeEnd
}) => {
  const loadImage = Math.abs(currentImageIdx - imgIdx) <= 1
  const classes = useStyles({ loadImage, width, height })

  const imageTransition = useTransition(modeFade, {
    from: {
      opacity: 0,
      position: 'absolute'
    },
    enter: {
      opacity: 1
    },
    leave: {
      opacity: 0
    },
    // config: { duration: 1000 },
    onRest: () => onFadeEnd()
  })

  return loadImage ? (
    currentImageIdx === imgIdx && modeFade ? (
      <div className={classes.fadeContainer}>
        {imageTransition((styles, item) =>
          item ? (
            <animated.img
              className={classes.image}
              style={styles}
              draggable="false"
              src={imagesSrcs[imgIdx]}
            />
          ) : (
            <animated.img
              className={classes.image}
              style={styles}
              draggable="false"
              src={imagesSrcs[fadeInitialIdx]}
            />
          )
        )}
      </div>
    ) : (
      <img
        className={classes.image}
        draggable="false"
        src={imagesSrcs[imgIdx]}
        alt={''}
      />
    )
  ) : (
    <div className={classes.fakeImage} />
  )
}
export default GalleryCenterImage
