export const group_aresII_segments =[
 [
  {
   "color": "#c0ed3aff",
   "pois": [{"id": "centroid", "point": [924, 767]}],
   "box": [884, 664, 963, 865],
   "polygons": [
    [
     [911, 665],
     [908, 681],
     [889, 682],
     [888, 686],
     [884, 864],
     [950, 865],
     [952, 858],
     [957, 865],
     [957, 861],
     [961, 861],
     [963, 857],
     [961, 665],
     [919, 664]
    ]
   ]
  },
  {
   "color": "#e4efcbff",
   "pois": [{"id": "centroid", "point": [842, 766]}],
   "box": [802, 664, 884, 866],
   "polygons": [
    [
     [818, 665],
     [813, 666],
     [802, 754],
     [807, 833],
     [803, 865],
     [853, 865],
     [852, 858],
     [847, 857],
     [853, 833],
     [856, 866],
     [880, 865],
     [881, 816],
     [854, 817],
     [853, 830],
     [851, 779],
     [854, 754],
     [855, 805],
     [882, 804],
     [884, 681],
     [866, 680],
     [865, 665],
     [818, 664]
    ]
   ]
  },
  {
   "color": "#9fc24cff",
   "pois": [{"id": "centroid", "point": [768, 756]}],
   "box": [724, 664, 813, 867],
   "polygons": [
    [
     [770, 665],
     [760, 666],
     [757, 681],
     [737, 682],
     [736, 686],
     [728, 730],
     [729, 817],
     [724, 867],
     [748, 865],
     [745, 862],
     [746, 854],
     [742, 853],
     [743, 847],
     [739, 845],
     [740, 834],
     [736, 829],
     [746, 830],
     [747, 823],
     [754, 823],
     [761, 813],
     [764, 818],
     [777, 811],
     [783, 818],
     [782, 825],
     [787, 827],
     [786, 830],
     [793, 826],
     [794, 832],
     [786, 837],
     [791, 835],
     [794, 838],
     [789, 844],
     [791, 849],
     [780, 854],
     [782, 862],
     [776, 865],
     [800, 865],
     [804, 834],
     [801, 760],
     [813, 665],
     [771, 664]
    ],
    [[752, 864], [749, 866], [763, 865], [752, 863]]
   ]
  },
  {
   "color": "#9fbbd7ff",
   "pois": [{"id": "centroid", "point": [683, 773]}],
   "box": [636, 682, 733, 880],
   "polygons": [
    [[694, 807], [694, 820], [713, 819], [712, 807]],
    [
     [658, 683],
     [639, 757],
     [647, 833],
     [641, 867],
     [658, 866],
     [656, 843],
     [654, 858],
     [647, 857],
     [657, 833],
     [662, 867],
     [718, 866],
     [724, 833],
     [721, 790],
     [711, 789],
     [710, 785],
     [694, 786],
     [694, 790],
     [692, 789],
     [691, 773],
     [692, 763],
     [721, 762],
     [723, 759],
     [733, 704],
     [731, 682]
    ],
    [[638, 877], [636, 878], [658, 877]],
    [[664, 877], [662, 878], [706, 880], [715, 879], [714, 877]]
   ]
  },
  {
   "color": "#2af6ccff",
   "pois": [{"id": "centroid", "point": [608, 761]}],
   "box": [564, 682, 656, 878],
   "polygons": [
    [
     [584, 683],
     [564, 745],
     [565, 760],
     [566, 763],
     [590, 764],
     [592, 778],
     [591, 785],
     [578, 786],
     [577, 790],
     [567, 790],
     [570, 822],
     [564, 850],
     [579, 848],
     [577, 854],
     [582, 853],
     [589, 857],
     [590, 849],
     [583, 847],
     [584, 842],
     [580, 837],
     [582, 832],
     [590, 839],
     [594, 839],
     [596, 834],
     [592, 829],
     [593, 823],
     [598, 825],
     [604, 819],
     [609, 823],
     [614, 820],
     [624, 830],
     [623, 836],
     [635, 831],
     [643, 837],
     [638, 759],
     [656, 691],
     [656, 682]
    ],
    [[641, 841], [641, 840]],
    [
     [627, 850],
     [619, 853],
     [620, 855],
     [625, 853],
     [629, 857],
     [627, 861],
     [636, 866],
     [639, 853],
     [630, 849]
    ],
    [[609, 858], [587, 863], [596, 867], [614, 866], [610, 863], [610, 857]],
    [[596, 877], [594, 878], [634, 877]]
   ]
  },
  {
   "color": "#84d426ff",
   "pois": [{"id": "centroid", "point": [393, 827]}],
   "box": [298, 764, 552, 892],
   "polygons": [
    [
     [324, 765],
     [298, 813],
     [312, 892],
     [375, 891],
     [367, 842],
     [361, 848],
     [360, 857],
     [353, 857],
     [368, 833],
     [378, 892],
     [416, 891],
     [411, 890],
     [414, 884],
     [411, 883],
     [411, 874],
     [419, 867],
     [419, 862],
     [424, 863],
     [427, 859],
     [430, 861],
     [433, 855],
     [439, 862],
     [448, 860],
     [450, 853],
     [455, 856],
     [455, 860],
     [458, 857],
     [461, 859],
     [459, 861],
     [463, 867],
     [544, 866],
     [539, 863],
     [545, 859],
     [541, 852],
     [552, 849],
     [551, 844],
     [475, 844],
     [467, 783],
     [449, 782],
     [455, 768],
     [454, 764],
     [325, 764]
    ],
    [[443, 865], [447, 867], [445, 864]],
    [[462, 872], [459, 877], [466, 876], [463, 871]]
   ]
  }
 ],
 [
  {
   "color": "#c0ed3aff",
   "pois": [{"id": "centroid", "point": [678, 722]}],
   "box": [604, 633, 771, 822],
   "polygons": [
    [
     [726, 634],
     [712, 647],
     [693, 644],
     [645, 687],
     [648, 723],
     [647, 728],
     [633, 741],
     [635, 778],
     [608, 802],
     [604, 809],
     [615, 812],
     [627, 811],
     [627, 815],
     [630, 810],
     [635, 817],
     [656, 822],
     [689, 792],
     [686, 785],
     [689, 783],
     [688, 780],
     [691, 781],
     [688, 721],
     [771, 643],
     [731, 633]
    ]
   ]
  },
  {
   "color": "#e4efcbff",
   "pois": [{"id": "centroid", "point": [610, 706]}],
   "box": [539, 616, 692, 806],
   "polygons": [
    [
     [646, 617],
     [557, 690],
     [558, 723],
     [564, 763],
     [563, 769],
     [539, 789],
     [540, 793],
     [591, 806],
     [633, 767],
     [632, 764],
     [612, 759],
     [609, 760],
     [607, 772],
     [602, 750],
     [601, 716],
     [618, 701],
     [621, 702],
     [621, 722],
     [607, 734],
     [609, 748],
     [629, 753],
     [633, 751],
     [632, 741],
     [647, 727],
     [645, 686],
     [692, 645],
     [690, 624],
     [651, 616]
    ]
   ]
  },
  {
   "color": "#9fc24cff",
   "pois": [{"id": "centroid", "point": [556, 680]}],
   "box": [474, 606, 646, 789],
   "polygons": [
    [
     [603, 607],
     [588, 620],
     [568, 618],
     [518, 658],
     [520, 673],
     [517, 702],
     [506, 711],
     [509, 736],
     [511, 734],
     [513, 740],
     [528, 737],
     [529, 744],
     [524, 750],
     [530, 751],
     [531, 756],
     [539, 754],
     [539, 759],
     [533, 760],
     [536, 762],
     [539, 778],
     [561, 758],
     [556, 691],
     [646, 615],
     [603, 606]
    ],
    [[489, 763], [474, 776], [502, 782], [487, 775], [491, 767], [490, 762]],
    [[533, 765], [532, 768], [534, 765]],
    [
     [533, 773],
     [528, 774],
     [525, 779],
     [522, 778],
     [520, 785],
     [515, 787],
     [524, 789],
     [531, 785],
     [536, 780],
     [534, 772]
    ]
   ]
  },
  {
   "color": "#9fbbd7ff",
   "pois": [{"id": "centroid", "point": [477, 685]}],
   "box": [398, 603, 566, 785],
   "polygons": [
    [[478, 721], [475, 723], [477, 731], [494, 733], [494, 722], [484, 720]],
    [
     [507, 604],
     [428, 663],
     [437, 739],
     [412, 758],
     [411, 762],
     [458, 774],
     [463, 773],
     [489, 751],
     [488, 744],
     [493, 746],
     [496, 741],
     [498, 743],
     [499, 739],
     [506, 737],
     [502, 708],
     [483, 699],
     [480, 704],
     [478, 678],
     [498, 683],
     [505, 681],
     [518, 670],
     [518, 657],
     [566, 619],
     [564, 615],
     [507, 603]
    ],
    [[400, 768], [398, 769], [401, 771], [459, 785], [450, 780], [401, 768]]
   ]
  },
  {
   "color": "#2af6ccff",
   "pois": [{"id": "centroid", "point": [424, 659]}],
   "box": [343, 591, 506, 768],
   "polygons": [
    [
     [450, 592],
     [380, 642],
     [382, 656],
     [391, 658],
     [394, 677],
     [392, 683],
     [382, 683],
     [387, 713],
     [390, 711],
     [394, 716],
     [407, 716],
     [410, 722],
     [401, 728],
     [404, 731],
     [413, 727],
     [420, 738],
     [418, 741],
     [435, 729],
     [428, 662],
     [506, 604],
     [450, 591]
    ],
    [
     [367, 732],
     [349, 746],
     [379, 754],
     [385, 749],
     [384, 746],
     [371, 744],
     [374, 737],
     [361, 747],
     [356, 745],
     [358, 741],
     [372, 734],
     [367, 731]
    ],
    [[406, 743], [399, 745], [402, 748], [400, 753], [412, 748], [408, 742]],
    [[389, 744], [388, 748], [394, 746], [390, 744]],
    [[343, 756], [388, 766], [343, 755]],
    [[392, 766], [390, 767], [398, 768], [393, 766]]
   ]
  },
  {
   "color": "#84d426ff",
   "pois": [{"id": "centroid", "point": [235, 679]}],
   "box": [150, 616, 360, 749],
   "polygons": [
    [
     [204, 617],
     [150, 650],
     [157, 685],
     [174, 679],
     [175, 676],
     [177, 679],
     [186, 727],
     [201, 731],
     [199, 727],
     [202, 724],
     [198, 719],
     [200, 717],
     [206, 717],
     [215, 709],
     [222, 713],
     [224, 708],
     [229, 709],
     [227, 712],
     [231, 714],
     [237, 708],
     [244, 713],
     [243, 717],
     [247, 718],
     [248, 723],
     [250, 721],
     [253, 724],
     [247, 729],
     [249, 733],
     [254, 734],
     [255, 740],
     [251, 741],
     [255, 745],
     [271, 749],
     [274, 748],
     [273, 727],
     [299, 734],
     [303, 731],
     [305, 723],
     [314, 725],
     [319, 722],
     [325, 724],
     [323, 728],
     [335, 728],
     [331, 732],
     [335, 737],
     [331, 741],
     [339, 742],
     [360, 726],
     [309, 711],
     [307, 678],
     [300, 683],
     [297, 682],
     [292, 654],
     [281, 651],
     [296, 641],
     [297, 637],
     [204, 616]
    ],
    [[171, 682], [173, 681]],
    [[167, 685], [157, 688], [164, 722], [181, 726], [183, 725], [174, 684]]
   ]
  }
 ],
 [
  {
   "color": "#c0ed3aff",
   "pois": [{"id": "centroid", "point": [518, 640]}],
   "box": [419, 579, 632, 736],
   "polygons": [
    [
     [602, 580],
     [582, 588],
     [569, 583],
     [556, 586],
     [490, 613],
     [490, 627],
     [469, 636],
     [473, 695],
     [423, 715],
     [419, 717],
     [420, 719],
     [429, 725],
     [436, 722],
     [439, 732],
     [445, 736],
     [472, 725],
     [471, 721],
     [476, 716],
     [472, 710],
     [475, 703],
     [482, 703],
     [487, 696],
     [498, 698],
     [494, 649],
     [632, 594],
     [603, 579]
    ]
   ]
  },
  {
   "color": "#e4efcbff",
   "pois": [{"id": "centroid", "point": [472, 620]}],
   "box": [380, 551, 579, 710],
   "polygons": [
    [
     [551, 552],
     [418, 600],
     [415, 603],
     [424, 665],
     [423, 674],
     [380, 692],
     [407, 710],
     [467, 685],
     [471, 686],
     [473, 694],
     [472, 681],
     [458, 675],
     [459, 682],
     [455, 684],
     [451, 664],
     [448, 662],
     [446, 633],
     [472, 622],
     [476, 623],
     [478, 631],
     [489, 627],
     [490, 611],
     [565, 582],
     [579, 588],
     [579, 565],
     [552, 551]
    ],
    [[467, 646], [455, 651], [457, 664], [468, 670], [471, 669], [468, 645]]
   ]
  },
  {
   "color": "#9fc24cff",
   "pois": [{"id": "centroid", "point": [447, 589]}],
   "box": [346, 537, 551, 681],
   "polygons": [
    [
     [522, 538],
     [503, 545],
     [487, 540],
     [414, 567],
     [414, 580],
     [392, 589],
     [399, 641],
     [397, 647],
     [388, 651],
     [388, 655],
     [397, 657],
     [387, 669],
     [389, 675],
     [382, 674],
     [377, 681],
     [422, 663],
     [415, 601],
     [551, 550],
     [526, 537]
    ],
    [[346, 668], [357, 676], [356, 670], [346, 667]]
   ]
  },
  {
   "color": "#9fbbd7ff",
   "pois": [{"id": "centroid", "point": [387, 581]}],
   "box": [298, 520, 487, 670],
   "polygons": [
    [[379, 618], [374, 620], [376, 628], [384, 633], [390, 630], [388, 622], [380, 617]],
    [
     [448, 521],
     [341, 558],
     [350, 628],
     [308, 644],
     [332, 661],
     [354, 653],
     [350, 644],
     [360, 642],
     [364, 634],
     [376, 634],
     [378, 640],
     [393, 638],
     [397, 646],
     [399, 645],
     [394, 611],
     [386, 601],
     [381, 602],
     [379, 579],
     [391, 587],
     [402, 584],
     [414, 579],
     [415, 566],
     [487, 540],
     [452, 520]
    ],
    [[298, 648], [326, 665], [298, 647]],
    [[328, 669], [332, 670], [328, 668]]
   ]
  },
  {
   "color": "#2af6ccff",
   "pois": [{"id": "centroid", "point": [362, 550]}],
   "box": [266, 501, 449, 646],
   "polygons": [
    [
     [414, 502],
     [322, 533],
     [324, 542],
     [320, 546],
     [328, 598],
     [325, 604],
     [328, 608],
     [323, 611],
     [315, 610],
     [316, 615],
     [318, 612],
     [324, 615],
     [322, 620],
     [328, 620],
     [332, 625],
     [348, 619],
     [340, 561],
     [342, 556],
     [449, 519],
     [417, 501]
    ],
    [[290, 616], [274, 622], [283, 628], [302, 628], [302, 625], [288, 623], [291, 615]],
    [[266, 627], [298, 646], [266, 626]],
    [[315, 626], [314, 632], [319, 629], [316, 626]],
    [[296, 634], [293, 635], [296, 636], [296, 633]]
   ]
  },
  {
   "color": "#84d426ff",
   "pois": [{"id": "centroid", "point": [225, 548]}],
   "box": [160, 487, 299, 614],
   "polygons": [
    [
     [223, 488],
     [160, 508],
     [173, 570],
     [181, 566],
     [185, 567],
     [184, 571],
     [187, 568],
     [192, 572],
     [190, 577],
     [198, 579],
     [197, 582],
     [201, 583],
     [197, 587],
     [199, 591],
     [215, 602],
     [218, 601],
     [217, 591],
     [221, 594],
     [222, 591],
     [231, 591],
     [233, 594],
     [239, 594],
     [238, 596],
     [249, 605],
     [255, 607],
     [260, 604],
     [263, 613],
     [269, 614],
     [294, 604],
     [296, 600],
     [299, 601],
     [296, 594],
     [283, 586],
     [282, 556],
     [263, 560],
     [257, 530],
     [252, 528],
     [275, 519],
     [223, 487]
    ]
   ]
  }
 ],
 [
  {
   "color": "#c0ed3aff",
   "pois": [{"id": "centroid", "point": [454, 548]}],
   "box": [338, 514, 554, 632],
   "polygons": [
    [
     [504, 515],
     [427, 528],
     [424, 529],
     [424, 539],
     [389, 546],
     [397, 601],
     [386, 606],
     [397, 610],
     [393, 622],
     [403, 621],
     [397, 561],
     [554, 533],
     [543, 515],
     [516, 520],
     [510, 514]
    ],
    [[361, 610], [338, 614], [345, 632], [363, 628], [359, 624], [360, 618], [364, 617], [362, 609]]
   ]
  },
  {
   "color": "#e4efcbff",
   "pois": [{"id": "centroid", "point": [431, 528]}],
   "box": [328, 483, 535, 603],
   "polygons": [
    [
     [523, 484],
     [373, 509],
     [383, 575],
     [328, 586],
     [334, 603],
     [395, 592],
     [388, 540],
     [417, 535],
     [423, 539],
     [424, 527],
     [493, 515],
     [513, 514],
     [516, 520],
     [535, 516],
     [534, 499],
     [524, 483]
    ]
   ]
  },
  {
   "color": "#9fc24cff",
   "pois": [{"id": "centroid", "point": [432, 494]}],
   "box": [322, 465, 524, 576],
   "polygons": [
    [
     [475, 466],
     [405, 476],
     [400, 478],
     [398, 488],
     [367, 493],
     [375, 549],
     [356, 553],
     [364, 556],
     [361, 560],
     [358, 558],
     [353, 562],
     [356, 565],
     [353, 567],
     [357, 570],
     [380, 566],
     [374, 507],
     [524, 481],
     [515, 466],
     [487, 471],
     [483, 465]
    ],
    [[322, 569], [325, 576], [336, 573], [324, 572], [322, 568]]
   ]
  },
  {
   "color": "#9fbbd7ff",
   "pois": [{"id": "centroid", "point": [396, 478]}],
   "box": [309, 441, 480, 550],
   "polygons": [
    [
     [465, 442],
     [351, 460],
     [361, 524],
     [309, 534],
     [315, 550],
     [327, 548],
     [322, 543],
     [324, 541],
     [319, 540],
     [320, 537],
     [327, 538],
     [334, 531],
     [345, 532],
     [347, 537],
     [355, 538],
     [354, 544],
     [373, 540],
     [366, 494],
     [375, 490],
     [375, 483],
     [378, 490],
     [384, 490],
     [401, 486],
     [400, 477],
     [479, 464],
     [480, 460],
     [469, 441]
    ]
   ]
  },
  {
   "color": "#2af6ccff",
   "pois": [{"id": "centroid", "point": [395, 450]}],
   "box": [301, 420, 467, 525],
   "polygons": [
    [
     [451, 421],
     [359, 435],
     [358, 444],
     [346, 446],
     [352, 501],
     [338, 504],
     [335, 513],
     [341, 514],
     [344, 519],
     [358, 515],
     [351, 464],
     [353, 458],
     [467, 440],
     [457, 420]
    ],
    [[304, 509], [301, 510], [304, 516], [324, 517], [316, 511], [306, 512], [305, 508]],
    [[307, 521], [306, 525], [311, 523], [308, 521]]
   ]
  },
  {
   "color": "#84d426ff",
   "pois": [{"id": "centroid", "point": [305, 439]}],
   "box": [264, 384, 351, 497],
   "polygons": [
    [
     [320, 385],
     [264, 393],
     [273, 451],
     [287, 493],
     [289, 476],
     [293, 487],
     [306, 491],
     [309, 497],
     [317, 491],
     [321, 497],
     [323, 488],
     [330, 490],
     [330, 496],
     [351, 491],
     [346, 475],
     [345, 451],
     [322, 454],
     [318, 421],
     [341, 417],
     [326, 384]
    ]
   ]
  }
 ],
 [
  {
   "color": "#c0ed3aff",
   "pois": [{"id": "centroid", "point": [494, 454]}],
   "box": [406, 438, 563, 509],
   "polygons": [
    [
     [454, 439],
     [450, 447],
     [414, 448],
     [406, 462],
     [551, 463],
     [556, 505],
     [562, 490],
     [557, 445],
     [534, 444],
     [532, 438],
     [454, 438]
    ],
    [[562, 507], [563, 509], [562, 504]]
   ]
  },
  {
   "color": "#e4efcbff",
   "pois": [{"id": "centroid", "point": [503, 434]}],
   "box": [382, 414, 575, 496],
   "polygons": [
    [
     [436, 415],
     [432, 416],
     [425, 428],
     [428, 447],
     [451, 444],
     [454, 438],
     [535, 439],
     [536, 444],
     [559, 446],
     [563, 488],
     [566, 496],
     [569, 495],
     [572, 485],
     [568, 472],
     [568, 454],
     [571, 454],
     [573, 464],
     [575, 459],
     [570, 415],
     [436, 414]
    ],
    [[390, 478], [382, 490], [383, 493], [398, 492], [395, 477]]
   ]
  },
  {
   "color": "#9fc24cff",
   "pois": [{"id": "centroid", "point": [516, 407]}],
   "box": [397, 393, 582, 468],
   "polygons": [
    [
     [479, 394],
     [474, 401],
     [440, 402],
     [434, 414],
     [572, 415],
     [576, 456],
     [581, 444],
     [577, 399],
     [555, 398],
     [554, 393]
    ],
    [[581, 456], [582, 461], [582, 456]],
    [[398, 465], [397, 468], [402, 467], [400, 465]]
   ]
  },
  {
   "color": "#9fbbd7ff",
   "pois": [{"id": "centroid", "point": [504, 384]}],
   "box": [416, 371, 565, 446],
   "polygons": [
    [
     [458, 372],
     [450, 383],
     [453, 400],
     [471, 401],
     [479, 392],
     [552, 392],
     [556, 393],
     [557, 398],
     [562, 397],
     [565, 390],
     [563, 371]
    ],
    [[418, 431], [416, 433], [421, 434], [426, 446], [424, 431]]
   ]
  },
  {
   "color": "#2af6ccff",
   "pois": [{"id": "centroid", "point": [524, 362]}],
   "box": [426, 351, 577, 419],
   "polygons": [
    [
     [483, 352],
     [478, 359],
     [464, 360],
     [459, 369],
     [563, 370],
     [567, 386],
     [573, 376],
     [575, 398],
     [577, 397],
     [572, 351],
     [486, 351]
    ],
    [[570, 390], [568, 398], [571, 397], [570, 389]],
    [[426, 416], [427, 419], [430, 417], [426, 415]]
   ]
  },
  {
   "color": "#84d426ff",
   "pois": [{"id": "centroid", "point": [463, 336]}],
   "box": [434, 305, 509, 391],
   "polygons": [
    [
     [455, 306],
     [434, 338],
     [439, 383],
     [442, 380],
     [445, 391],
     [450, 390],
     [451, 381],
     [464, 358],
     [474, 357],
     [471, 348],
     [473, 336],
     [494, 335],
     [509, 310],
     [509, 305]
    ]
   ]
  }
 ],
 [
  {
   "color": "#c0ed3aff",
   "pois": [{"id": "centroid", "point": [586, 390]}],
   "box": [499, 365, 636, 448],
   "polygons": [
    [
     [554, 366],
     [544, 372],
     [519, 367],
     [499, 378],
     [611, 399],
     [614, 441],
     [635, 427],
     [631, 385],
     [619, 382],
     [615, 375],
     [558, 365]
    ],
    [[634, 437], [628, 441], [629, 448], [636, 443], [634, 436]]
   ]
  },
  {
   "color": "#e4efcbff",
   "pois": [{"id": "centroid", "point": [621, 375]}],
   "box": [542, 342, 672, 435],
   "polygons": [
    [
     [559, 343],
     [542, 352],
     [544, 371],
     [556, 364],
     [619, 376],
     [620, 383],
     [644, 389],
     [643, 396],
     [633, 395],
     [638, 433],
     [648, 435],
     [661, 426],
     [659, 419],
     [655, 420],
     [653, 401],
     [658, 395],
     [661, 410],
     [672, 402],
     [669, 360],
     [563, 342]
    ]
   ]
  },
  {
   "color": "#9fc24cff",
   "pois": [{"id": "centroid", "point": [646, 353]}],
   "box": [563, 329, 693, 408],
   "polygons": [
    [
     [614, 330],
     [605, 336],
     [579, 331],
     [563, 341],
     [671, 361],
     [674, 401],
     [692, 389],
     [689, 347],
     [677, 345],
     [674, 338],
     [617, 329]
    ],
    [[692, 398], [686, 401], [687, 408], [693, 405], [692, 397]]
   ]
  },
  {
   "color": "#9fbbd7ff",
   "pois": [{"id": "centroid", "point": [660, 329]}],
   "box": [601, 308, 707, 387],
   "polygons": [
    [
     [617, 309],
     [601, 318],
     [603, 335],
     [614, 329],
     [628, 330],
     [674, 338],
     [678, 346],
     [687, 347],
     [705, 338],
     [703, 321],
     [624, 308]
    ],
    [[701, 361], [696, 364], [702, 360]],
    [[704, 347], [691, 355], [695, 358], [696, 369], [706, 363], [704, 346]],
    [[706, 373], [693, 381], [694, 387], [707, 378], [706, 372]]
   ]
  },
  {
   "color": "#2af6ccff",
   "pois": [{"id": "centroid", "point": [693, 318]}],
   "box": [620, 294, 732, 377],
   "polygons": [
    [
     [655, 295],
     [648, 299],
     [633, 299],
     [620, 307],
     [704, 321],
     [707, 337],
     [722, 327],
     [724, 348],
     [729, 351],
     [728, 358],
     [709, 370],
     [709, 377],
     [732, 361],
     [729, 305],
     [661, 294]
    ],
    [[715, 352], [711, 355], [716, 351]],
    [[717, 339], [706, 345], [708, 362], [719, 354], [717, 338]]
   ]
  },
  {
   "color": "#84d426ff",
   "pois": [{"id": "centroid", "point": [675, 272]}],
   "box": [636, 251, 728, 299],
   "polygons": [
    [
     [682, 252],
     [636, 278],
     [639, 298],
     [646, 299],
     [655, 293],
     [668, 295],
     [670, 279],
     [689, 281],
     [728, 257],
     [690, 251]
    ]
   ]
  }
 ],
 [
  {
   "color": "#c0ed3aff",
   "pois": [{"id": "centroid", "point": [697, 353]}],
   "box": [625, 321, 734, 401],
   "polygons": [
    [
     [648, 322],
     [625, 329],
     [703, 360],
     [706, 401],
     [734, 390],
     [731, 350],
     [728, 349],
     [726, 340],
     [686, 324],
     [671, 328],
     [649, 321]
    ]
   ]
  },
  {
   "color": "#e4efcbff",
   "pois": [{"id": "centroid", "point": [742, 343]}],
   "box": [683, 304, 786, 398],
   "polygons": [
    [[740, 353], [742, 360], [752, 362], [751, 355], [740, 352]],
    [
     [704, 305],
     [683, 311],
     [683, 323],
     [728, 341],
     [728, 346],
     [733, 351],
     [737, 398],
     [746, 397],
     [747, 387],
     [761, 382],
     [762, 365],
     [768, 365],
     [769, 379],
     [786, 372],
     [782, 332],
     [706, 304]
    ]
   ]
  },
  {
   "color": "#9fc24cff",
   "pois": [{"id": "centroid", "point": [778, 326]}],
   "box": [707, 297, 814, 372],
   "polygons": [
    [
     [727, 298],
     [707, 304],
     [785, 333],
     [788, 372],
     [814, 363],
     [812, 324],
     [808, 322],
     [807, 315],
     [766, 299],
     [751, 303],
     [731, 297]
    ]
   ]
  },
  {
   "color": "#9fbbd7ff",
   "pois": [{"id": "centroid", "point": [813, 314]}],
   "box": [755, 280, 1483, 757],
   "polygons": [
    [[839, 340], [841, 339]],
    [
     [781, 281],
     [761, 287],
     [760, 292],
     [755, 294],
     [756, 300],
     [764, 298],
     [808, 315],
     [814, 326],
     [817, 367],
     [830, 362],
     [832, 357],
     [848, 352],
     [847, 350],
     [829, 357],
     [823, 355],
     [824, 335],
     [831, 335],
     [832, 347],
     [846, 342],
     [846, 325],
     [822, 334],
     [818, 333],
     [819, 327],
     [847, 317],
     [846, 302],
     [784, 280]
    ],
    [[1446, 745], [1446, 744]],
    [[1481, 755], [1483, 757], [1482, 755]]
   ]
  },
  {
   "color": "#2af6ccff",
   "pois": [{"id": "centroid", "point": [849, 300]}],
   "box": [785, 272, 883, 352],
   "polygons": [
    [
     [824, 273],
     [815, 276],
     [806, 273],
     [785, 280],
     [848, 302],
     [849, 317],
     [871, 311],
     [872, 333],
     [878, 334],
     [876, 341],
     [851, 349],
     [850, 352],
     [883, 340],
     [882, 290],
     [829, 272]
    ],
    [[860, 333], [852, 336], [861, 332]],
    [[862, 321], [848, 326], [849, 341], [865, 335], [864, 320]]
   ]
  },
  {
   "color": "#84d426ff",
   "pois": [{"id": "centroid", "point": [871, 254]}],
   "box": [830, 236, 929, 281],
   "polygons": [
    [
     [893, 237],
     [830, 256],
     [831, 272],
     [858, 281],
     [854, 267],
     [858, 266],
     [859, 261],
     [873, 265],
     [929, 246],
     [896, 236]
    ]
   ]
  }
 ],
 [
  {
   "color": "#c0ed3aff",
   "pois": [{"id": "centroid", "point": [831, 327]}],
   "box": [782, 290, 862, 371],
   "polygons": [
    [
     [804, 291],
     [782, 294],
     [822, 332],
     [824, 371],
     [843, 368],
     [848, 362],
     [862, 360],
     [860, 316],
     [837, 296],
     [820, 299],
     [809, 290]
    ]
   ]
  },
  {
   "color": "#e4efcbff",
   "pois": [{"id": "centroid", "point": [882, 320]}],
   "box": [840, 281, 918, 361],
   "polygons": [
    [[876, 330], [876, 338], [881, 342], [882, 334], [877, 330]],
    [
     [869, 282],
     [847, 285],
     [846, 295],
     [840, 297],
     [862, 317],
     [863, 361],
     [889, 356],
     [890, 346],
     [894, 345],
     [897, 346],
     [898, 355],
     [918, 353],
     [918, 316],
     [875, 281]
    ]
   ]
  },
  {
   "color": "#9fc24cff",
   "pois": [{"id": "centroid", "point": [928, 313]}],
   "box": [877, 277, 957, 356],
   "polygons": [
    [
     [898, 278],
     [877, 281],
     [920, 317],
     [921, 356],
     [947, 352],
     [948, 347],
     [957, 345],
     [956, 302],
     [931, 283],
     [912, 284],
     [903, 277]
    ]
   ]
  },
  {
   "color": "#9fbbd7ff",
   "pois": [{"id": "centroid", "point": [971, 302]}],
   "box": [928, 268, 1005, 346],
   "polygons": [
    [[993, 333], [987, 334], [997, 332]],
    [
     [961, 269],
     [940, 272],
     [939, 276],
     [928, 278],
     [929, 283],
     [958, 304],
     [959, 346],
     [975, 342],
     [978, 325],
     [983, 326],
     [984, 339],
     [1005, 335],
     [1004, 319],
     [969, 323],
     [970, 317],
     [1005, 311],
     [1004, 295],
     [967, 268]
    ]
   ]
  },
  {
   "color": "#2af6ccff",
   "pois": [{"id": "centroid", "point": [1020, 298]}],
   "box": [969, 264, 1049, 344],
   "polygons": [
    [
     [991, 265],
     [969, 268],
     [1006, 296],
     [1006, 312],
     [1034, 309],
     [1035, 331],
     [1040, 332],
     [1039, 339],
     [1023, 342],
     [1025, 344],
     [1049, 343],
     [1049, 289],
     [1016, 265],
     [999, 268],
     [994, 264]
    ],
    [[1015, 330], [1010, 331], [1021, 329]],
    [[1025, 317], [1006, 320], [1006, 336], [1027, 332], [1026, 316]]
   ]
  },
  {
   "color": "#84d426ff",
   "pois": [{"id": "centroid", "point": [1079, 259]}],
   "box": [1040, 240, 1142, 302],
   "polygons": [
    [
     [1116, 241],
     [1040, 252],
     [1041, 283],
     [1060, 289],
     [1065, 296],
     [1058, 302],
     [1066, 302],
     [1069, 292],
     [1061, 287],
     [1062, 275],
     [1058, 272],
     [1063, 260],
     [1073, 265],
     [1142, 254],
     [1120, 240]
    ]
   ]
  }
 ],
 [
  {
   "color": "#c0ed3aff",
   "pois": [{"id": "centroid", "point": [982, 318]}],
   "box": [960, 279, 1009, 360],
   "polygons": [
    [[961, 279], [960, 360], [962, 353], [1009, 352], [1007, 289], [989, 288], [988, 279]]
   ]
  },
  {
   "color": "#e4efcbff",
   "pois": [{"id": "centroid", "point": [1038, 319]}],
   "box": [1011, 279, 1064, 360],
   "polygons": [
    [[1023, 329], [1025, 339], [1027, 332], [1025, 327], [1023, 328]],
    [
     [1029, 280],
     [1028, 289],
     [1011, 290],
     [1011, 351],
     [1030, 352],
     [1031, 345],
     [1037, 344],
     [1040, 360],
     [1063, 359],
     [1064, 322],
     [1057, 279]
    ]
   ]
  },
  {
   "color": "#9fc24cff",
   "pois": [{"id": "centroid", "point": [1086, 318]}],
   "box": [1059, 279, 1112, 360],
   "polygons": [
    [
     [1061, 279],
     [1059, 280],
     [1064, 313],
     [1064, 360],
     [1072, 359],
     [1073, 353],
     [1112, 352],
     [1112, 306],
     [1109, 290],
     [1090, 288],
     [1088, 279]
    ]
   ]
  },
  {
   "color": "#9fbbd7ff",
   "pois": [{"id": "centroid", "point": [1139, 315]}],
   "box": [941, 279, 1168, 886],
   "polygons": [
    [[1148, 347], [1146, 348], [1158, 347]],
    [
     [1130, 280],
     [1128, 283],
     [1112, 284],
     [1116, 315],
     [1115, 353],
     [1133, 352],
     [1134, 337],
     [1141, 338],
     [1141, 352],
     [1166, 351],
     [1166, 335],
     [1127, 334],
     [1128, 328],
     [1167, 327],
     [1168, 313],
     [1158, 279]
    ],
    [[1153, 360], [1151, 361], [1164, 360]],
    [[941, 884], [942, 886], [941, 881]]
   ]
  },
  {
   "color": "#2af6ccff",
   "pois": [{"id": "centroid", "point": [1191, 319]}],
   "box": [1160, 279, 1219, 366],
   "polygons": [
    [
     [1162, 279],
     [1160, 280],
     [1169, 312],
     [1169, 328],
     [1200, 329],
     [1198, 348],
     [1199, 352],
     [1205, 353],
     [1204, 360],
     [1166, 361],
     [1167, 366],
     [1216, 366],
     [1219, 313],
     [1209, 283],
     [1190, 282],
     [1189, 279]
    ],
    [[1175, 347], [1173, 348], [1184, 347]],
    [[1169, 335], [1168, 352], [1191, 351], [1191, 335]]
   ]
  },
  {
   "color": "#84d426ff",
   "pois": [{"id": "centroid", "point": [1299, 285]}],
   "box": [1255, 273, 1359, 326],
   "polygons": [
    [
     [1258, 274],
     [1255, 308],
     [1257, 326],
     [1258, 320],
     [1261, 324],
     [1265, 323],
     [1265, 296],
     [1272, 285],
     [1283, 291],
     [1355, 291],
     [1359, 289],
     [1350, 273]
    ]
   ]
  }
 ],
 [
  {
   "color": "#c0ed3aff",
   "pois": [{"id": "centroid", "point": [1130, 335]}],
   "box": [1095, 294, 1172, 383],
   "polygons": [
    [
     [1133, 295],
     [1095, 331],
     [1095, 367],
     [1150, 375],
     [1150, 383],
     [1154, 329],
     [1172, 310],
     [1163, 307],
     [1164, 297],
     [1141, 294]
    ]
   ]
  },
  {
   "color": "#e4efcbff",
   "pois": [{"id": "centroid", "point": [1182, 341]}],
   "box": [1153, 303, 1231, 390],
   "polygons": [
    [
     [1201, 304],
     [1194, 312],
     [1175, 309],
     [1154, 330],
     [1153, 384],
     [1157, 390],
     [1162, 382],
     [1163, 366],
     [1170, 368],
     [1170, 383],
     [1194, 380],
     [1197, 344],
     [1231, 307],
     [1208, 303]
    ]
   ]
  },
  {
   "color": "#9fc24cff",
   "pois": [{"id": "centroid", "point": [1231, 349]}],
   "box": [1196, 307, 1272, 390],
   "polygons": [
    [
     [1231, 308],
     [1197, 345],
     [1196, 382],
     [1231, 387],
     [1238, 384],
     [1238, 388],
     [1251, 390],
     [1255, 384],
     [1258, 344],
     [1272, 326],
     [1271, 322],
     [1261, 320],
     [1261, 310],
     [1237, 307]
    ]
   ]
  },
  {
   "color": "#9fbbd7ff",
   "pois": [{"id": "centroid", "point": [1287, 356]}],
   "box": [1256, 316, 1333, 410],
   "polygons": [
    [[1287, 388], [1298, 388], [1287, 387]],
    [
     [1303, 317],
     [1300, 320],
     [1279, 319],
     [1257, 345],
     [1256, 390],
     [1259, 391],
     [1259, 400],
     [1267, 402],
     [1268, 405],
     [1302, 410],
     [1305, 408],
     [1305, 404],
     [1301, 402],
     [1270, 397],
     [1273, 375],
     [1280, 376],
     [1280, 391],
     [1298, 394],
     [1306, 392],
     [1306, 377],
     [1267, 370],
     [1268, 364],
     [1308, 368],
     [1309, 351],
     [1333, 320],
     [1306, 316]
    ]
   ]
  },
  {
   "color": "#2af6ccff",
   "pois": [{"id": "centroid", "point": [1341, 362]}],
   "box": [1305, 320, 1384, 416],
   "polygons": [
    [
     [1334, 321],
     [1310, 351],
     [1308, 366],
     [1309, 370],
     [1341, 375],
     [1340, 400],
     [1345, 401],
     [1344, 408],
     [1308, 403],
     [1305, 406],
     [1309, 411],
     [1342, 416],
     [1364, 385],
     [1366, 363],
     [1381, 338],
     [1380, 331],
     [1365, 328],
     [1364, 324],
     [1336, 320]
    ],
    [[1382, 368], [1381, 376], [1384, 368]],
    [[1314, 391], [1312, 392], [1325, 392], [1318, 391]],
    [[1308, 379], [1307, 395], [1331, 397], [1330, 380], [1308, 378]]
   ]
  },
  {
   "color": "#84d426ff",
   "pois": [{"id": "centroid", "point": [1502, 347]}],
   "box": [1444, 329, 1551, 397],
   "polygons": [
    [
     [1451, 330],
     [1444, 341],
     [1444, 354],
     [1455, 342],
     [1457, 343],
     [1454, 349],
     [1458, 350],
     [1544, 362],
     [1539, 394],
     [1540, 397],
     [1542, 395],
     [1542, 387],
     [1549, 370],
     [1551, 342],
     [1457, 329]
    ]
   ]
  }
 ],
 [
  {
   "color": "#c0ed3aff",
   "pois": [{"id": "centroid", "point": [1257, 371]}],
   "box": [1214, 327, 1318, 425],
   "polygons": [
    [
     [1293, 328],
     [1215, 359],
     [1214, 400],
     [1243, 410],
     [1239, 421],
     [1251, 425],
     [1269, 418],
     [1274, 367],
     [1318, 348],
     [1318, 334],
     [1294, 327]
    ]
   ]
  },
  {
   "color": "#e4efcbff",
   "pois": [{"id": "centroid", "point": [1308, 378]}],
   "box": [1270, 345, 1379, 429],
   "polygons": [
    [
     [1353, 346],
     [1337, 353],
     [1319, 348],
     [1275, 367],
     [1271, 381],
     [1270, 419],
     [1273, 404],
     [1277, 405],
     [1280, 406],
     [1280, 422],
     [1300, 429],
     [1304, 386],
     [1379, 354],
     [1353, 345]
    ]
   ]
  },
  {
   "color": "#9fc24cff",
   "pois": [{"id": "centroid", "point": [1348, 401]}],
   "box": [1302, 354, 1409, 458],
   "polygons": [
    [
     [1380, 355],
     [1304, 387],
     [1302, 430],
     [1331, 440],
     [1344, 435],
     [1335, 441],
     [1335, 458],
     [1344, 454],
     [1353, 456],
     [1363, 451],
     [1368, 395],
     [1407, 377],
     [1409, 362],
     [1383, 354]
    ]
   ]
  },
  {
   "color": "#9fbbd7ff",
   "pois": [{"id": "centroid", "point": [1404, 410]}],
   "box": [1363, 372, 1474, 471],
   "polygons": [
    [[1393, 445], [1404, 447], [1393, 444]],
    [
     [1420, 373],
     [1368, 396],
     [1363, 446],
     [1365, 457],
     [1408, 471],
     [1407, 463],
     [1377, 452],
     [1380, 429],
     [1388, 432],
     [1388, 448],
     [1409, 455],
     [1411, 436],
     [1375, 424],
     [1375, 417],
     [1411, 428],
     [1416, 410],
     [1474, 382],
     [1448, 373],
     [1439, 377],
     [1424, 372]
    ]
   ]
  },
  {
   "color": "#2af6ccff",
   "pois": [{"id": "centroid", "point": [1459, 419]}],
   "box": [1410, 382, 1519, 472],
   "polygons": [
    [
     [1476, 383],
     [1415, 411],
     [1416, 430],
     [1444, 439],
     [1443, 457],
     [1455, 450],
     [1473, 456],
     [1476, 431],
     [1515, 412],
     [1515, 400],
     [1506, 397],
     [1506, 391],
     [1476, 382]
    ],
    [[1514, 437], [1516, 441], [1519, 440], [1518, 436], [1514, 436]],
    [[1418, 454], [1428, 457], [1417, 453]],
    [[1413, 437], [1412, 456], [1433, 461], [1434, 444], [1414, 437]],
    [[1411, 465], [1410, 472], [1419, 468], [1412, 465]]
   ]
  },
  {
   "color": "#84d426ff",
   "pois": [{"id": "centroid", "point": [1658, 447]}],
   "box": [1587, 410, 1707, 512],
   "polygons": [
    [
     [1605, 411],
     [1587, 420],
     [1587, 429],
     [1596, 424],
     [1598, 425],
     [1589, 431],
     [1677, 458],
     [1670, 499],
     [1674, 498],
     [1678, 502],
     [1675, 506],
     [1680, 512],
     [1696, 502],
     [1701, 483],
     [1678, 496],
     [1679, 489],
     [1702, 474],
     [1707, 439],
     [1609, 410]
    ]
   ]
  }
 ],
 [
  {
   "color": "#c0ed3aff",
   "pois": [{"id": "centroid", "point": [1362, 417]}],
   "box": [1309, 381, 1446, 478],
   "polygons": [
    [
     [1423, 382],
     [1313, 401],
     [1309, 442],
     [1331, 458],
     [1329, 466],
     [1321, 461],
     [1320, 467],
     [1333, 478],
     [1349, 475],
     [1352, 428],
     [1366, 424],
     [1369, 416],
     [1432, 405],
     [1445, 412],
     [1446, 392],
     [1427, 381]
    ]
   ]
  },
  {
   "color": "#e4efcbff",
   "pois": [{"id": "centroid", "point": [1402, 432]}],
   "box": [1349, 406, 1494, 491],
   "polygons": [
    [
     [1429, 407],
     [1367, 418],
     [1365, 426],
     [1353, 428],
     [1352, 456],
     [1358, 459],
     [1358, 477],
     [1361, 480],
     [1372, 487],
     [1377, 442],
     [1494, 420],
     [1476, 409],
     [1445, 414],
     [1433, 406]
    ],
    [[1350, 465], [1351, 467], [1350, 462]],
    [[1349, 476], [1350, 479], [1349, 473]],
    [[1349, 483], [1349, 490], [1356, 491], [1355, 484], [1349, 482]]
   ]
  },
  {
   "color": "#9fc24cff",
   "pois": [{"id": "centroid", "point": [1428, 464]}],
   "box": [1374, 421, 1517, 524],
   "polygons": [
    [
     [1494, 422],
     [1377, 444],
     [1374, 489],
     [1396, 504],
     [1419, 499],
     [1396, 506],
     [1395, 512],
     [1387, 507],
     [1386, 515],
     [1397, 524],
     [1429, 522],
     [1437, 461],
     [1515, 446],
     [1517, 435],
     [1494, 421]
    ]
   ]
  },
  {
   "color": "#9fbbd7ff",
   "pois": [{"id": "centroid", "point": [1481, 478]}],
   "box": [1431, 446, 1570, 540],
   "polygons": [
    [[1454, 519], [1462, 522], [1455, 518]],
    [
     [1519, 447],
     [1436, 463],
     [1431, 527],
     [1450, 540],
     [1459, 538],
     [1439, 524],
     [1442, 502],
     [1445, 499],
     [1450, 503],
     [1449, 521],
     [1465, 532],
     [1467, 529],
     [1467, 511],
     [1440, 494],
     [1442, 486],
     [1470, 504],
     [1473, 484],
     [1570, 465],
     [1548, 452],
     [1533, 453],
     [1521, 446]
    ]
   ]
  },
  {
   "color": "#2af6ccff",
   "pois": [{"id": "centroid", "point": [1530, 501]}],
   "box": [1468, 466, 1623, 550],
   "polygons": [
    [
     [1567, 467],
     [1472, 486],
     [1470, 504],
     [1493, 519],
     [1491, 535],
     [1495, 539],
     [1514, 549],
     [1513, 545],
     [1516, 545],
     [1517, 541],
     [1523, 550],
     [1525, 548],
     [1522, 545],
     [1522, 538],
     [1511, 532],
     [1517, 533],
     [1520, 528],
     [1525, 529],
     [1531, 517],
     [1595, 504],
     [1597, 493],
     [1594, 490],
     [1594, 480],
     [1572, 466],
     [1567, 466]
    ],
    [
     [1469, 515],
     [1468, 534],
     [1476, 535],
     [1471, 530],
     [1479, 535],
     [1485, 533],
     [1485, 523],
     [1469, 514]
    ],
    [[1594, 528], [1595, 532], [1594, 527]],
    [[1611, 527], [1605, 530], [1610, 532], [1611, 537], [1623, 534], [1611, 526]],
    [[1555, 533], [1555, 540], [1555, 532]]
   ]
  },
  {
   "color": "#84d426ff",
   "pois": [{"id": "centroid", "point": [1736, 577]}],
   "box": [1658, 518, 1789, 650],
   "polygons": [
    [
     [1702, 519],
     [1668, 526],
     [1668, 533],
     [1676, 533],
     [1658, 537],
     [1732, 583],
     [1721, 650],
     [1778, 636],
     [1780, 616],
     [1739, 625],
     [1743, 616],
     [1784, 605],
     [1789, 568],
     [1702, 518]
    ]
   ]
  }
 ],
 [
  {
   "color": "#c0ed3aff",
   "pois": [{"id": "centroid", "point": [1441, 483]}],
   "box": [1366, 462, 1570, 548],
   "polygons": [
    [
     [1370, 463],
     [1366, 507],
     [1374, 525],
     [1372, 533],
     [1369, 531],
     [1374, 548],
     [1378, 547],
     [1379, 543],
     [1385, 495],
     [1409, 494],
     [1410, 488],
     [1495, 489],
     [1500, 498],
     [1522, 497],
     [1525, 477],
     [1515, 462]
    ],
    [[1556, 527], [1555, 544], [1570, 543], [1560, 527]]
   ]
  },
  {
   "color": "#e4efcbff",
   "pois": [{"id": "centroid", "point": [1454, 505]}],
   "box": [1379, 488, 1575, 562],
   "polygons": [
    [
     [1410, 489],
     [1408, 496],
     [1384, 497],
     [1383, 541],
     [1388, 560],
     [1393, 514],
     [1546, 513],
     [1536, 498],
     [1498, 497],
     [1494, 488],
     [1422, 488]
    ],
    [[1557, 555], [1555, 556], [1573, 558], [1575, 555]],
    [[1379, 558], [1379, 562], [1379, 557]]
   ]
  },
  {
   "color": "#9fc24cff",
   "pois": [{"id": "centroid", "point": [1454, 545]}],
   "box": [1388, 514, 1605, 609],
   "polygons": [
    [
     [1411, 515],
     [1393, 516],
     [1388, 562],
     [1397, 583],
     [1426, 584],
     [1396, 585],
     [1395, 591],
     [1392, 589],
     [1391, 592],
     [1396, 606],
     [1425, 609],
     [1430, 607],
     [1437, 545],
     [1540, 544],
     [1550, 546],
     [1552, 552],
     [1554, 551],
     [1555, 530],
     [1546, 515],
     [1411, 514]
    ],
    [[1597, 595], [1594, 594], [1592, 604], [1605, 603], [1599, 594]]
   ]
  },
  {
   "color": "#9fbbd7ff",
   "pois": [{"id": "centroid", "point": [1504, 568]}],
   "box": [1429, 545, 1621, 645],
   "polygons": [
    [
     [1437, 546],
     [1429, 612],
     [1442, 645],
     [1431, 615],
     [1434, 587],
     [1438, 586],
     [1435, 581],
     [1437, 571],
     [1447, 593],
     [1451, 574],
     [1581, 573],
     [1571, 556],
     [1553, 555],
     [1547, 545]
    ],
    [[1439, 609], [1443, 615], [1439, 608]],
    [[1439, 592], [1439, 613], [1444, 623], [1445, 603], [1439, 591]],
    [[1591, 615], [1588, 629], [1590, 636], [1613, 620], [1613, 625], [1621, 631], [1611, 614]]
   ]
  },
  {
   "color": "#2af6ccff",
   "pois": [{"id": "centroid", "point": [1521, 607]}],
   "box": [1442, 575, 1637, 670],
   "polygons": [
    [
     [1450, 575],
     [1448, 593],
     [1457, 615],
     [1455, 627],
     [1466, 639],
     [1463, 627],
     [1466, 632],
     [1466, 627],
     [1469, 627],
     [1473, 638],
     [1473, 631],
     [1476, 632],
     [1475, 638],
     [1480, 635],
     [1476, 640],
     [1480, 653],
     [1474, 662],
     [1480, 665],
     [1487, 658],
     [1492, 620],
     [1581, 618],
     [1588, 629],
     [1593, 596],
     [1581, 575]
    ],
    [
     [1446, 611],
     [1446, 629],
     [1450, 637],
     [1446, 626],
     [1447, 624],
     [1451, 630],
     [1452, 623],
     [1446, 610]
    ],
    [
     [1455, 637],
     [1455, 656],
     [1457, 652],
     [1459, 653],
     [1455, 645],
     [1456, 640],
     [1459, 642],
     [1456, 637]
    ],
    [[1526, 641], [1525, 650], [1529, 649], [1529, 641]],
    [
     [1579, 641],
     [1578, 656],
     [1585, 665],
     [1606, 664],
     [1599, 659],
     [1607, 648],
     [1601, 650],
     [1598, 646],
     [1585, 645],
     [1585, 641]
    ],
    [[1465, 644], [1463, 647], [1468, 652], [1465, 643]],
    [[1442, 646], [1454, 670], [1454, 661], [1449, 658], [1442, 645]],
    [[1632, 652], [1615, 653], [1618, 664], [1636, 664], [1637, 658], [1632, 651]],
    [[1460, 658], [1463, 662], [1460, 657]]
   ]
  },
  {
   "color": "#84d426ff",
   "pois": [{"id": "centroid", "point": [1677, 730]}],
   "box": [1613, 657, 1732, 804],
   "polygons": [
    [
     [1638, 658],
     [1638, 665],
     [1642, 666],
     [1613, 667],
     [1648, 729],
     [1637, 804],
     [1719, 804],
     [1722, 781],
     [1661, 780],
     [1663, 772],
     [1726, 770],
     [1732, 728],
     [1687, 657]
    ]
   ]
  }
 ],
 [
  {
   "color": "#c0ed3aff",
   "pois": [{"id": "centroid", "point": [1460, 581]}],
   "box": [1351, 533, 1567, 660],
   "polygons": [
    [
     [1363, 534],
     [1352, 551],
     [1351, 569],
     [1371, 573],
     [1377, 565],
     [1468, 582],
     [1468, 594],
     [1503, 601],
     [1499, 649],
     [1562, 660],
     [1567, 640],
     [1511, 629],
     [1520, 567],
     [1518, 559],
     [1366, 533]
    ]
   ]
  },
  {
   "color": "#e4efcbff",
   "pois": [{"id": "centroid", "point": [1437, 606]}],
   "box": [1330, 566, 1557, 692],
   "polygons": [
    [
     [1376, 567],
     [1372, 573],
     [1342, 568],
     [1330, 588],
     [1495, 619],
     [1489, 681],
     [1507, 685],
     [1509, 679],
     [1506, 676],
     [1511, 674],
     [1508, 672],
     [1510, 669],
     [1517, 672],
     [1523, 669],
     [1527, 673],
     [1528, 669],
     [1532, 673],
     [1535, 672],
     [1535, 676],
     [1539, 678],
     [1539, 684],
     [1543, 685],
     [1540, 690],
     [1550, 692],
     [1557, 674],
     [1555, 671],
     [1495, 659],
     [1503, 603],
     [1501, 600],
     [1466, 593],
     [1467, 586],
     [1463, 581],
     [1378, 566]
    ]
   ]
  },
  {
   "color": "#9fc24cff",
   "pois": [{"id": "centroid", "point": [1416, 637]}],
   "box": [1312, 589, 1543, 728],
   "polygons": [
    [
     [1329, 590],
     [1317, 609],
     [1314, 660],
     [1324, 660],
     [1343, 626],
     [1463, 649],
     [1463, 662],
     [1477, 665],
     [1472, 715],
     [1532, 728],
     [1538, 726],
     [1543, 706],
     [1538, 709],
     [1540, 715],
     [1533, 715],
     [1533, 718],
     [1526, 714],
     [1524, 722],
     [1517, 721],
     [1511, 715],
     [1504, 714],
     [1507, 708],
     [1500, 705],
     [1503, 700],
     [1496, 696],
     [1498, 694],
     [1486, 691],
     [1494, 619],
     [1334, 589]
    ],
    [[1313, 661], [1312, 680], [1321, 665], [1315, 661]]
   ]
  },
  {
   "color": "#9fbbd7ff",
   "pois": [{"id": "centroid", "point": [1426, 677]}],
   "box": [1327, 626, 1535, 765],
   "polygons": [
    [
     [1343, 627],
     [1327, 658],
     [1468, 686],
     [1461, 752],
     [1471, 754],
     [1473, 750],
     [1480, 751],
     [1483, 747],
     [1487, 749],
     [1502, 743],
     [1505, 744],
     [1504, 748],
     [1499, 752],
     [1504, 751],
     [1515, 763],
     [1524, 765],
     [1533, 739],
     [1469, 725],
     [1476, 669],
     [1475, 665],
     [1461, 661],
     [1462, 649],
     [1344, 626]
    ],
    [[1535, 742], [1535, 746], [1535, 741]],
    [[1532, 750], [1532, 749]],
    [[1482, 754], [1484, 756], [1483, 753]],
    [[1528, 762], [1528, 761]]
   ]
  },
  {
   "color": "#2af6ccff",
   "pois": [{"id": "centroid", "point": [1392, 713]}],
   "box": [1302, 658, 1519, 809],
   "polygons": [
    [
     [1325, 659],
     [1306, 691],
     [1303, 731],
     [1306, 735],
     [1302, 741],
     [1303, 748],
     [1306, 744],
     [1307, 749],
     [1331, 754],
     [1335, 712],
     [1425, 731],
     [1432, 729],
     [1437, 718],
     [1438, 727],
     [1444, 729],
     [1434, 756],
     [1428, 758],
     [1427, 785],
     [1444, 789],
     [1451, 780],
     [1461, 782],
     [1463, 778],
     [1476, 779],
     [1477, 773],
     [1487, 775],
     [1480, 768],
     [1475, 771],
     [1468, 769],
     [1472, 764],
     [1458, 761],
     [1467, 688],
     [1465, 685],
     [1327, 658]
    ],
    [[1372, 743], [1371, 752], [1376, 751], [1374, 743]],
    [[1517, 775], [1513, 776], [1514, 778], [1519, 777], [1518, 774]],
    [
     [1496, 779],
     [1486, 784],
     [1491, 786],
     [1489, 788],
     [1508, 792],
     [1508, 803],
     [1517, 783],
     [1498, 778]
    ],
    [[1519, 787], [1519, 786]],
    [[1515, 797], [1515, 796]],
    [[1511, 809], [1511, 808]]
   ]
  },
  {
   "color": "#84d426ff",
   "pois": [{"id": "centroid", "point": [1440, 863]}],
   "box": [1374, 781, 1507, 956],
   "polygons": [
    [[1402, 904], [1401, 915], [1459, 929], [1469, 929], [1470, 921], [1467, 919], [1403, 904]],
    [
     [1451, 782],
     [1447, 790],
     [1413, 783],
     [1383, 849],
     [1374, 927],
     [1376, 934],
     [1466, 956],
     [1497, 869],
     [1507, 792],
     [1456, 781]
    ]
   ]
  }
 ],
 [
  {
   "color": "#c0ed3aff",
   "pois": [{"id": "centroid", "point": [1367, 676]}],
   "box": [1256, 598, 1463, 770],
   "polygons": [
    [
     [1287, 599],
     [1257, 614],
     [1256, 638],
     [1267, 632],
     [1347, 666],
     [1347, 683],
     [1366, 691],
     [1364, 712],
     [1367, 717],
     [1367, 728],
     [1372, 730],
     [1370, 738],
     [1364, 743],
     [1365, 752],
     [1369, 742],
     [1432, 770],
     [1463, 750],
     [1418, 729],
     [1426, 655],
     [1288, 598]
    ]
   ]
  },
  {
   "color": "#e4efcbff",
   "pois": [{"id": "centroid", "point": [1316, 698]}],
   "box": [1199, 628, 1420, 799],
   "polygons": [
    [
     [1230, 629],
     [1199, 645],
     [1338, 708],
     [1333, 774],
     [1340, 770],
     [1338, 767],
     [1341, 764],
     [1356, 769],
     [1357, 773],
     [1364, 775],
     [1361, 777],
     [1363, 781],
     [1371, 780],
     [1368, 784],
     [1371, 791],
     [1385, 799],
     [1420, 778],
     [1363, 752],
     [1362, 732],
     [1366, 724],
     [1363, 715],
     [1364, 691],
     [1345, 683],
     [1344, 665],
     [1270, 633],
     [1256, 639],
     [1230, 628]
    ],
    [[1364, 788], [1367, 787], [1364, 787]]
   ]
  },
  {
   "color": "#9fc24cff",
   "pois": [{"id": "centroid", "point": [1270, 724]}],
   "box": [1163, 646, 1375, 831],
   "polygons": [
    [
     [1195, 647],
     [1163, 664],
     [1163, 689],
     [1172, 684],
     [1177, 685],
     [1274, 731],
     [1274, 743],
     [1277, 745],
     [1273, 810],
     [1276, 800],
     [1338, 831],
     [1355, 821],
     [1354, 817],
     [1345, 817],
     [1341, 823],
     [1335, 821],
     [1323, 803],
     [1323, 799],
     [1327, 798],
     [1324, 794],
     [1327, 791],
     [1325, 784],
     [1331, 783],
     [1336, 708],
     [1198, 646]
    ],
    [[1373, 807], [1366, 811], [1372, 810], [1375, 806]]
   ]
  },
  {
   "color": "#9fbbd7ff",
   "pois": [{"id": "centroid", "point": [1233, 763]}],
   "box": [1125, 684, 1337, 864],
   "polygons": [
    [
     [1173, 685],
     [1125, 711],
     [1240, 767],
     [1237, 837],
     [1245, 835],
     [1252, 842],
     [1253, 838],
     [1273, 836],
     [1275, 840],
     [1266, 845],
     [1273, 847],
     [1274, 856],
     [1290, 864],
     [1294, 858],
     [1302, 857],
     [1328, 840],
     [1271, 810],
     [1270, 805],
     [1266, 806],
     [1268, 793],
     [1272, 795],
     [1273, 790],
     [1272, 773],
     [1269, 772],
     [1272, 730],
     [1173, 684]
    ],
    [[1337, 844], [1317, 857], [1337, 847], [1337, 843]],
    [[1315, 858], [1315, 857]]
   ]
  },
  {
   "color": "#2af6ccff",
   "pois": [{"id": "centroid", "point": [1168, 790]}],
   "box": [1072, 711, 1283, 910],
   "polygons": [
    [
     [1123, 712],
     [1073, 739],
     [1072, 815],
     [1078, 818],
     [1081, 816],
     [1083, 821],
     [1092, 824],
     [1094, 766],
     [1171, 805],
     [1191, 794],
     [1193, 803],
     [1198, 805],
     [1197, 814],
     [1171, 833],
     [1171, 869],
     [1174, 864],
     [1178, 865],
     [1238, 898],
     [1267, 880],
     [1269, 878],
     [1265, 873],
     [1253, 874],
     [1249, 870],
     [1247, 873],
     [1239, 873],
     [1241, 879],
     [1234, 884],
     [1230, 883],
     [1232, 880],
     [1227, 879],
     [1223, 871],
     [1217, 871],
     [1209, 866],
     [1211, 862],
     [1228, 867],
     [1233, 861],
     [1238, 861],
     [1235, 855],
     [1226, 858],
     [1221, 854],
     [1223, 850],
     [1232, 849],
     [1229, 845],
     [1236, 832],
     [1239, 767],
     [1124, 711]
    ],
    [[1125, 810], [1127, 815], [1127, 808], [1125, 809]],
    [[1245, 865], [1247, 871], [1248, 866], [1245, 864]],
    [[1274, 873], [1275, 875], [1275, 872]],
    [[1234, 874], [1231, 875], [1233, 878], [1236, 874], [1234, 873]],
    [[1280, 882], [1283, 880], [1280, 881]],
    [[1274, 885], [1239, 908], [1242, 910], [1273, 888], [1274, 884]]
   ]
  },
  {
   "color": "#84d426ff",
   "pois": [{"id": "centroid", "point": [1094, 936]}],
   "box": [1003, 850, 1203, 1035],
   "polygons": [
    [[1019, 968], [1021, 978], [1069, 1008], [1072, 1007], [1072, 997], [1025, 968], [1019, 967]],
    [
     [1101, 851],
     [1003, 909],
     [1003, 991],
     [1071, 1035],
     [1173, 968],
     [1178, 927],
     [1182, 932],
     [1187, 931],
     [1197, 923],
     [1200, 924],
     [1203, 916],
     [1186, 907],
     [1186, 879],
     [1143, 856],
     [1130, 864],
     [1101, 850]
    ],
    [[1181, 933], [1179, 934], [1184, 934], [1182, 933]],
    [[1179, 936], [1178, 965], [1183, 961], [1183, 936]]
   ]
  }
 ],
 [
  {
   "color": "#c0ed3aff",
   "pois": [{"id": "centroid", "point": [1181, 739]}],
   "box": [1102, 643, 1256, 841],
   "polygons": [
    [
     [1148, 644],
     [1105, 653],
     [1102, 672],
     [1103, 676],
     [1146, 718],
     [1145, 761],
     [1157, 773],
     [1158, 785],
     [1162, 788],
     [1161, 798],
     [1158, 799],
     [1158, 812],
     [1160, 801],
     [1198, 841],
     [1204, 840],
     [1207, 811],
     [1209, 816],
     [1230, 830],
     [1229, 834],
     [1256, 827],
     [1231, 801],
     [1235, 722],
     [1150, 643]
    ],
    [[1208, 820], [1207, 840], [1227, 834], [1221, 831], [1218, 824], [1209, 820]]
   ]
  },
  {
   "color": "#e4efcbff",
   "pois": [{"id": "centroid", "point": [1109, 755]}],
   "box": [1020, 661, 1187, 859],
   "polygons": [
    [
     [1063, 662],
     [1020, 672],
     [1097, 755],
     [1097, 825],
     [1127, 859],
     [1187, 844],
     [1155, 811],
     [1155, 799],
     [1138, 802],
     [1139, 792],
     [1156, 787],
     [1157, 782],
     [1156, 772],
     [1143, 760],
     [1144, 717],
     [1099, 672],
     [1081, 676],
     [1066, 661]
    ]
   ]
  },
  {
   "color": "#9fc24cff",
   "pois": [{"id": "centroid", "point": [1038, 762]}],
   "box": [965, 671, 1117, 879],
   "polygons": [
    [
     [1016, 672],
     [971, 681],
     [969, 700],
     [965, 702],
     [966, 708],
     [1014, 764],
     [1015, 774],
     [1019, 778],
     [1018, 844],
     [1019, 836],
     [1040, 863],
     [1053, 873],
     [1054, 879],
     [1086, 869],
     [1080, 867],
     [1070, 873],
     [1065, 870],
     [1066, 864],
     [1058, 857],
     [1060, 855],
     [1056, 853],
     [1058, 850],
     [1055, 848],
     [1057, 845],
     [1062, 847],
     [1056, 843],
     [1057, 838],
     [1068, 837],
     [1064, 832],
     [1074, 818],
     [1080, 820],
     [1085, 813],
     [1092, 819],
     [1095, 817],
     [1095, 754],
     [1018, 671]
    ],
    [[1108, 853], [1108, 861], [1098, 865], [1099, 867], [1117, 862], [1108, 852]],
    [[1093, 856], [1101, 857], [1093, 855]]
   ]
  },
  {
   "color": "#9fbbd7ff",
   "pois": [{"id": "centroid", "point": [971, 797]}],
   "box": [891, 702, 1050, 911],
   "polygons": [
    [[1003, 827], [994, 829], [994, 842], [1008, 838], [1009, 830], [1005, 826]],
    [
     [960, 703],
     [891, 718],
     [949, 791],
     [950, 861],
     [955, 867],
     [957, 865],
     [962, 869],
     [966, 866],
     [971, 869],
     [967, 875],
     [962, 875],
     [967, 877],
     [968, 886],
     [978, 898],
     [1031, 885],
     [1030, 866],
     [1014, 846],
     [1013, 807],
     [1005, 809],
     [1002, 804],
     [994, 805],
     [995, 784],
     [1013, 779],
     [1012, 763],
     [960, 702]
    ],
    [[1033, 872], [1034, 885], [1042, 882], [1033, 871]],
    [[1046, 892], [1033, 895], [1043, 896], [1050, 893], [1049, 891]],
    [[1029, 896], [977, 909], [978, 911], [1020, 900], [1031, 897], [1030, 895]]
   ]
  },
  {
   "color": "#2af6ccff",
   "pois": [{"id": "centroid", "point": [890, 802]}],
   "box": [820, 718, 961, 930],
   "polygons": [
    [
     [886, 719],
     [820, 733],
     [823, 809],
     [832, 822],
     [829, 814],
     [829, 763],
     [866, 814],
     [895, 808],
     [898, 820],
     [894, 835],
     [892, 830],
     [878, 834],
     [875, 840],
     [867, 842],
     [869, 873],
     [901, 918],
     [915, 914],
     [918, 910],
     [913, 905],
     [916, 901],
     [913, 900],
     [914, 895],
     [897, 884],
     [898, 879],
     [911, 882],
     [915, 886],
     [924, 884],
     [922, 878],
     [919, 879],
     [912, 874],
     [919, 866],
     [925, 865],
     [929, 859],
     [937, 862],
     [939, 859],
     [944, 865],
     [948, 863],
     [947, 790],
     [891, 718]
    ],
    [[835, 827], [841, 835], [835, 826]],
    [[957, 888], [957, 891], [961, 890], [959, 886], [957, 887]],
    [[920, 896], [918, 899], [923, 896]],
    [[918, 924], [901, 929], [907, 930], [920, 923]]
   ]
  },
  {
   "color": "#84d426ff",
   "pois": [{"id": "centroid", "point": [724, 919]}],
   "box": [625, 840, 869, 1006],
   "polygons": [
    [[634, 932], [636, 942], [654, 979], [654, 968], [634, 931]],
    [
     [757, 841],
     [625, 872],
     [633, 953],
     [658, 1006],
     [755, 980],
     [753, 923],
     [771, 946],
     [770, 949],
     [763, 949],
     [762, 939],
     [755, 934],
     [759, 980],
     [800, 969],
     [798, 964],
     [805, 954],
     [800, 950],
     [805, 946],
     [802, 944],
     [803, 941],
     [813, 939],
     [808, 935],
     [809, 932],
     [815, 932],
     [819, 939],
     [821, 934],
     [827, 933],
     [826, 928],
     [829, 925],
     [834, 926],
     [833, 931],
     [835, 927],
     [847, 931],
     [869, 925],
     [853, 900],
     [813, 910],
     [811, 883],
     [794, 855],
     [771, 857],
     [761, 840]
    ]
   ]
  }
 ],
 [
  {
   "color": "#c0ed3aff",
   "pois": [{"id": "centroid", "point": [919, 761]}],
   "box": [880, 660, 957, 858],
   "polygons": [
    [
     [906, 661],
     [904, 677],
     [885, 678],
     [884, 682],
     [881, 719],
     [880, 858],
     [942, 857],
     [944, 850],
     [949, 857],
     [949, 853],
     [956, 850],
     [957, 661],
     [908, 660]
    ]
   ]
  },
  {
   "color": "#e4efcbff",
   "pois": [{"id": "centroid", "point": [838, 759]}],
   "box": [798, 660, 880, 858],
   "polygons": [
    [
     [811, 661],
     [798, 745],
     [799, 802],
     [802, 820],
     [800, 857],
     [846, 857],
     [845, 849],
     [840, 848],
     [847, 824],
     [850, 858],
     [875, 857],
     [877, 832],
     [876, 809],
     [849, 810],
     [848, 822],
     [849, 748],
     [850, 798],
     [877, 797],
     [878, 790],
     [880, 677],
     [863, 676],
     [862, 660]
    ]
   ]
  },
  {
   "color": "#9fc24cff",
   "pois": [{"id": "centroid", "point": [764, 749]}],
   "box": [720, 660, 809, 858],
   "polygons": [
    [
     [758, 661],
     [755, 676],
     [735, 677],
     [727, 713],
     [725, 725],
     [728, 766],
     [724, 783],
     [726, 811],
     [720, 858],
     [744, 856],
     [741, 854],
     [742, 845],
     [738, 844],
     [739, 838],
     [735, 837],
     [738, 834],
     [735, 831],
     [736, 826],
     [733, 825],
     [734, 820],
     [744, 820],
     [743, 815],
     [750, 815],
     [757, 805],
     [760, 810],
     [761, 807],
     [770, 807],
     [772, 804],
     [778, 808],
     [778, 817],
     [783, 822],
     [785, 819],
     [789, 820],
     [788, 826],
     [782, 827],
     [789, 829],
     [783, 838],
     [786, 840],
     [778, 843],
     [775, 848],
     [777, 853],
     [772, 857],
     [795, 856],
     [800, 823],
     [798, 747],
     [809, 660]
    ],
    [[748, 856], [745, 857], [749, 855]],
    [[753, 855], [751, 856], [759, 856], [755, 855]]
   ]
  },
  {
   "color": "#9fbbd7ff",
   "pois": [{"id": "centroid", "point": [681, 766]}],
   "box": [633, 676, 731, 872],
   "polygons": [
    [[692, 800], [691, 813], [710, 812], [709, 800]],
    [
     [658, 677],
     [637, 750],
     [645, 826],
     [638, 855],
     [639, 858],
     [655, 857],
     [652, 833],
     [650, 848],
     [644, 847],
     [654, 823],
     [658, 858],
     [714, 857],
     [721, 824],
     [718, 783],
     [709, 782],
     [707, 778],
     [692, 779],
     [691, 783],
     [688, 760],
     [689, 756],
     [720, 753],
     [731, 697],
     [731, 683],
     [729, 677],
     [658, 676]
    ],
    [[635, 868], [633, 869], [655, 868]],
    [[660, 868], [658, 869], [709, 872], [710, 868]]
   ]
  },
  {
   "color": "#2af6ccff",
   "pois": [{"id": "centroid", "point": [607, 753]}],
   "box": [563, 676, 656, 869],
   "polygons": [
    [
     [584, 677],
     [563, 740],
     [565, 756],
     [589, 757],
     [591, 773],
     [590, 778],
     [566, 783],
     [569, 812],
     [563, 840],
     [576, 838],
     [576, 845],
     [580, 843],
     [581, 846],
     [588, 848],
     [588, 840],
     [581, 838],
     [578, 828],
     [581, 824],
     [592, 831],
     [594, 825],
     [590, 820],
     [591, 815],
     [600, 817],
     [602, 811],
     [607, 814],
     [616, 813],
     [622, 828],
     [628, 823],
     [638, 827],
     [642, 823],
     [636, 752],
     [656, 680],
     [655, 676],
     [630, 676]
    ],
    [[638, 833], [634, 833], [635, 837], [639, 835], [638, 832]],
    [
     [624, 840],
     [623, 843],
     [617, 842],
     [616, 845],
     [626, 847],
     [625, 852],
     [633, 856],
     [635, 842],
     [624, 839]
    ],
    [[607, 849], [585, 851], [584, 855], [593, 855], [592, 858], [611, 857], [608, 848]],
    [[596, 868], [591, 868], [632, 869], [597, 867]]
   ]
  },
  {
   "color": "#84d426ff",
   "pois": [{"id": "centroid", "point": [394, 817]}],
   "box": [301, 755, 551, 881],
   "polygons": [
    [
     [399, 756],
     [324, 758],
     [301, 802],
     [314, 881],
     [376, 880],
     [368, 831],
     [362, 837],
     [360, 847],
     [354, 846],
     [369, 822],
     [379, 881],
     [417, 880],
     [411, 878],
     [414, 873],
     [411, 872],
     [414, 868],
     [411, 867],
     [414, 864],
     [411, 863],
     [419, 856],
     [421, 850],
     [425, 852],
     [427, 848],
     [431, 851],
     [430, 848],
     [434, 844],
     [438, 851],
     [447, 851],
     [450, 843],
     [455, 845],
     [453, 849],
     [458, 846],
     [466, 857],
     [542, 857],
     [538, 853],
     [544, 848],
     [540, 846],
     [540, 842],
     [543, 840],
     [546, 844],
     [551, 840],
     [551, 835],
     [475, 834],
     [468, 774],
     [450, 773],
     [456, 760],
     [455, 756],
     [399, 755]
    ],
    [[442, 854], [447, 855], [442, 853]],
    [[461, 861], [461, 860]]
   ]
  }
 ]
]