// import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import HousesTableHeadCell from './HousesTableHeadCell'
import { columnsSelector } from '../../../modules/reducerHousesTable'

const useStyles = makeStyles((theme) => ({
  tableHead: {
    textTransform: 'uppercase',
    height: 40
  }
}))

const HousesTableHead = () => {
  const classes = useStyles()
  const columns = useSelector(columnsSelector)

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {columns.map((col) => (
          <HousesTableHeadCell key={col.id} colId={col.id} />
        ))}
      </TableRow>
    </TableHead>
  )
}
export default HousesTableHead
