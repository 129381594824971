import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'

import MultiSelect from '../../Common/Input/MultiSelect/MultiSelect'
import { houseOrientationSelector } from '../../../modules/reducerHouses'
import {
  orientationsSelector,
  setOrientationSelection
} from '../../../modules/reducerHousesFilter'

// const useStyles = makeStyles((theme) => ({}))

const FilterOrientationSelect = () => {
  const orientations = useSelector(houseOrientationSelector)
  const selectedOrientations = useSelector(orientationsSelector)
  // const classes = useStyles()
  const dispatch = useDispatch()

  const handleChange = useCallback(
    (selection) => {
      dispatch(setOrientationSelection(selection))
    },
    [dispatch]
  )

  return (
    <MultiSelect
      label={'Orientación'}
      emptyLabel={'Selecciona la orientación'}
      options={orientations}
      selection={selectedOrientations}
      onSelectionChange={handleChange}
    />
  )
}
export default FilterOrientationSelect
