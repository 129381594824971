import { useState, useEffect, useMemo } from 'react'
// import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Popper from '@material-ui/core/Popper'
import { useSelector } from 'react-redux'
import { useGesture } from 'react-use-gesture'

import { getSegment } from '../../modules/reducerRotatorGroups'
import {
  localPointToGlobalPoint,
  getChildTopLeft
} from '../../utils/centeredChild'
import { isPointInsideBounds } from '../../utils/mathFunctions'

const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: theme.zIndex.rotator + 1
  },
  //Todo esto es para el triangulo
  popperWithArrow: {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`
      }
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`
      }
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`
      }
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`
      }
    }
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid'
    }
  }
}))

const SegmentTooltip = ({
  parentBounds,
  boundariesRef, //Restringe donde se puede mostrar el tooltip dentro del padre
  childSize,
  segmentId,
  poiId, //Nombre del poi a usar como centro
  open, //Mostrar o no el popper
  children, //Contenido del popper
  scale, //Escala aplicada a la imagen
  translation, //Desplazamiento aplicado a la imagen
  arrow, //Si se añade el triangulo o no
  placement, //Donde se coloca el popper
  offset //Distancia al punto de referencia
}) => {
  const classes = useStyles({ arrow })
  const [arrowRef, setArrowRef] = useState(null)
  const [globalPoint, setGlobalPoint] = useState({ x: 0, y: 0 })
  const [pointVisible, setPointVisible] = useState(true)
  const gSegment = useMemo(getSegment, [])
  const segment = useSelector((state) => gSegment(state, segmentId))

  const localPoi = segment ? segment.pois.find((poi) => poi.id === poiId) : null

  useEffect(() => {
    const topLeft = getChildTopLeft(scale, translation, childSize, parentBounds)
    const gpoint = localPointToGlobalPoint(
      {
        x: localPoi ? localPoi.point[0] : 0,
        y: localPoi ? localPoi.point[1] : 0
      },
      scale,
      topLeft
    )
    setGlobalPoint(gpoint)
    if (boundariesRef.current) {
      const bBounds = {
        x: boundariesRef.current.offsetLeft,
        y: boundariesRef.current.offsetTop,
        width: boundariesRef.current.offsetWidth,
        height: boundariesRef.current.offsetHeight
      }
      setPointVisible(isPointInsideBounds(gpoint.x, gpoint.y, bBounds))
    } else {
      setPointVisible(isPointInsideBounds(gpoint.x, gpoint.y, parentBounds))
    }
  }, [parentBounds, childSize, scale, translation, localPoi, boundariesRef])

  const virtualReference = () => {
    const width = 10
    const height = 10
    return {
      clientWidth: width,
      clientHeight: height,
      getBoundingClientRect: () => {
        return {
          top: globalPoint.y - height / 2,
          left: globalPoint.x - width / 2,
          bottom: globalPoint.y + height / 2,
          right: globalPoint.x + width / 2,
          width: width,
          height: height
        }
      }
    }
  }

  const bind = useGesture({
    onPinch: ({ cancel }) => {
      cancel(true)
    }
  })

  return localPoi ? (
    <Popper
      {...bind()}
      className={`${classes.popper} ${arrow ? classes.popperWithArrow : ''}`}
      placement={placement ? placement : 'top'}
      disablePortal
      popperOptions={{ positionFixed: true }}
      open={open}
      anchorEl={virtualReference()}
      modifiers={{
        preventOverflow: {
          enabled: true,
          boundariesElement: boundariesRef.current
        },
        flip: {
          enabled: true
        },
        arrow: {
          enabled: arrow && pointVisible,
          element: arrowRef
        },
        keepTogether: {
          enabled: pointVisible
        },
        offset: {
          enabled: offset,
          offset: `${offset ? offset.x : 0}px,${offset ? offset.y : 0}px`
        }
      }}
    >
      {pointVisible && arrow ? (
        <span className={classes.arrow} ref={setArrowRef} />
      ) : null}
      {children}
    </Popper>
  ) : null
}
export default SegmentTooltip
