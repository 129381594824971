// import { useEffect } from 'react'
// import { useSelector } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'

import FilterMain from './FilterMain'
import FilterTable from './FilterTable'
import FloorSelect from './Floor/FloorSelect'

// const useStyles = makeStyles((theme) => ({}))

const FilterMenu = () => {
  // const classes = useStyles()

  return (
    <>
      {/*<FloorSelect />*/}
      <FilterMain />
      <FilterTable />
    </>
  )
}
export default FilterMenu
