import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'

import {
  getCurrentGallery,
  expandedSelector,
  setCurrentImageIdx,
  currentImageIdxSelector,
  setExpanded
} from '../../modules/reducerGallery'

import GalleryCenter from './Center/GalleryCenter'
import GalleryBar from './Bar/GalleryBar'

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    padding: ({ expanded, mobile }) =>
      expanded
        ? '0px 0px 0px 0px'
        : mobile
        ? '140px 0px 160px 0px'
        : '140px 120px 160px 200px'
  },
  contentWrapper: {
    boxSizing: 'border-box',
    padding: 8,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    backgroundColor: theme.palette.gallery.background,
    position: 'relative',
    boxShadow: `0px 2px 5px 4px rgba(0,0,0,0.40)`
  }
}))

const Gallery = ({ mobile }) => {
  const dispatch = useDispatch()
  const expanded = useSelector(expandedSelector)
  const classes = useStyles({ expanded, mobile })
  const currentGallery = useSelector(getCurrentGallery)
  const currentImgIdx = useSelector(currentImageIdxSelector)

  const imageSrcs = currentGallery
    ? currentGallery.images.map((image) => image.src)
    : []

  const handleChangeImageIdx = useCallback(
    (idx) => {
      dispatch(setCurrentImageIdx(idx))
    },
    [dispatch]
  )

  const handleExpand = useCallback(
    (expand) => {
      dispatch(setExpanded(expand))
    },
    [dispatch]
  )

  return currentGallery ? (
    <div className={classes.root}>
      <div className={classes.contentWrapper}>
        <GalleryCenter
          imagesSrcs={imageSrcs}
          currentImageIdx={currentImgIdx}
          onChangeImageIdx={handleChangeImageIdx}
          expanded={expanded}
          onChangeExpand={handleExpand}
        />
        {!mobile && (
          <GalleryBar
            imagesSrcs={imageSrcs}
            currentImageIdx={currentImgIdx}
            onChangeImageIdx={handleChangeImageIdx}
            height={65}
            aspectRatio={16 / 9}
          />
        )}
      </div>
    </div>
  ) : null
}
export default Gallery
