const HeartIcon = ({ filled, ...props }) => {
  return (
    <svg fill="none" viewBox="0 0 23 22" {...props}>
      <path
        stroke="stroke"
        d="M.979 6.304c0-3.478 2.25-5.17 4.502-5.358C7.536.758 9.787 1.98 10.277 5.082c0 .188.195.376.489.376s.49-.188.49-.376c.489-3.102 2.838-4.042 4.697-3.948 2.251.188 4.6 1.974 4.6 5.17 0 .752-.196 1.598-.49 2.443l.882.282c.293-.94.489-1.88.489-2.725 0-3.76-2.74-5.922-5.48-6.11-1.958-.188-4.21.752-5.188 3.102C9.689.852 7.34-.088 5.383.006 2.74.194 0 2.262 0 6.304c0 5.921 6.655 11.373 9.494 13.44l.587-.752C7.928 17.394.979 11.943.979 6.304z"
      />
      <path
        stroke="stroke"
        d="M16.639 9.781c-3.523 0-6.362 2.726-6.362 6.11 0 3.383 2.839 6.109 6.362 6.109s6.362-2.726 6.362-6.11c0-3.383-2.839-6.109-6.362-6.109zm0 11.279c-2.936 0-5.383-2.35-5.383-5.17s2.447-5.169 5.383-5.169 5.383 2.35 5.383 5.17-2.447 5.169-5.383 5.169z"
      />
      <path
        stroke="stroke"
        d="M19.28 15.42h-2.153v-2.067c0-.282-.196-.47-.49-.47-.293 0-.489.188-.489.47v2.068h-2.153c-.293 0-.49.188-.49.47 0 .281.197.47.49.47h2.153v2.067c0 .282.196.47.49.47.293 0 .49-.188.49-.47V16.36h2.152c.294 0 .49-.188.49-.47s-.196-.47-.49-.47z"
      />
    </svg>
  )
}
export default HeartIcon

/*

*/
