import { makeStyles } from '@material-ui/core/styles'
// import { useDispatch } from 'react-redux'
import { useGesture } from 'react-use-gesture'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'

import FilterOptionTitle from './FilterOptionTitle'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexFlow: 'column',
    position: 'relative',
    touchAction: 'none'
  },
  slider: {
    margin: '16px 6px 0px 6px',
    width: 'calc(100% - 12px)',

    '& .MuiSlider-markLabel[data-index="0"]': {
      transform: 'translateX(calc(0% - 6px))'
    },
    '& .MuiSlider-markLabel[data-index="1"]': {
      transform: 'translateX(calc(-100% + 6px))'
    }
  },
  thumb: {
    backgroundImage: theme.palette.secondary.gradientHorizontal,
    '&$focused,  &:hover': {
      boxShadow: 'none'
    }
  },
  track: {
    backgroundImage: theme.palette.secondary.gradientHorizontal
  },
  focused: {},

  markLabel: {
    top: '-16px',
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.h5.fontSize
  },
  range: {
    color: theme.palette.primary.contrastText,
    // textAlign: 'center'
    display: 'flex',
    justifyContent: 'center'
  }
}))

const FilterSlider = ({
  title,
  min,
  max,
  range,
  step,
  unit,
  onRangeChange
}) => {
  const classes = useStyles()
  // const dispatch = useDispatch()

  const bind = useGesture({
    onDragStart: () => {
      // dispatch(setDragEnabled(false))
    },
    onDragEnd: () => {
      // dispatch(setDragEnabled(true))
    }
  })

  return (
    <div {...bind()} className={classes.root}>
      <FilterOptionTitle title={title} />
      <Slider
        className={classes.slider}
        classes={{
          markLabel: classes.markLabel,
          thumbColorSecondary: classes.thumbColorSecondary,

          thumb: classes.thumb,
          track: classes.track,
          focusVisible: classes.focused
        }}
        color="secondary"
        min={min}
        max={max}
        step={step}
        marks={[
          {
            value: min,
            label: `${min} ${unit}`
          },
          {
            value: max,
            label: `${max} ${unit}`
          }
        ]}
        value={range}
        onChange={onRangeChange}
      />
      <div className={classes.range}>
        <Typography variant="h5">
          {`${range[0]} ${unit} - ${range[1]} ${unit}`}
        </Typography>
      </div>
    </div>
  )
}
export default FilterSlider
