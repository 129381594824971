const MinimizeIcon = (props) => {
  return (
    <svg fill="none" viewBox="0 0 1000 1000" {...props}>
      <path
        stroke="stroke"
        d="M944.23 12.9c-4.4 1.92-66.63 62.8-176.54 172.71L597.65 355.45V99.25l-4.79-8.23c-7.66-13.02-24.7-19.34-38.68-13.98-7.28 2.68-15.89 10.91-18.96 18.38-4.02 9.19-4.02 342.36 0 351.55 3.06 7.47 11.68 15.7 18.96 18.38 3.64 1.53 59.93 2.11 175.2 2.11 189.56 0 176.93.96 188.42-13.4 11.87-14.93 7.66-37.15-8.81-46.91l-8.23-4.79h-256.2L814.4 232.33C933.88 113.04 985.2 60.38 987.3 55.4c5.36-13.4 2.68-26.62-7.85-36.38-10.14-9.38-22.58-11.68-35.22-6.12zM95.02 535.25c-7.09 3.25-15.32 11.87-18 18.96-5.36 13.98.96 31.02 13.98 38.68l8.23 4.79h256.2L185.59 767.51C66.11 886.99 14.79 939.65 12.69 944.63c-5.36 13.4-2.68 26.61 7.85 36.38 10.15 9.38 22.02 11.49 34.85 6.32 4.98-2.11 57.64-53.42 177.12-172.9l169.84-169.84v256.2l4.79 8.23c9.77 16.47 31.98 20.68 46.91 8.81 14.36-11.49 13.4 1.15 13.4-188.42 0-115.27-.57-171.56-2.11-175.2-2.68-7.28-10.91-15.89-18.38-18.96-9-3.83-343.32-3.83-351.94 0z"
      />
    </svg>
  )
}
export default MinimizeIcon
