import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import CFSentDialog from './CFSentDialog'
import {
  setSendClicked,
  submitClickedSelector,
  getOk,
  getFormData
} from '../../modules/reducerContact'

import { settings } from '../../settings/settings'

const useStyles = makeStyles((theme) => ({
  button: {
    flex: '2 1 auto',
    margin: '16px 32px 48px 0px',
    backgroundColor: theme.palette.other.sendButton,
    color: theme.palette.primary.light2,
    backgroundImage: 'none',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      backgroundImage: theme.palette.secondary.gradientHorizontal
    }
  }
}))

const CFSendButton = () => {
  const classes = useStyles()
  const submitClicked = useSelector(submitClickedSelector)
  const ok = useSelector(getOk)
  const data = useSelector(getFormData)
  const [showDialog, setShowDialog] = useState(false)
  const dispatch = useDispatch()

  const handleCloseDialog = useCallback(() => {
    setShowDialog(false)
  }, [])
  const handleSend = useCallback(
    (value) => {
      dispatch(setSendClicked(true))

      // const formData = new FormData()
      // formData.append('contact_name', 'data.name')
      // formData.append('contact_last_name', 'data.lastName')
      // formData.append('contact_phone', 'data.phone')
      // formData.append('contact_email', 'azaweb.dev@gmail.com')
      // formData.append('contact_comments', 'data.comments')
      // formData.append('contact_message', 'Mensaje')
      //
      // fetch(
      //   'https://asde.basuravariada2.online/root/wordpress/wp-json/contact/v1/send',
      //   {
      //     method: 'POST',
      //     body: formData
      //   }
      // )
      //   .then((res) => res.json())
      //   .then((data) => console.log(data))
      //   .catch((error) => console.log(error))

      if (ok) {
        const formData = new FormData()
        formData.append('contact_subject', 'Datos cliente areaDesignDemo3D')
        formData.append('contact_email', 'azaweb.dev@gmail.com')
        let msg = 'Datos introducidos:<br>'
        msg += `Nombre: ${data.name} <br>`
        msg += `Apellidos: ${data.lastName} <br>`
        msg += `Número teléfono: ${data.phone} <br>`
        msg += `Email: ${data.email} <br>`
        msg += `Comentarios: ${data.comments} <br>`
        formData.append('contact_message', msg)

        fetch(settings.wordpress.url + settings.wordpress.sendEmail, {
          method: 'POST',
          body: formData
        })
          .then((res) => res.json())
          .then((data) => {
            console.log(data)
            setShowDialog(true)
          })
          .catch((error) => console.log(error))
      }
    },
    [dispatch, ok, data]
  )

  return (
    <>
      <Button
        className={classes.button}
        disabled={submitClicked && !ok}
        onClick={handleSend}
        disableElevation
        disableRipple
        disableFocusRipple
      >
        Enviar
      </Button>
      <CFSentDialog open={showDialog} onClose={handleCloseDialog} />
    </>
  )
}
export default CFSendButton
