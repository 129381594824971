import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

import { currentHouseSelector } from '../../../modules/reducerHouses'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

const House3DView = () => {
  const currentHouse = useSelector(currentHouseSelector)
  const classes = useStyles()

  return <div className={classes.root}>VISTA 3D DE LA CASA {currentHouse}</div>
}
export default House3DView
