import { createSelector } from 'reselect'
import { dataSelector, DATA_TYPE, MEDIA_TYPE } from './reducerRead'
import {
  currentPromocionSelector,
  getCurrentPromocion
} from './reducerPromocion'
import { galleriesSelector } from './reducerGallery'
import { documentsSelector } from './reducerDocuments'

import { findById, selectItem } from '../utils/utils'

export const HOUSES_SELECT_FAVORITE_HOUSE = 'HOUSES_SELECT_FAVORITE_HOUSE'
export const HOUSES_SET_CURRENT_HOUSE = 'SET_CURRENT_HOUSE'

const initialState = {
  favorites: [],
  currentHouse: ''

  // houseStates: [
  //   { id: 'hs0', name: 'Disponible', color: '#09ce1d' },
  //   { id: 'hs1', name: 'Bajo opción de compra', color: '#e9b707' },
  //   { id: 'hs2', name: 'Reservado', color: '#d61408' },
  //   { id: 'hs3', name: 'Vendido', color: '#5c667b' },
  //   { id: 'hs4', name: 'No disponible', color: '#5c667b' }
  // ],
  // houseTypologies: [
  //   { id: 't0', name: 'Vivienda 1 dormitorio' },
  //   { id: 't1', name: 'Vivienda 2 dormitorios' },
  //   { id: 't2', name: 'Unifamiliar adosada' },
  //   { id: 't3', name: 'Vivienda ático' }
  // ],
  // houseFloors: [
  //   { id: 'f0', name: 'Planta baja' },
  //   { id: 'f1', name: '1ª Planta' },
  //   { id: 'f2', name: '2ª Planta' },
  //   { id: 'f3', name: '3ª Planta' }
  // ],
  // houseOrientation: [
  //   { id: 'o0', name: 'Norte' },
  //   { id: 'o1', name: 'Norte/Este' },
  //   { id: 'o2', name: 'Este' },
  //   { id: 'o3', name: 'Sur/Este' },
  //   { id: 'o4', name: 'Sur' },
  //   { id: 'o5', name: 'Sur/Oeste' },
  //   { id: 'o6', name: 'Oeste' },
  //   { id: 'o7', name: 'Norte/Oeste' }
  // ],
  // houses: [
  //   {
  //     id: 'h0',
  //     img: 'imagen',
  //     name: 'House0',
  //     typology: 't0',
  //     area: 50,
  //     extraSpace: 10,
  //     orientation: 'o1',
  //     price: 60000,
  //     state: 'hs3',
  //     floor: 'f0'
  //   },
  //   {
  //     id: 'h1',
  //     img: 'imagen',
  //     name: 'House1',
  //     typology: 't1',
  //     area: 50,
  //     extraSpace: 10,
  //     orientation: 'o2',
  //     price: 70000,
  //     state: 'hs3',
  //     floor: 'f0'
  //   },
  //   {
  //     id: 'h2',
  //     img: 'imagen',
  //     name: 'House2',
  //     typology: 't2',
  //     area: 50,
  //     extraSpace: 10,
  //     orientation: 'o1',
  //     price: 55000,
  //     state: 'hs0',
  //     floor: 'f0'
  //   },
  //   {
  //     id: 'h3',
  //     img: 'imagen',
  //     name: 'House3',
  //     typology: 't3',
  //     area: 50,
  //     extraSpace: 10,
  //     orientation: 'o6',
  //     price: 100000,
  //     state: 'hs1',
  //     floor: 'f0'
  //   },
  //   {
  //     id: 'h4',
  //     img: 'imagen',
  //     name: 'House4',
  //     typology: 't0',
  //     area: 50,
  //     extraSpace: 10,
  //     orientation: 'o4',
  //     price: 99000,
  //     state: 'hs2',
  //     floor: 'f0'
  //   },
  //   {
  //     id: 'h5',
  //     img: 'imagen',
  //     name: 'House5',
  //     typology: 't1',
  //     area: 50,
  //     extraSpace: 10,
  //     orientation: 'o0',
  //     price: 106000,
  //     state: 'hs4',
  //     floor: 'f0'
  //   },
  //   {
  //     id: 'h6',
  //     img: 'imagen',
  //     name: 'House6',
  //     typology: 't2',
  //     area: 50,
  //     extraSpace: 10,
  //     orientation: 'o0',
  //     price: 100700,
  //     state: 'hs1',
  //     floor: 'f0'
  //   },
  //   {
  //     id: 'h7',
  //     img: 'imagen',
  //     name: 'House7',
  //     typology: 't3',
  //     area: 50,
  //     extraSpace: 10,
  //     orientation: 'o7',
  //     price: 14440,
  //     state: 'hs1',
  //     floor: 'f0'
  //   },
  //   {
  //     id: 'h8',
  //     img: 'imagen',
  //     name: 'House8',
  //     typology: 't3',
  //     area: 50,
  //     extraSpace: 10,
  //     orientation: 'o6',
  //     price: 100067,
  //     state: 'hs3',
  //     floor: 'f0'
  //   },
  //   {
  //     id: 'h9',
  //     img: 'imagen',
  //     name: 'House9',
  //     typology: 't2',
  //     area: 50,
  //     extraSpace: 10,
  //     orientation: 'o5',
  //     price: 140000,
  //     state: 'hs3',
  //     floor: 'f0'
  //   },
  //   {
  //     id: 'h10',
  //     img: 'imagen',
  //     name: 'House10',
  //     typology: 't2',
  //     area: 50,
  //     extraSpace: 10,
  //     orientation: 'o2',
  //     price: 140001,
  //     state: 'hs0',
  //     floor: 'f1'
  //   },
  //   {
  //     id: 'h11',
  //     img: 'imagen',
  //     name: 'House11',
  //     typology: 't1',
  //     area: 50,
  //     extraSpace: 10,
  //     orientation: 'o2',
  //     price: 87000,
  //     state: 'hs0',
  //     floor: 'f0'
  //   }
  // ],
}

const changeState = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case HOUSES_SELECT_FAVORITE_HOUSE:
      return {
        ...state,
        favorites: selectItem(state.favorites, action.id, action.select)
      }
    case HOUSES_SET_CURRENT_HOUSE:
      return { ...state, currentHouse: action.id }
    default:
      return state
  }
}
export default changeState

export const selectFavoriteHouse = (id, select) => ({
  type: HOUSES_SELECT_FAVORITE_HOUSE,
  id,
  select
})

export const setCurrentHouse = (id) => ({
  type: HOUSES_SET_CURRENT_HOUSE,
  id
})

export const toggleFavorite = (id) => (dispatch, getState) => {
  const state = getState()
  const select = favoritesSelector(state).indexOf(id) < 0
  dispatch({
    type: HOUSES_SELECT_FAVORITE_HOUSE,
    id,
    select
  })
}

// export const houseStatesSelector = (state) => state.houses.houseStates
// export const houseTypologiesSelector = (state) => state.houses.houseTypologies
// export const houseFloorsSelector = (state) => state.houses.houseFloors
// export const houseOrientationSelector = (state) => state.houses.houseOrientation
// export const housesSelector = (state) => state.houses.houses

const getChildrenElements = (promocion, taxonomy) => {
  if (!promocion || !taxonomy) {
    return []
  }
  const root = taxonomy.data.find((t) => t.name === promocion.name)
  return taxonomy.data.filter((t) => t.parent === root.id)
}

export const houseTypologiesSelector = createSelector(
  [dataSelector, getCurrentPromocion],
  (data, promocion) => {
    const typologies = getChildrenElements(
      promocion,
      findById(data, DATA_TYPE.HOUSE_TYPE)
    )

    return typologies.map((typo) => {
      return {
        id: DATA_TYPE.HOUSE_TYPE + typo.id,
        name: typo.name,
        area: parseFloat(typo.acf.superficie),
        aditionalArea: parseFloat(typo.acf.espacio_adicional),
        rooms: parseInt(typo.acf.habitaciones),
        baths: parseInt(typo.acf.banos),
        nFloors: parseInt(typo.acf.numero_de_plantas),
        parking: typo.acf.garaje,
        areaParking: typo.acf.garaje
          ? parseFloat(typo.acf.superficie_garaje)
          : 0,
        terrace: typo.acf.terraza,
        areaTerrace: typo.acf.terraza
          ? parseFloat(typo.acf.superficie_terraza)
          : 0
      }
    })
  }
)

export const houseStatesSelector = createSelector(
  [dataSelector, getCurrentPromocion],
  (data, promocion) => {
    const states = getChildrenElements(
      promocion,
      findById(data, DATA_TYPE.HOUSE_STATE)
    )

    return states.map((hs) => {
      return {
        id: DATA_TYPE.HOUSE_STATE + hs.id,
        name: hs.name,
        color: hs.acf.color
      }
    })
  }
)

const compareFloorLevel = (a, b) => {
  if (a.level < b.level) {
    return -1
  }
  if (a.level > b.level) {
    return 1
  }
  return 0
}

export const houseFloorsSelector = createSelector(
  [dataSelector, getCurrentPromocion],
  (data, promocion) => {
    let houseFloors = getChildrenElements(
      promocion,
      findById(data, DATA_TYPE.HOUSE_FLOOR)
    )

    houseFloors = houseFloors.map((hf) => {
      return {
        id: DATA_TYPE.HOUSE_FLOOR + hf.id,
        name: hf.name,
        level: parseInt(hf.acf.nivel)
      }
    })
    return houseFloors.sort(compareFloorLevel)
  }
)

export const getHouseFloorsDescOrder = createSelector(
  [houseFloorsSelector],
  (floors) => {
    return floors.slice(0).reverse()
  }
)

export const houseOrientationSelector = createSelector(
  [dataSelector, getCurrentPromocion],
  (data, promocion) => {
    const orientations = getChildrenElements(
      promocion,
      findById(data, DATA_TYPE.ORIENTATION)
    )

    return orientations.map((o) => {
      return {
        id: DATA_TYPE.ORIENTATION + o.id,
        name: o.name
      }
    })
  }
)

//Devuelve casa promociona actual
export const housesSelector = createSelector(
  [
    dataSelector,
    currentPromocionSelector,
    houseStatesSelector,
    houseTypologiesSelector,
    houseOrientationSelector,
    houseFloorsSelector,
    galleriesSelector,
    documentsSelector
  ],
  (
    data,
    promocion,
    states,
    typologies,
    orientations,
    floors,
    galleries,
    docs
  ) => {
    const allHouses = findById(data, DATA_TYPE.HOUSE)
    if (!allHouses) {
      return []
    }
    const houses = allHouses.data.filter(
      (house) =>
        DATA_TYPE.PROMOCION + house.acf.promocion_vivienda === promocion
    )

    const media = findById(data, DATA_TYPE.MEDIA)
    const finalHouses = houses.map((h) => {
      let documents = h.acf.pdfs_relation ? h.acf.pdfs_relation : []
      documents = documents.map((docId) => MEDIA_TYPE.PDF + docId)
      documents = documents.filter((docId) => findById(docs, docId)) //TODO: Ya tienes los docs buscados guardalos
      const img = media
        ? media.data.find((m) => m.id === h.featured_media)
        : null
      const galleryObject = findById(
        galleries,
        h.acf.galeria ? DATA_TYPE.GALLERY + h.acf.galeria : ''
      )
      return {
        id: DATA_TYPE.HOUSE + h.id,
        name: h.title.rendered,
        description: h.acf.descripcion ? h.acf.descripcion : '',
        img: img ? img.source_url : '',
        imgObject: img,
        typology: h.tipo_vivienda[0]
          ? DATA_TYPE.HOUSE_TYPE + h.tipo_vivienda[0]
          : '',
        state: h.estado_vivienda[0]
          ? DATA_TYPE.HOUSE_STATE + h.estado_vivienda[0]
          : '',
        floor: h.planta[0] ? DATA_TYPE.HOUSE_FLOOR + h.planta[0] : '',
        price: h.acf.precio ? parseInt(h.acf.precio) : 0,
        gallery: galleryObject ? galleryObject.id : '',
        galleryObject,
        documents,
        orientation: h.orientacion[0]
          ? DATA_TYPE.ORIENTATION + h.orientacion[0]
          : ''
      }
    })

    return finalHouses.reduce((acc, house) => {
      const stateObject = findById(states, house.state)
      const typologyObject = findById(typologies, house.typology)
      const orientationObject = findById(orientations, house.orientation)
      const floorsObject = findById(floors, house.floor)
      if (stateObject && typologyObject && orientationObject && floorsObject) {
        acc.push({
          ...house,
          stateObject,
          typologyObject,
          orientationObject,
          floorsObject
        })
      }
      return acc
    }, [])
  }
)

export const favoritesSelector = (state) => state.houses.favorites
export const currentHouseSelector = (state) => state.houses.currentHouse

export const getHouse = () =>
  createSelector([housesSelector, (_, id) => id], (houses, id) => {
    return findById(houses, id)
  })

export const getCurrentHouse = createSelector(
  [housesSelector, currentHouseSelector],
  (houses, current) => {
    return findById(houses, current)
  }
)

export const getOrientation = () =>
  createSelector(
    [houseOrientationSelector, (_, id) => id],
    (orientations, id) => {
      return findById(orientations, id)
    }
  )

export const getState = () =>
  createSelector([houseStatesSelector, (_, id) => id], (houseStates, id) => {
    return findById(houseStates, id)
  })

export const getTypology = () =>
  createSelector([houseTypologiesSelector, (_, id) => id], (typologies, id) => {
    return findById(typologies, id)
  })

export const getHouseDetails = () =>
  createSelector(
    [
      housesSelector,
      houseOrientationSelector,
      houseTypologiesSelector,
      favoritesSelector,
      getCurrentPromocion,
      (_, id) => id
    ],
    (houses, houseOrientation, typologies, favorites, promocion, id) => {
      const house = findById(houses, id)
      if (!house) {
        return { house: null, favorite: false, details: [] }
      }
      const areaUnit = promocion ? promocion.areaUnit : ''
      return {
        house,
        favorite: favorites.indexOf(house.id) >= 0,
        details: house
          ? [
              ['Tipología', house.typologyObject.name],
              ['Orientación', house.orientationObject.name],
              ['Habitaciones', house.typologyObject.rooms],
              ['Superficie', house.typologyObject.area + ' ' + areaUnit],
              ['Baños/Aseos', house.typologyObject.baths],
              ['Terraza', house.typologyObject.terrace ? 'Si' : 'No'],
              ['Parking', house.typologyObject.parking ? 'Si' : 'No']
            ]
          : []
      }
    }
  )

export const getPriceRange = createSelector([housesSelector], (houses) => {
  return houses.reduce(
    (acc, house) => {
      if (house.price > acc[1]) {
        acc[1] = house.price
      }
      if (house.price < acc[0]) {
        acc[0] = house.price
      }
      return acc
    },
    houses.length > 0 ? [houses[0].price, houses[0].price] : [0, 0]
  )
})

export const getAreaRange = createSelector(
  [houseTypologiesSelector],
  (typologies) => {
    return typologies.reduce(
      (acc, typo) => {
        if (typo.area > acc[1]) {
          acc[1] = typo.area
        }
        if (typo.area < acc[0]) {
          acc[0] = typo.area
        }
        return acc
      },
      typologies.length > 0 ? [typologies[0].area, typologies[0].area] : [0, 0]
    )
  }
)
