import { createSelector } from 'reselect'
import { findById, selectItem } from '../utils/utils'
import {
  housesSelector,
  houseTypologiesSelector,
  houseFloorsSelector,
  houseStatesSelector,
  // favoritesSelector,
  getPriceRange,
  getAreaRange
} from './reducerHouses'

export const HOUSE_FILTER_SET_FLOOR = 'HOUSE_FILTER_SET_FLOOR'
export const HOUSE_FILTER_SELECT_TYPOLOGY = 'HOUSE_FILTER_SELECT_TYPOLOGY'
export const HOUSE_FILTER_SET_TYPOLOGY_SELECTION =
  'HOUSE_FILTER_SET_TYPOLOGY_SELECTION'
export const HOUSE_FILTER_SELECT_ORIENTATION = 'HOUSE_FILTER_SELECT_ORIENTATION'
export const HOUSE_FILTER_SET_ORIENTATION_SELECTION =
  'HOUSE_FILTER_SET_ORIENTATION_SELECTION'
export const HOUSE_FILTER_SET_PRICE_RANGE = 'HOUSE_FILTER_SET_PRICE_RANGE'
export const HOUSE_FILTER_SET_AREA_RANGE = 'HOUSE_FILTER_SET_AREA_RANGE'
export const HOUSE_FILTER_SET_STATE_SELECTION =
  'HOUSE_FILTER_SET_STATE_SELECTION'
export const HOUSE_FILTER_SELECT_ROOM_OPTION = 'HOUSE_FILTER_SELECT_ROOM_OPTION'
export const HOUSE_FILTER_RESET_FILTER = 'HOUSE_FILTER_RESET_FILTER'

export const ROOM_OPTIONS = [
  { id: 'ro0', name: '1', range: [1, 1] },
  { id: 'ro1', name: '2', range: [2, 2] },
  { id: 'ro2', name: '3', range: [3, 3] },
  { id: 'ro3', name: '4', range: [4, 4] },
  { id: 'ro4', name: '+5', range: [5, Number.MAX_VALUE] }
]
const initialState = {
  floor: '',
  typologies: [],
  orientations: [],
  roomOptions: [],
  priceRange: [Number.MIN_VALUE, Number.MAX_VALUE], //[min,max]
  areaRange: [Number.MIN_VALUE, Number.MAX_VALUE] //[min,max]
}

const changeState = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case HOUSE_FILTER_SET_FLOOR:
      return { ...state, floor: action.id }
    case HOUSE_FILTER_SET_TYPOLOGY_SELECTION:
      return { ...state, typologies: action.selection }
    case HOUSE_FILTER_SET_STATE_SELECTION:
      return { ...state, houseStates: action.selection }
    case HOUSE_FILTER_SET_ORIENTATION_SELECTION:
      return { ...state, orientations: action.selection }

    case HOUSE_FILTER_SELECT_TYPOLOGY: {
      return {
        ...state,
        typologies: selectItem(state.typologies, action.id, action.select)
      }
    }
    case HOUSE_FILTER_SELECT_ORIENTATION:
      return {
        ...state,
        orientations: selectItem(state.orientations, action.id, action.select)
      }
    case HOUSE_FILTER_SELECT_ROOM_OPTION:
      return {
        ...state,
        roomOptions: selectItem(state.roomOptions, action.id, action.select)
      }
    case HOUSE_FILTER_SET_PRICE_RANGE:
      return { ...state, priceRange: action.range }
    case HOUSE_FILTER_SET_AREA_RANGE:
      return { ...state, areaRange: action.range }
    case HOUSE_FILTER_RESET_FILTER:
      return {
        ...state,
        typologies: [],
        orientations: [],
        roomOptions: [],
        priceRange: [Number.MIN_VALUE, Number.MAX_VALUE],
        areaRange: [Number.MIN_VALUE, Number.MAX_VALUE]
      }
    default:
      return state
  }
}
export default changeState

export const setFloor = (id) => ({
  type: HOUSE_FILTER_SET_FLOOR,
  id
})

export const selectTypology = (id, select) => ({
  type: HOUSE_FILTER_SELECT_TYPOLOGY,
  id,
  select
})

export const setTypologySelection = (selection) => ({
  type: HOUSE_FILTER_SET_TYPOLOGY_SELECTION,
  selection
})

export const setPriceRange = (range) => ({
  type: HOUSE_FILTER_SET_PRICE_RANGE,
  range
})

export const setAreaRange = (range) => ({
  type: HOUSE_FILTER_SET_AREA_RANGE,
  range
})

export const selectOrientation = (id, select) => ({
  type: HOUSE_FILTER_SELECT_ORIENTATION,
  id,
  select
})
export const setOrientationSelection = (selection) => ({
  type: HOUSE_FILTER_SET_ORIENTATION_SELECTION,
  selection
})
export const setHouseStateSelection = (selection) => ({
  type: HOUSE_FILTER_SET_STATE_SELECTION,
  selection
})
export const selectRoomOption = (id, select) => ({
  type: HOUSE_FILTER_SELECT_ROOM_OPTION,
  id,
  select
})
export const resetFilter = () => ({
  type: HOUSE_FILTER_RESET_FILTER
})

export const floorSelector = (state) => state.filter.floor
export const typologiesSelector = (state) => state.filter.typologies
export const orientationsSelector = (state) => state.filter.orientations
export const priceRangeSelector = (state) => state.filter.priceRange
export const areaRangeSelector = (state) => state.filter.areaRange
export const roomOptionsSelector = (state) => state.filter.roomOptions

export const getCurrentFloor = createSelector(
  [floorSelector, houseFloorsSelector],
  (floor, floors) => {
    return findById(floors, floor)
  }
)

const roomFilter = (typo, roomOptionsSelection) => {
  if (!roomOptionsSelection || !typo) {
    return false
  }
  if (roomOptionsSelection.length === 0) {
    return true
  }
  for (let roId of roomOptionsSelection) {
    const ro = findById(ROOM_OPTIONS, roId)
    if (ro) {
      if (typo.rooms >= ro.range[0] && typo.rooms <= ro.range[1]) {
        return true
      }
    }
  }
  return false
}

export const getFilterHouses = createSelector(
  [
    housesSelector,
    floorSelector,
    typologiesSelector,
    priceRangeSelector,
    areaRangeSelector,
    orientationsSelector,
    roomOptionsSelector,
    houseTypologiesSelector
  ],
  (
    houses,
    floor,
    typologies,
    priceRange,
    areaRange,
    orientations,
    roomOptions,
    houseTypologies
  ) => {
    return houses.filter((house) => {
      return house.typologyObject
        ? (floor === '' || house.floor === floor) &&
            (typologies.length === 0 ||
              typologies.indexOf(house.typology) >= 0) &&
            (orientations.length === 0 ||
              orientations.indexOf(house.orientation) >= 0) &&
            house.price >= priceRange[0] &&
            house.price <= priceRange[1] &&
            house.typologyObject.area >= areaRange[0] &&
            house.typologyObject.area <= areaRange[1] &&
            roomFilter(house.typologyObject, roomOptions)
        : false
    })
  }
)

export const getClassifyHousesByTypology = createSelector(
  [housesSelector, houseTypologiesSelector],
  (houses, typologies) => {
    const values = typologies.map((typo) => {
      return { typologyId: typo.id, housesId: [] }
    })
    houses.reduce((acc, house) => {
      const typo = values.find((v) => v.typologyId === house.typology)
      if (typo) {
        typo.housesId.push(house.id)
      }
      return acc
    }, values)
    return values
  }
)

export const getClassifyHousesByFloor = createSelector(
  [housesSelector, houseFloorsSelector],
  (houses, floors) => {
    const values = floors.map((floor) => {
      return { floorId: floor.id, housesId: [] }
    })
    houses.reduce((acc, house) => {
      const typo = values.find((v) => v.floorId === house.floor)
      if (typo) {
        typo.housesId.push(house.id)
      }
      return acc
    }, values)
    return values
  }
)

export const getClassifyHousesByState = createSelector(
  [housesSelector, houseStatesSelector],
  (houses, states) => {
    const values = states.map((state) => {
      return { stateId: state.id, housesId: [] }
    })
    houses.reduce((acc, house) => {
      const state = values.find((v) => v.stateId === house.state)
      if (state) {
        state.housesId.push(house.id)
      }
      return acc
    }, values)
    return values
  }
)

export const getSliderPriceRange = createSelector(
  [getPriceRange],
  (priceRange) => {
    return [Math.max(0, priceRange[0] - 10000), priceRange[1] + 10000]
  }
)

export const getSliderAreaRange = createSelector(
  [getAreaRange],
  (areaRange) => {
    return [Math.max(0, areaRange[0] - 10), areaRange[1] + 10]
  }
)
