import { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

import HexagonIcon from '../Icons/HexagonIcon'
import { getHouse, getState } from '../../modules/reducerHouses'

const useStyles = makeStyles((theme) => ({
  icon: {
    width: ({ width }) => width,
    height: ({ width }) => width,
    fill: ({ fill }) => fill
  }
}))

const StateIcon = ({ houseId, width }) => {
  const gHouse = useMemo(getHouse, [])
  const house = useSelector((state) => gHouse(state, houseId))
  const gState = useMemo(getState, [])
  const state = useSelector((state) => gState(state, house ? house.state : ''))
  const classes = useStyles({ width, fill: state ? state.color : 'black' })

  return <HexagonIcon className={classes.icon} />
}
export default StateIcon
