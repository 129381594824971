import { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import AddDownloadButton from '../Downloads/AddDownloadButton'
import RemoveDownloadButton from '../Downloads/RemoveDownloadButton'
import {
  setCurrentDocument,
  currentDocumentSelector
} from '../../modules/reducerDocuments'

const useStyles = makeStyles((theme) => ({
  root: {},
  listItem: {
    display: 'flex',
    flexFlow: 'row',
    cursor: 'pointer',
    height: '32px',
    color: ({ selected }) => (selected ? theme.palette.secondary.main : ''),
    '&:hover': { color: theme.palette.secondary.main }
  },
  itemText: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  itemIcon: { minWidth: 0, paddingRight: 4, color: 'inherit' },
  buttonsWrapper: { display: 'flex', flexFlow: 'row', height: '100%' }
}))

const DocumentItem = ({ id, name }) => {
  const [hover, setHover] = useState(false)
  const currentDocument = useSelector(currentDocumentSelector)
  const selected = id === currentDocument || hover
  const classes = useStyles({ selected })
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    dispatch(setCurrentDocument(id))
  }, [dispatch, id])

  const handleMouseOver = useCallback(() => {
    setHover(true)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setHover(false)
  }, [])

  return (
    <div onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
      <ListItem
        className={classes.listItem}
        role={undefined}
        dense
        disableGutters
        onClick={handleClick}
      >
        <ListItemIcon className={classes.itemIcon}>
          {selected ? (
            <RadioButtonCheckedIcon disableRipple style={{ fontSize: 16 }} />
          ) : (
            <RadioButtonUncheckedIcon disableRipple style={{ fontSize: 16 }} />
          )}
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classes.itemText,
            secondary: classes.itemText
          }}
          primary={name}
          primaryTypographyProps={{ variant: 'h4' }}
        />
        <div className={classes.buttonsWrapper}>
          <AddDownloadButton ids={[id]} width={20} />
          <RemoveDownloadButton ids={[id]} width={18} />
        </div>
      </ListItem>
    </div>
  )
}

export default DocumentItem
