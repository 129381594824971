import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import ThreeDRotationIcon from '@material-ui/icons/ThreeDRotation'

import ImageIcon from '../../Icons/ImageIcon'

import {
  houseDetails3dModeSelector,
  setView3DMode
} from '../../../modules/reducerMain'

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.primary.contrastText,
    padding: '0 4px',
    '&:hover': { color: theme.palette.secondary.main }
  }
}))

const HouseDetailsGalleryButton = ({ houseId }) => {
  const houseDetails3dMode = useSelector(houseDetails3dModeSelector)
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    dispatch(setView3DMode(!houseDetails3dMode))
  }, [dispatch, houseDetails3dMode])

  return (
    <Tooltip title={houseDetails3dMode ? 'Galería' : 'Vista 3D'} arrow>
      <IconButton className={classes.button} onClick={handleClick}>
        {houseDetails3dMode ? (
          <ImageIcon height={20} stroke={'currentColor'} />
        ) : (
          <ThreeDRotationIcon />
        )}
      </IconButton>
    </Tooltip>
  )
}
export default HouseDetailsGalleryButton
