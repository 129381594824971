import React, { useCallback, useState, useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { Document, Page } from 'react-pdf'
import { pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'
import mergeRefs from 'react-merge-refs'
import { useGesture } from 'react-use-gesture'

import PDFBar from './PDFBar'

import {
  urlSelector,
  currentPageIdxSelector,
  scaleSelector,
  rotationSelector,
  isPortrait,
  setContainerSize,
  pageSizeSelector,
  setPageSize,
  setPageCount,
  setPageIdx,
  pageCountSelector
} from '../../modules/reducerPDFViewer'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.pdf.background,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexFlow: 'column',

    position: 'relative'
  },

  documentWrapper: {
    touchAction: 'none',
    width: '100%',
    height: '100%',
    display: 'flex', //Solo este
    overflowY: 'auto',
    overflowX: 'auto'
  },
  document: {
    padding: 8,
    margin: 'auto',
    cursor: ({ panning }) => (panning ? 'grabbing' : 'grab')
  },
  page: {
    boxShadow: `0px 2px 5px 4px rgba(0,0,0,0.40)`
  },
  bar: { width: '100%', height: '50px' }
}))

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
const MIN_MOV = 10

const PDFViewer = () => {
  const [rootBoundsRef, rootBounds] = useMeasure({ polyfill: ResizeObserver })
  const rootRef = useRef(null)

  const url = useSelector(urlSelector)
  const currentPageIdx = useSelector(currentPageIdxSelector)
  const scale = useSelector(scaleSelector)
  const rotation = useSelector(rotationSelector)
  const [panning, setPanning] = useState(false)
  const [scrollToEnd, setScrollToEnd] = useState(false)
  const [dragCanceled, setDragCanceled] = useState(false)
  const pageSize = useSelector(pageSizeSelector)
  const pageCount = useSelector(pageCountSelector)
  const portrait = useSelector(isPortrait)
  const classes = useStyles({ panning })
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      setContainerSize({
        width: rootBounds.width - 25, //Para poner un margen con el padre
        height: rootBounds.height - 25
      })
    )
  }, [dispatch, rootBounds.width, rootBounds.height])

  const handlePageLoadSuccess = useCallback(
    (page) => {
      dispatch(
        setPageSize({
          width: page.originalWidth,
          height: page.originalHeight
        })
      )
      rootRef.current.scrollTop = scrollToEnd ? rootRef.current.scrollHeight : 0
      setScrollToEnd(false)
    },
    [dispatch, scrollToEnd]
  )

  const next = useCallback(() => {
    dispatch(setPageIdx(currentPageIdx + 1))
  }, [dispatch, currentPageIdx])

  const prev = useCallback(() => {
    dispatch(setPageIdx(currentPageIdx - 1))
  }, [dispatch, currentPageIdx])

  const handleDocumentLoadSuccess = useCallback(
    (pdf) => {
      dispatch(setPageCount(pdf.numPages))
    },
    [dispatch]
  )

  const handleError = useCallback(
    (e) => {
      console.log(e)
      dispatch(setPageCount(0))
    },
    [dispatch]
  )

  const bind = useGesture({
    onDragStart: () => {
      setPanning(true)
    },
    onDragEnd: () => setPanning(false),
    onDrag: ({ delta, movement, cancel, canceled }) => {
      if (canceled) {
        return
      }
      if (rootRef.current) {
        rootRef.current.scrollLeft -= delta[0]
        rootRef.current.scrollTop -= delta[1]
        if (Math.ceil(rootBounds.height) >= rootRef.current.scrollHeight) {
          //Pagina completa
          if (movement[1] < -3 * MIN_MOV) {
            next()
            cancel()
          } else if (movement[1] > 3 * MIN_MOV) {
            setScrollToEnd(true)
            prev()
            cancel()
          }
        } else if (
          //No se usa rootBounds.height porque incluye la barra inferior si esta visible
          Math.ceil(rootRef.current.clientHeight) + rootRef.current.scrollTop >=
          rootRef.current.scrollHeight
        ) {
          //Limite inferior
          if (movement[1] < -MIN_MOV) {
            if (dragCanceled) {
              //Si es la segunda vez que se llega al final cambiar de pagina
              next()
              cancel()
            } else {
              //La primera vez que se llega al final se cancela
              setDragCanceled(true)
              setPanning(false)
              cancel()
            }
          }
        } else if (rootRef.current.scrollTop === 0) {
          //Limite superior
          if (movement[1] > MIN_MOV) {
            if (dragCanceled) {
              //Si es la segunda vez que se llega al principio cambiar de pagina
              setScrollToEnd(true)
              prev()
              cancel()
            } else {
              setDragCanceled(true)
              setPanning(false)
              cancel()
            }
          }
        } else {
          //Movimiento
          setDragCanceled(false)
        }
      }
    },
    onWheelStart: ({ delta }) => {
      setDragCanceled(false)
      if (delta[1] > 0) {
        if (
          Math.ceil(rootBounds.height) + rootRef.current.scrollTop >=
          rootRef.current.scrollHeight
        ) {
          next()
        }
      } else {
        if (rootRef.current.scrollTop === 0) {
          setScrollToEnd(true)
          prev()
        }
      }
    }
  })

  return (
    <div className={classes.root}>
      <div
        {...bind()}
        className={classes.documentWrapper}
        ref={mergeRefs([rootRef, rootBoundsRef])}
      >
        <Document
          className={classes.document}
          file={url}
          error="Error"
          loading="Loading"
          noData=""
          onLoadSuccess={handleDocumentLoadSuccess}
          onLoadError={handleError}
          onSourceError={handleError}
          externalLinkTarget={'_blank'}
          rotate={rotation}
        >
          {currentPageIdx >= 0 ? (
            <Page
              className={classes.page}
              pageIndex={currentPageIdx}
              width={portrait ? pageSize.width : pageSize.height}
              scale={scale}
              renderAnnotationLayer={true}
              renderTextLayer={false}
              onLoadSuccess={handlePageLoadSuccess}
            />
          ) : null}
        </Document>
      </div>
      {pageCount > 0 ? (
        <PDFBar small={rootBounds && rootBounds.width < 600} />
      ) : null}
    </div>
  )
}
export default PDFViewer
