import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import {
  roomOptionsSelector,
  selectRoomOption
} from '../../../modules/reducerHousesFilter'

const useStyles = makeStyles((theme) => ({
  option: {
    margin: '0 4px',
    width: 36,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: ({ selected }) =>
      selected
        ? theme.palette.primary.light
        : theme.palette.primary.contrastText,

    '&:hover': {
      color: ({ selected }) =>
        selected
          ? theme.palette.primary.contrastText
          : theme.palette.primary.light,
      backgroundImage: theme.palette.secondary.gradientHorizontal
    },
    // border: `1px solid currentColor`,
    borderRadius: 4,
    cursor: 'pointer',
    backgroundImage: ({ selected }) =>
      selected ? theme.palette.secondary.gradientHorizontal : '',
    backgroundColor: ({ selected }) =>
      selected ? 'transparent' : theme.palette.primary.light
  }
}))

const FilterRoomsOption = ({ id, name }) => {
  const roomOptions = useSelector(roomOptionsSelector)
  const selected = roomOptions.indexOf(id) >= 0
  const classes = useStyles({ selected })
  const dispatch = useDispatch()

  const handleChange = useCallback(() => {
    dispatch(selectRoomOption(id, !selected))
  }, [dispatch, id, selected])

  return (
    <div className={classes.option} onClick={handleChange}>
      <Typography variant="h4">{name}</Typography>
    </div>
  )
}
export default FilterRoomsOption
