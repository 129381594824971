import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import HeartIcon from '../Icons/HeartIcon'
import { favoritesSelector, toggleFavorite } from '../../modules/reducerHouses'

const useStyles = makeStyles((theme) => ({
  root: { padding: 0, color: 'currentColor' },
  icon: {
    width: 20,
    height: 20,
    stroke: ({ isFavorite }) => (isFavorite ? '#eb5757' : 'currentColor') //theme.palette.primary.contrastText
  }
}))

const FavoriteButton = ({ houseId, ...props }) => {
  const favorites = useSelector(favoritesSelector)
  const isFavorite = favorites.indexOf(houseId) >= 0
  const classes = useStyles({ isFavorite })
  const dispatch = useDispatch()

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation()
      dispatch(toggleFavorite(houseId))
    },
    [dispatch, houseId]
  )

  return (
    <Tooltip
      title={isFavorite ? 'Quitar de favoritos' : 'Añadir a favoritos'}
      arrow
    >
      <IconButton className={classes.root} onClick={handleClick} {...props}>
        <HeartIcon className={classes.icon} filled={isFavorite} />
      </IconButton>
    </Tooltip>
  )
}
export default FavoriteButton
