import { createSelector } from 'reselect'
import { getCurrentPromocion } from './reducerPromocion'
import { getFilterHouses } from './reducerHousesFilter'
import {
  houseTypologiesSelector,
  houseOrientationSelector,
  favoritesSelector
} from './reducerHouses'
import { findById } from '../utils/utils'

export const HOUSES_TABLE_SET_SORT_ORDER = 'HOUSES_TABLE_SET_SORT_ORDER'
export const HOUSES_TABLE_SET_SORT_COLUMN = 'HOUSES_TABLE_SET_SORT_COLUMN'
export const HOUSES_TABLE_COLUMN_CLICKED = 'HOUSES_TABLE_COLUMN_CLICKED'
export const HOUSES_TABLE_SET_PAGE = 'HOUSES_TABLE_SET_PAGE'
export const HOUSES_TABLE_SET_ROWS_PER_PAGE = 'HOUSES_TABLE_SET_ROWS_PER_PAGE'

export const COLUMNS = {
  NAME: 'c0',
  AREA: 'c1',
  FAVORITE: 'c2'
}
const initialState = {
  columns: [
    {
      id: COLUMNS.NAME,
      label: 'Nombre',
      align: 'left',
      sortEnabled: true,
      sortOrder: 'asc'
    },
    {
      id: COLUMNS.AREA,
      label: 'Superficie',
      align: 'center',
      sortEnabled: true,
      sortOrder: 'asc'
    },
    {
      id: COLUMNS.FAVORITE,
      label: 'Favoritos',
      align: 'center',
      sortEnabled: false,
      sortOrder: 'asc'
    }
  ],
  sortColumn: '',
  rowsPerPage: Number.MAX_VALUE,
  currentPage: 0
}

const changeState = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case HOUSES_TABLE_COLUMN_CLICKED: {
      const col = findById(state.columns, action.col)
      if (!col || !col.sortEnabled) {
        return state
      }
      let columns = state.columns
      let sortColumn = state.sortColumn
      if (sortColumn !== action.col) {
        sortColumn = action.col
      } else {
        columns = columns.map((col) => {
          if (col.id === action.col) {
            return {
              ...col,
              sortOrder: col.sortOrder === 'asc' ? 'desc' : 'asc'
            }
          }
          return col
        })
      }
      return { ...state, columns, sortColumn }
    }
    case HOUSES_TABLE_SET_SORT_ORDER:
      let columns = state.columns.map((col) => {
        if (col.id === action.col) {
          return { ...col, sortOrder: action.order }
        }
        return col
      })
      return { ...state, columns }
    case HOUSES_TABLE_SET_SORT_COLUMN:
      return {
        ...state,
        sortColumn: action.column
      }
    case HOUSES_TABLE_SET_PAGE:
      return {
        ...state,
        currentPage: action.page
      }
    case HOUSES_TABLE_SET_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.nRows
      }
    default:
      return state
  }
}
export default changeState

export const columnClicked = (col) => ({
  type: HOUSES_TABLE_COLUMN_CLICKED,
  col
})

export const setSortOrder = (col, order) => ({
  type: HOUSES_TABLE_SET_SORT_ORDER,
  col,
  order
})

export const setSorColumn = (column) => ({
  type: HOUSES_TABLE_SET_SORT_COLUMN,
  column
})

export const setPage = (page) => ({
  type: HOUSES_TABLE_SET_PAGE,
  page
})

export const setRowsPerPage = (nRows) => ({
  type: HOUSES_TABLE_SET_ROWS_PER_PAGE,
  nRows
})

export const columnsSelector = (state) => state.housesTable.columns
export const sortColumnSelector = (state) => state.housesTable.sortColumn
export const rowsPerPageSelector = (state) => state.housesTable.rowsPerPage
//Usar getCurrenPage en lugar de currentPageSelector
export const currentPageSelector = (state) => state.housesTable.currentPage

export const getRowCount = createSelector([getFilterHouses], (houses) => {
  return houses.length
})

export const getColumn = () =>
  createSelector([columnsSelector, (_, id) => id], (columns, id) => {
    return findById(columns, id)
  })
/*
  Crea datos de cada columna. El valor que se de es el que se utiliza para
  ordenar
*/
export const getTableHouses = createSelector(
  [
    getFilterHouses,
    houseTypologiesSelector,
    houseOrientationSelector,
    favoritesSelector
  ],
  (houses, houseTypologies, houseOrientation, favorites) => {
    return houses.map((house) => {
      return {
        id: house.id,
        state: house.state,
        favorite: favorites.indexOf(house.id) >= 0,
        c0: house.name,
        c1: house.typologyObject.area
      }
    })
  }
)

const sortByColumn = (column, order) => {
  let sortOrder = order === 'asc' ? -1 : 1
  return (a, b) => {
    var result = a[column] < b[column] ? -1 : a[column] > b[column] ? 1 : 0
    return result * sortOrder
  }
}

export const getSortedHouses = createSelector(
  [getTableHouses, sortColumnSelector, columnsSelector],
  (houses, sortColumn, columns) => {
    const column = findById(columns, sortColumn)
    if (!column) {
      return houses
    }
    const newHouses = [...houses]
    return newHouses.sort(sortByColumn(sortColumn, column.sortOrder))
  }
)

export const getFinalTableHouses = createSelector(
  [getSortedHouses, getCurrentPromocion],
  (houses, promocion) => {
    //Termina de crear los datos
    const areaUnit = promocion ? promocion.areaUnit : ''
    return houses.map((house) => {
      return {
        ...house,
        c1: house.c1 + ' ' + areaUnit
      }
    })
  }
)
