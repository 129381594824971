const HouseIcon = (props) => {
  return (
    <svg fill="none" viewBox="0 0 41 34" {...props}>
      <path
        stroke="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M1 33.217h39M1 19.652l16.956-6.782"
      />
      <path
        stroke="stroke"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M4.391 18.296v14.921"
      />
      <path
        stroke="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M15.413 1L40 16.26"
      />
      <path
        stroke="stroke"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M36.609 14.226v18.991m-5.087-22.212V5.239h3.391v7.8"
      />
      <path
        stroke="stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M33.218 26.435h-11.87v6.782h11.87v-6.782zm-5.935 0v6.782m-3.392-16.108a2.545 2.545 0 000-5.087 2.544 2.544 0 000 5.087zm-9.326 7.631H7.782v8.478h6.783v-8.479zm-6.783-3.392h6.783"
      />
      <path
        stroke="stroke"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M17.956 2.696v30.521"
      />
    </svg>
  )
}
export default HouseIcon
