import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'

import RemoveIcon from '../Icons/RemoveIcon'
import {
  getNDocumentsToDownload,
  removeDownload
} from '../../modules/reducerDocuments'
const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: '0 4px',
    color: 'currentColor',
    '&:hover': { color: theme.palette.secondary.main },
    '&:disabled': { color: '#707070' }
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

const RemoveDownloadButton = ({ ids, width, height }) => {
  const gNDocumentsToDownload = useSelector(getNDocumentsToDownload)
  const nDocs = gNDocumentsToDownload(ids)
  const dispatch = useDispatch()
  const classes = useStyles()

  const handleDownload = useCallback(
    (e) => {
      e.stopPropagation()
      for (let docId of ids) {
        dispatch(removeDownload(docId))
      }
    },
    [dispatch, ids]
  )

  return ids ? (
    <Tooltip title={'Quitar de descargas'} arrow>
      <div className={classes.buttonWrapper}>
        <IconButton
          className={classes.iconButton}
          disabled={nDocs === 0}
          onClick={handleDownload}
        >
          <RemoveIcon width={width} height={height} stroke={'currentColor'} />
        </IconButton>
      </div>
    </Tooltip>
  ) : null
}
export default RemoveDownloadButton
