import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Badge from '@material-ui/core/Badge'

import DownloadIcon from '../Icons/DownloadIcon'

import { downloadSelector } from '../../modules/reducerDocuments'

const useStyles = makeStyles((theme) => ({
  badge: {
    right: -12,
    color: theme.palette.primary.main,
    backgroundImage: theme.palette.secondary.gradientHorizontal
  },
  root: {
    position: 'relative',
    width: theme.componentsData.menuOption.width,
    height: theme.componentsData.menuOption.height,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column',
    backgroundColor: ({ selected }) =>
      selected ? '#2e2920' : theme.palette.primary.mainTransparent,
    color: ({ selected }) =>
      selected
        ? theme.palette.secondary.main
        : theme.palette.primary.contrastText,
    opacity: ({ selected }) => (selected ? 0.85 : ''),
    '&:hover': {
      color: theme.palette.secondary.main,
      opacity: 0.85,
      backgroundColor: '#2e2920'
    }
  },
  iconWrapper: {
    width: '40px',
    height: '40px',
    flexShrink: 0,
    margin: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    stroke: 'currentColor',
    width: 32,
    height: '100%'
  },
  nextIcon: {
    position: 'absolute',
    right: 8,
    fill: 'currentColor',
    width: 20,
    transform: ({ expanded }) => (expanded ? 'rotate(180deg)' : 'rotate(0deg)')
  },
  title: { color: 'currentColor', paddingTop: 4 }
}))

const DownloadMenuOption = ({ className }) => {
  const download = useSelector(downloadSelector)
  const classes = useStyles()

  return (
    <Badge
      classes={{ badge: classes.badge }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      badgeContent={download.length}
    >
      <DownloadIcon className={className} />
    </Badge>
  )
}
export default DownloadMenuOption
//
