import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import EditorTextField from '../Common/Input/EditorTextField'
import {
  lastNameSelector,
  setLastName,
  getLastNameError,
  submitClickedSelector
} from '../../modules/reducerContact'

const useStyles = makeStyles((theme) => ({
  textField: { width: '100%' }
}))

const CFLastNameInput = () => {
  const classes = useStyles()
  const lastName = useSelector(lastNameSelector)
  const error = useSelector(getLastNameError)
  const submitClicked = useSelector(submitClickedSelector)
  const dispatch = useDispatch()

  const handleChange = useCallback(
    (value) => {
      dispatch(setLastName(value))
    },
    [dispatch]
  )

  return (
    <EditorTextField
      className={classes.textField}
      label="Apellidos"
      value={lastName}
      required
      error={submitClicked && error !== ''}
      onChange={handleChange}
      helperText={submitClicked ? error : ''}
    />
  )
}
export default CFLastNameInput
