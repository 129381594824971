import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import InfoIcon from '@material-ui/icons/Info'

import GradientText from '../Common/GradientText'
const useStyles = makeStyles((theme) => ({
  root: {},
  title: { color: theme.palette.secondary.main },
  paper: { backgroundColor: theme.palette.primary.light },
  dialogContent: { display: 'flex', alignItems: 'center' },
  text: { color: theme.palette.primary.contrastText, margin: 0 }
}))
const CFSentDialog = ({ open, onClose }) => {
  const classes = useStyles()
  return (
    <Dialog classes={{ paper: classes.paper }} open={open} onClose={onClose}>
      <DialogTitle className={classes.title} disableTypography>
        <GradientText variant="h2">
          Gracias por contactar con nosotros
        </GradientText>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <InfoIcon fontSize="large" color="secondary" />
        <DialogContentText className={classes.text}>
          Hemos recibido tu mensaje. En breve atenderemos tu consulta.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default CFSentDialog
