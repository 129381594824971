// import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Krpano from '../Krpano/Krpano'
// import { useSelector, useDispatch } from 'react-redux'
// import {} from '../../modules/reducerMain'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexFlow: 'column'
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 0
  }
}))

const GeneralView = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Krpano src="./GeneralView/ENTRENUCLEOS.html" />
    </div>
  )
}
export default GeneralView
//<div style={{ width: 200, height: 700, backgroundColor: 'grey' }} />
//src="./VisitasVirtuales/1/index.html"
//src="./VT/TB79/LH79.html"
