export const group_g0_segments =[
 [
  {
   "color": "#08ff00ff",
   "pois": [{"id": "centroid", "point": [725, 429]}],
   "box": [614, 333, 834, 523],
   "polygons": [
    [
     [834, 399],
     [776, 523],
     [773, 523],
     [614, 464],
     [683, 333],
     [774, 363],
     [776, 364],
     [774, 369],
     [776, 379]
    ]
   ]
  },
  {
   "color": "#ffa200ff",
   "pois": [{"id": "centroid", "point": [902, 503]}],
   "box": [812, 403, 995, 608],
   "polygons": [
    [
     [995, 438],
     [965, 513],
     [963, 516],
     [950, 516],
     [935, 554],
     [921, 554],
     [898, 608],
     [812, 576],
     [890, 403]
    ]
   ]
  },
  {
   "color": "#0012ffff",
   "pois": [{"id": "centroid", "point": [786, 678]}],
   "box": [685, 588, 909, 755],
   "polygons": [
    [
     [909, 620],
     [884, 679],
     [868, 681],
     [851, 724],
     [793, 725],
     [793, 754],
     [788, 755],
     [685, 754],
     [688, 634],
     [775, 634],
     [782, 627],
     [813, 627],
     [815, 614],
     [826, 588],
     [909, 617]
    ]
   ]
  },
  {
   "color": "#fffe00ff",
   "pois": [{"id": "centroid", "point": [588, 665]}],
   "box": [491, 587, 689, 747],
   "polygons": [
    [[689, 627], [681, 637], [679, 737], [604, 737], [604, 747], [491, 746], [502, 587], [689, 587]]
   ]
  }
 ],
 [
  {
   "color": "#08ff00ff",
   "pois": [{"id": "centroid", "point": [747, 427]}],
   "box": [630, 326, 860, 527],
   "polygons": [
    [
     [860, 413],
     [778, 527],
     [770, 524],
     [703, 485],
     [630, 445],
     [724, 326],
     [810, 370],
     [806, 376],
     [808, 385],
     [810, 387],
     [853, 408]
    ]
   ]
  },
  {
   "color": "#ffa200ff",
   "pois": [{"id": "centroid", "point": [908, 526]}],
   "box": [804, 425, 1013, 630],
   "polygons": [
    [
     [1013, 476],
     [969, 546],
     [966, 549],
     [954, 546],
     [931, 582],
     [917, 579],
     [886, 626],
     [882, 630],
     [807, 588],
     [804, 585],
     [809, 575],
     [915, 425],
     [1000, 468]
    ]
   ]
  },
  {
   "color": "#0012ffff",
   "pois": [{"id": "centroid", "point": [758, 681]}],
   "box": [643, 599, 891, 758],
   "polygons": [
    [
     [891, 643],
     [854, 699],
     [852, 700],
     [838, 698],
     [812, 738],
     [761, 730],
     [754, 732],
     [750, 756],
     [748, 758],
     [643, 741],
     [669, 623],
     [753, 637],
     [763, 635],
     [764, 631],
     [789, 635],
     [797, 633],
     [799, 622],
     [815, 599],
     [888, 639],
     [891, 641]
    ]
   ]
  },
  {
   "color": "#fffe00ff",
   "pois": [{"id": "centroid", "point": [567, 638]}],
   "box": [456, 549, 680, 723],
   "polygons": [
    [
     [680, 578],
     [671, 618],
     [664, 619],
     [641, 723],
     [569, 712],
     [566, 716],
     [565, 721],
     [456, 703],
     [498, 549],
     [677, 576]
    ]
   ]
  }
 ],
 [
  {
   "color": "#08ff00ff",
   "pois": [{"id": "centroid", "point": [769, 428]}],
   "box": [650, 324, 883, 532],
   "polygons": [
    [
     [883, 431],
     [778, 532],
     [650, 428],
     [766, 324],
     [839, 378],
     [842, 381],
     [836, 387],
     [838, 397],
     [883, 429]
    ]
   ]
  },
  {
   "color": "#ffa200ff",
   "pois": [{"id": "centroid", "point": [909, 550]}],
   "box": [792, 451, 1025, 649],
   "polygons": [
    [
     [1025, 516],
     [966, 579],
     [962, 582],
     [959, 579],
     [949, 579],
     [920, 610],
     [916, 606],
     [908, 603],
     [866, 647],
     [862, 649],
     [792, 593],
     [936, 451]
    ]
   ]
  },
  {
   "color": "#0012ffff",
   "pois": [{"id": "centroid", "point": [729, 681]}],
   "box": [604, 609, 869, 754],
   "polygons": [
    [
     [869, 663],
     [819, 714],
     [811, 712],
     [802, 712],
     [771, 745],
     [724, 730],
     [715, 730],
     [706, 753],
     [699, 754],
     [604, 722],
     [653, 610],
     [729, 635],
     [743, 635],
     [744, 632],
     [767, 640],
     [777, 639],
     [780, 630],
     [802, 609]
    ]
   ]
  },
  {
   "color": "#fffe00ff",
   "pois": [{"id": "centroid", "point": [551, 609]}],
   "box": [431, 511, 673, 703],
   "polygons": [
    [
     [673, 566],
     [656, 605],
     [647, 609],
     [645, 613],
     [606, 703],
     [541, 682],
     [536, 682],
     [532, 689],
     [431, 655],
     [502, 511]
    ]
   ]
  }
 ],
 [
  {
   "color": "#08ff00ff",
   "pois": [{"id": "centroid", "point": [790, 432]}],
   "box": [673, 329, 904, 536],
   "polygons": [
    [[904, 451], [778, 536], [673, 415], [808, 329], [872, 396], [864, 402], [866, 413]]
   ]
  },
  {
   "color": "#ffa200ff",
   "pois": [{"id": "centroid", "point": [905, 574]}],
   "box": [780, 480, 1028, 666],
   "polygons": [
    [
     [1028, 559],
     [957, 612],
     [951, 614],
     [950, 612],
     [940, 609],
     [903, 636],
     [899, 630],
     [889, 630],
     [838, 666],
     [780, 600],
     [780, 598],
     [797, 586],
     [952, 480],
     [1014, 543]
    ]
   ]
  },
  {
   "color": "#0012ffff",
   "pois": [{"id": "centroid", "point": [701, 676]}],
   "box": [570, 594, 842, 746],
   "polygons": [
    [
     [842, 680],
     [784, 722],
     [782, 723],
     [774, 719],
     [766, 718],
     [728, 746],
     [683, 722],
     [675, 724],
     [662, 744],
     [656, 743],
     [570, 696],
     [640, 594],
     [715, 633],
     [725, 630],
     [747, 642],
     [756, 641],
     [761, 633],
     [786, 616]
    ]
   ]
  },
  {
   "color": "#fffe00ff",
   "pois": [{"id": "centroid", "point": [541, 577]}],
   "box": [417, 474, 667, 678],
   "polygons": [
    [
     [667, 555],
     [644, 589],
     [635, 590],
     [575, 678],
     [518, 648],
     [511, 648],
     [508, 652],
     [506, 652],
     [417, 604],
     [513, 474],
     [652, 545],
     [667, 553]
    ]
   ]
  }
 ],
 [
  {
   "color": "#08ff00ff",
   "pois": [{"id": "centroid", "point": [811, 439]}],
   "box": [698, 340, 920, 541],
   "polygons": [
    [[920, 476], [777, 541], [698, 406], [849, 340], [898, 414], [899, 416], [889, 421], [889, 429]]
   ]
  },
  {
   "color": "#ffa200ff",
   "pois": [{"id": "centroid", "point": [896, 598]}],
   "box": [766, 512, 1023, 678],
   "polygons": [
    [
     [1023, 602],
     [935, 646],
     [931, 640],
     [921, 639],
     [882, 659],
     [877, 652],
     [869, 651],
     [811, 678],
     [809, 677],
     [766, 603],
     [961, 512],
     [964, 513]
    ]
   ]
  },
  {
   "color": "#0012ffff",
   "pois": [{"id": "centroid", "point": [674, 666]}],
   "box": [542, 576, 812, 739],
   "polygons": [
    [
     [812, 693],
     [744, 726],
     [737, 720],
     [727, 719],
     [688, 739],
     [685, 739],
     [655, 714],
     [649, 710],
     [640, 708],
     [621, 728],
     [542, 665],
     [630, 576],
     [695, 626],
     [697, 627],
     [706, 625],
     [727, 641],
     [737, 639],
     [740, 634],
     [769, 620]
    ]
   ]
  },
  {
   "color": "#fffe00ff",
   "pois": [{"id": "centroid", "point": [538, 545]}],
   "box": [413, 440, 665, 649],
   "polygons": [
    [
     [665, 541],
     [635, 572],
     [625, 572],
     [550, 649],
     [505, 612],
     [493, 610],
     [490, 613],
     [487, 611],
     [413, 551],
     [530, 440],
     [548, 452]
    ]
   ]
  }
 ],
 [
  {
   "color": "#08ff00ff",
   "pois": [{"id": "centroid", "point": [830, 450]}],
   "box": [725, 357, 930, 545],
   "polygons": [
    [[930, 503], [776, 545], [725, 400], [887, 357], [922, 439], [920, 441], [910, 444], [910, 453]]
   ]
  },
  {
   "color": "#ffa200ff",
   "pois": [{"id": "centroid", "point": [883, 620]}],
   "box": [752, 545, 1009, 686],
   "polygons": [
    [
     [1009, 645],
     [917, 673],
     [911, 674],
     [907, 667],
     [897, 666],
     [855, 679],
     [852, 670],
     [841, 669],
     [806, 680],
     [780, 686],
     [752, 604],
     [966, 545]
    ]
   ]
  },
  {
   "color": "#0012ffff",
   "pois": [{"id": "centroid", "point": [650, 653]}],
   "box": [522, 557, 779, 726],
   "polygons": [
    [
     [779, 701],
     [707, 722],
     [705, 722],
     [699, 714],
     [689, 713],
     [646, 726],
     [615, 690],
     [605, 689],
     [590, 701],
     [584, 704],
     [523, 633],
     [522, 630],
     [624, 557],
     [679, 617],
     [689, 617],
     [693, 623],
     [707, 637],
     [715, 635],
     [721, 631],
     [730, 628],
     [752, 623]
    ]
   ]
  },
  {
   "color": "#fffe00ff",
   "pois": [{"id": "centroid", "point": [542, 512]}],
   "box": [420, 408, 665, 616],
   "polygons": [
    [
     [665, 528],
     [631, 553],
     [629, 554],
     [621, 552],
     [617, 554],
     [615, 557],
     [532, 616],
     [493, 570],
     [481, 571],
     [476, 566],
     [420, 500],
     [555, 408]
    ]
   ]
  }
 ],
 [
  {
   "color": "#08ff00ff",
   "pois": [{"id": "centroid", "point": [847, 463]}],
   "box": [752, 380, 940, 549],
   "polygons": [
    [[940, 468], [928, 469], [924, 471], [936, 531], [773, 549], [752, 398], [923, 380]]
   ]
  },
  {
   "color": "#ffa200ff",
   "pois": [{"id": "centroid", "point": [865, 641]}],
   "box": [738, 579, 985, 698],
   "polygons": [
    [
     [985, 686],
     [892, 698],
     [881, 698],
     [879, 690],
     [871, 688],
     [825, 694],
     [823, 684],
     [815, 682],
     [759, 689],
     [749, 689],
     [738, 604],
     [963, 579],
     [985, 681]
    ]
   ]
  },
  {
   "color": "#0012ffff",
   "pois": [{"id": "centroid", "point": [629, 635]}],
   "box": [508, 537, 745, 712],
   "polygons": [
    [
     [745, 704],
     [735, 704],
     [673, 712],
     [668, 712],
     [662, 702],
     [653, 701],
     [609, 706],
     [587, 666],
     [577, 664],
     [554, 675],
     [508, 593],
     [622, 537],
     [663, 605],
     [673, 606],
     [687, 629],
     [694, 629],
     [702, 625],
     [734, 622]
    ]
   ]
  },
  {
   "color": "#fffe00ff",
   "pois": [{"id": "centroid", "point": [552, 481]}],
   "box": [437, 381, 669, 580],
   "polygons": [
    [
     [669, 515],
     [628, 535],
     [619, 533],
     [615, 534],
     [521, 580],
     [492, 529],
     [481, 529],
     [479, 526],
     [437, 450],
     [585, 381]
    ]
   ]
  }
 ],
 [
  {
   "color": "#08ff00ff",
   "pois": [{"id": "centroid", "point": [861, 479]}],
   "box": [770, 400, 953, 561],
   "polygons": [[[953, 498], [935, 499], [936, 561], [770, 553], [780, 400], [953, 408]]]
  },
  {
   "color": "#ffa200ff",
   "pois": [{"id": "centroid", "point": [843, 658]}],
   "box": [718, 601, 953, 723],
   "polygons": [
    [
     [953, 723],
     [853, 719],
     [846, 717],
     [845, 706],
     [791, 704],
     [791, 691],
     [718, 687],
     [724, 601],
     [953, 613]
    ]
   ]
  },
  {
   "color": "#0012ffff",
   "pois": [{"id": "centroid", "point": [612, 615]}],
   "box": [503, 518, 716, 700],
   "polygons": [
    [
     [716, 627],
     [711, 700],
     [641, 697],
     [634, 696],
     [627, 683],
     [578, 681],
     [563, 636],
     [551, 635],
     [531, 642],
     [503, 554],
     [621, 518],
     [625, 518],
     [650, 589],
     [660, 593],
     [669, 617],
     [675, 619],
     [681, 617],
     [716, 618]
    ]
   ]
  },
  {
   "color": "#fffe00ff",
   "pois": [{"id": "centroid", "point": [568, 451]}],
   "box": [463, 359, 674, 544],
   "polygons": [
    [
     [674, 503],
     [631, 516],
     [629, 514],
     [615, 514],
     [518, 544],
     [500, 490],
     [489, 487],
     [463, 404],
     [619, 359]
    ]
   ]
  }
 ],
 [
  {
   "color": "#08ff00ff",
   "pois": [{"id": "centroid", "point": [871, 497]}],
   "box": [766, 407, 978, 590],
   "polygons": [
    [[978, 444], [960, 529], [941, 530], [929, 590], [766, 556], [807, 407], [975, 440], [978, 441]]
   ]
  },
  {
   "color": "#ffa200ff",
   "pois": [{"id": "centroid", "point": [818, 672]}],
   "box": [688, 596, 936, 754],
   "polygons": [
    [
     [936, 646],
     [913, 754],
     [810, 732],
     [808, 731],
     [807, 719],
     [805, 718],
     [756, 708],
     [757, 695],
     [695, 682],
     [688, 679],
     [711, 596],
     [932, 643]
    ]
   ]
  },
  {
   "color": "#0012ffff",
   "pois": [{"id": "centroid", "point": [599, 592]}],
   "box": [505, 498, 700, 691],
   "polygons": [
    [
     [700, 612],
     [678, 691],
     [607, 676],
     [604, 675],
     [601, 661],
     [599, 660],
     [555, 652],
     [552, 649],
     [547, 604],
     [533, 602],
     [515, 605],
     [505, 516],
     [630, 498],
     [641, 573],
     [650, 578],
     [654, 605],
     [671, 605],
     [696, 610]
    ]
   ]
  },
  {
   "color": "#fffe00ff",
   "pois": [{"id": "centroid", "point": [589, 425]}],
   "box": [497, 342, 682, 508],
   "polygons": [
    [
     [682, 491],
     [666, 494],
     [655, 494],
     [637, 498],
     [635, 494],
     [625, 493],
     [522, 508],
     [515, 448],
     [506, 449],
     [497, 364],
     [657, 342]
    ]
   ]
  }
 ],
 [
  {
   "color": "#08ff00ff",
   "pois": [{"id": "centroid", "point": [878, 517]}],
   "box": [761, 418, 996, 618],
   "polygons": [
    [
     [996, 480],
     [959, 564],
     [947, 560],
     [941, 562],
     [916, 618],
     [761, 558],
     [832, 418],
     [841, 420],
     [993, 476],
     [996, 478]
    ]
   ]
  },
  {
   "color": "#ffa200ff",
   "pois": [{"id": "centroid", "point": [790, 681]}],
   "box": [659, 589, 912, 778],
   "polygons": [
    [
     [912, 674],
     [867, 778],
     [767, 738],
     [767, 725],
     [721, 707],
     [723, 703],
     [721, 693],
     [659, 667],
     [700, 589],
     [905, 670]
    ]
   ]
  },
  {
   "color": "#0012ffff",
   "pois": [{"id": "centroid", "point": [591, 567]}],
   "box": [507, 478, 685, 677],
   "polygons": [
    [
     [685, 603],
     [648, 677],
     [579, 649],
     [579, 635],
     [537, 619],
     [534, 616],
     [538, 571],
     [537, 567],
     [507, 567],
     [516, 478],
     [640, 480],
     [636, 553],
     [638, 561],
     [643, 561],
     [642, 589],
     [647, 591],
     [657, 591],
     [681, 600]
    ]
   ]
  },
  {
   "color": "#fffe00ff",
   "pois": [{"id": "centroid", "point": [616, 403]}],
   "box": [530, 329, 698, 482],
   "polygons": [
    [
     [698, 347],
     [692, 482],
     [646, 481],
     [639, 475],
     [534, 473],
     [538, 421],
     [537, 414],
     [530, 413],
     [530, 407],
     [538, 329],
     [698, 331]
    ]
   ]
  }
 ],
 [
  {
   "color": "#08ff00ff",
   "pois": [{"id": "centroid", "point": [881, 538]}],
   "box": [757, 432, 1007, 644],
   "polygons": [
    [
     [1007, 518],
     [953, 596],
     [951, 597],
     [941, 592],
     [934, 592],
     [898, 644],
     [757, 560],
     [764, 549],
     [855, 432],
     [981, 502]
    ]
   ]
  },
  {
   "color": "#ffa200ff",
   "pois": [{"id": "centroid", "point": [760, 686]}],
   "box": [635, 580, 882, 794],
   "polygons": [
    [
     [882, 698],
     [816, 794],
     [731, 742],
     [725, 737],
     [727, 734],
     [728, 725],
     [725, 722],
     [687, 699],
     [689, 695],
     [689, 685],
     [645, 658],
     [635, 651],
     [635, 649],
     [689, 580]
    ]
   ]
  },
  {
   "color": "#0012ffff",
   "pois": [{"id": "centroid", "point": [588, 542]}],
   "box": [507, 443, 672, 658],
   "polygons": [
    [
     [672, 592],
     [623, 656],
     [620, 658],
     [560, 620],
     [561, 605],
     [524, 582],
     [525, 574],
     [535, 541],
     [533, 533],
     [507, 528],
     [533, 443],
     [653, 464],
     [652, 472],
     [635, 533],
     [636, 544],
     [640, 545],
     [634, 565],
     [636, 573],
     [649, 576],
     [652, 579],
     [672, 590]
    ]
   ]
  },
  {
   "color": "#fffe00ff",
   "pois": [{"id": "centroid", "point": [646, 384]}],
   "box": [552, 301, 740, 474],
   "polygons": [
    [
     [740, 330],
     [704, 474],
     [659, 466],
     [652, 459],
     [645, 457],
     [552, 441],
     [567, 389],
     [565, 383],
     [560, 381],
     [585, 301],
     [739, 326]
    ]
   ]
  }
 ],
 [
  {
   "color": "#08ff00ff",
   "pois": [{"id": "centroid", "point": [880, 559]}],
   "box": [751, 450, 1010, 666],
   "polygons": [
    [
     [1010, 559],
     [938, 629],
     [929, 623],
     [919, 622],
     [874, 666],
     [872, 666],
     [751, 560],
     [875, 450],
     [889, 460]
    ]
   ]
  },
  {
   "color": "#ffa200ff",
   "pois": [{"id": "centroid", "point": [729, 685]}],
   "box": [614, 570, 847, 800],
   "polygons": [
    [
     [847, 717],
     [763, 800],
     [761, 800],
     [690, 736],
     [685, 730],
     [689, 725],
     [689, 717],
     [655, 687],
     [655, 685],
     [659, 682],
     [661, 674],
     [619, 636],
     [614, 629],
     [680, 570],
     [682, 570]
    ]
   ]
  },
  {
   "color": "#0012ffff",
   "pois": [{"id": "centroid", "point": [591, 516]}],
   "box": [516, 412, 670, 635],
   "polygons": [
    [
     [670, 451],
     [637, 517],
     [638, 526],
     [640, 528],
     [635, 539],
     [632, 541],
     [630, 547],
     [632, 555],
     [641, 558],
     [662, 578],
     [613, 623],
     [598, 635],
     [548, 588],
     [551, 580],
     [551, 573],
     [520, 545],
     [539, 509],
     [539, 499],
     [516, 491],
     [516, 489],
     [557, 412]
    ]
   ]
  },
  {
   "color": "#fffe00ff",
   "pois": [{"id": "centroid", "point": [678, 371]}],
   "box": [576, 280, 782, 467],
   "polygons": [
    [
     [782, 329],
     [759, 377],
     [753, 393],
     [717, 467],
     [675, 453],
     [671, 445],
     [576, 412],
     [599, 369],
     [600, 358],
     [595, 356],
     [636, 280],
     [780, 327]
    ]
   ]
  }
 ],
 [
  {
   "color": "#08ff00ff",
   "pois": [{"id": "centroid", "point": [874, 579]}],
   "box": [746, 471, 1004, 685],
   "polygons": [
    [
     [1004, 600],
     [919, 657],
     [915, 657],
     [909, 650],
     [900, 648],
     [845, 685],
     [746, 560],
     [892, 471]
    ]
   ]
  },
  {
   "color": "#ffa200ff",
   "pois": [{"id": "centroid", "point": [699, 680]}],
   "box": [598, 558, 808, 798],
   "polygons": [
    [
     [808, 731],
     [708, 798],
     [701, 791],
     [686, 771],
     [646, 715],
     [650, 713],
     [652, 703],
     [626, 668],
     [632, 664],
     [634, 656],
     [600, 611],
     [598, 606],
     [675, 558],
     [805, 725]
    ]
   ]
  },
  {
   "color": "#0012ffff",
   "pois": [{"id": "centroid", "point": [599, 492]}],
   "box": [525, 385, 688, 609],
   "polygons": [
    [
     [688, 441],
     [660, 478],
     [656, 481],
     [656, 483],
     [642, 501],
     [644, 511],
     [631, 527],
     [632, 537],
     [640, 542],
     [656, 563],
     [582, 609],
     [544, 558],
     [543, 553],
     [547, 549],
     [547, 539],
     [525, 510],
     [526, 506],
     [551, 475],
     [551, 467],
     [532, 456],
     [532, 453],
     [587, 385],
     [686, 438]
    ]
   ]
  },
  {
   "color": "#fffe00ff",
   "pois": [{"id": "centroid", "point": [713, 363]}],
   "box": [605, 267, 822, 463],
   "polygons": [
    [
     [822, 335],
     [783, 388],
     [780, 398],
     [732, 463],
     [722, 459],
     [710, 451],
     [693, 443],
     [689, 434],
     [681, 429],
     [605, 388],
     [632, 353],
     [635, 351],
     [637, 347],
     [638, 338],
     [635, 336],
     [637, 333],
     [690, 267]
    ]
   ]
  }
 ],
 [
  {
   "color": "#08ff00ff",
   "pois": [{"id": "centroid", "point": [864, 598]}],
   "box": [741, 494, 989, 698],
   "polygons": [
    [
     [989, 640],
     [891, 683],
     [889, 683],
     [889, 680],
     [885, 677],
     [883, 672],
     [871, 672],
     [813, 698],
     [741, 559],
     [904, 494]
    ]
   ]
  },
  {
   "color": "#ffa200ff",
   "pois": [{"id": "centroid", "point": [671, 669]}],
   "box": [587, 546, 767, 786],
   "polygons": [
    [
     [767, 737],
     [655, 786],
     [636, 749],
     [612, 695],
     [619, 692],
     [620, 683],
     [602, 646],
     [606, 643],
     [609, 643],
     [611, 633],
     [587, 581],
     [672, 546],
     [764, 728]
    ]
   ]
  },
  {
   "color": "#0012ffff",
   "pois": [{"id": "centroid", "point": [611, 469]}],
   "box": [536, 363, 709, 581],
   "polygons": [
    [
     [709, 432],
     [651, 485],
     [651, 494],
     [634, 510],
     [635, 519],
     [640, 523],
     [652, 548],
     [571, 581],
     [545, 526],
     [544, 521],
     [551, 515],
     [551, 507],
     [536, 476],
     [536, 474],
     [543, 467],
     [567, 447],
     [569, 437],
     [553, 423],
     [622, 363]
    ]
   ]
  },
  {
   "color": "#fffe00ff",
   "pois": [{"id": "centroid", "point": [748, 360]}],
   "box": [638, 263, 860, 462],
   "polygons": [
    [
     [860, 348],
     [808, 396],
     [807, 405],
     [746, 462],
     [714, 437],
     [711, 427],
     [707, 423],
     [638, 369],
     [679, 332],
     [678, 322],
     [680, 320],
     [745, 263]
    ]
   ]
  }
 ],
 [
  {
   "color": "#08ff00ff",
   "pois": [{"id": "centroid", "point": [850, 616]}],
   "box": [736, 519, 966, 705],
   "polygons": [
    [[966, 678], [858, 704], [854, 696], [853, 690], [841, 690], [779, 705], [736, 557], [911, 519]]
   ]
  },
  {
   "color": "#ffa200ff",
   "pois": [{"id": "centroid", "point": [646, 654]}],
   "box": [581, 534, 726, 765],
   "polygons": [
    [
     [726, 736],
     [606, 765],
     [583, 673],
     [583, 669],
     [591, 667],
     [593, 659],
     [583, 619],
     [593, 617],
     [594, 607],
     [581, 554],
     [671, 534]
    ]
   ]
  },
  {
   "color": "#0012ffff",
   "pois": [{"id": "centroid", "point": [628, 449]}],
   "box": [552, 347, 730, 552],
   "polygons": [
    [
     [730, 428],
     [663, 471],
     [662, 479],
     [646, 488],
     [641, 493],
     [641, 501],
     [645, 507],
     [652, 532],
     [566, 552],
     [552, 494],
     [552, 490],
     [561, 484],
     [561, 473],
     [553, 443],
     [591, 419],
     [593, 411],
     [580, 395],
     [659, 347],
     [720, 415]
    ]
   ]
  },
  {
   "color": "#fffe00ff",
   "pois": [{"id": "centroid", "point": [783, 363]}],
   "box": [674, 267, 896, 462],
   "polygons": [
    [
     [896, 366],
     [833, 407],
     [831, 417],
     [781, 450],
     [760, 462],
     [736, 435],
     [734, 432],
     [734, 423],
     [674, 355],
     [719, 327],
     [721, 325],
     [724, 314],
     [801, 267]
    ]
   ]
  }
 ],
 [
  {
   "color": "#08ff00ff",
   "pois": [{"id": "centroid", "point": [833, 631]}],
   "box": [732, 544, 935, 718],
   "polygons": [
    [
     [935, 711],
     [899, 712],
     [890, 714],
     [822, 718],
     [819, 704],
     [811, 702],
     [744, 707],
     [732, 555],
     [912, 544]
    ]
   ]
  },
  {
   "color": "#ffa200ff",
   "pois": [{"id": "centroid", "point": [624, 635]}],
   "box": [560, 522, 686, 737],
   "polygons": [
    [
     [686, 728],
     [563, 737],
     [560, 676],
     [560, 640],
     [571, 639],
     [572, 623],
     [570, 592],
     [581, 591],
     [583, 583],
     [581, 537],
     [582, 528],
     [673, 522]
    ]
   ]
  },
  {
   "color": "#0012ffff",
   "pois": [{"id": "centroid", "point": [648, 432]}],
   "box": [566, 337, 752, 523],
   "polygons": [
    [
     [752, 427],
     [691, 452],
     [677, 459],
     [675, 467],
     [654, 475],
     [652, 477],
     [650, 485],
     [652, 489],
     [654, 516],
     [643, 518],
     [623, 518],
     [602, 521],
     [568, 523],
     [566, 494],
     [566, 461],
     [577, 456],
     [577, 415],
     [619, 397],
     [621, 389],
     [612, 373],
     [613, 372],
     [699, 337],
     [751, 423]
    ]
   ]
  },
  {
   "color": "#fffe00ff",
   "pois": [{"id": "centroid", "point": [818, 370]}],
   "box": [712, 279, 928, 465],
   "polygons": [
    [
     [928, 389],
     [854, 422],
     [853, 431],
     [848, 434],
     [775, 465],
     [755, 432],
     [755, 419],
     [717, 357],
     [712, 346],
     [765, 324],
     [767, 315],
     [771, 313],
     [855, 279]
    ]
   ]
  }
 ],
 [
  {
   "color": "#08ff00ff",
   "pois": [{"id": "centroid", "point": [813, 642]}],
   "box": [709, 551, 909, 738],
   "polygons": [
    [
     [909, 572],
     [898, 738],
     [785, 727],
     [783, 721],
     [783, 709],
     [709, 703],
     [728, 551],
     [757, 555],
     [908, 569]
    ]
   ]
  },
  {
   "color": "#ffa200ff",
   "pois": [{"id": "centroid", "point": [607, 613]}],
   "box": [527, 502, 677, 714],
   "polygons": [
    [
     [677, 511],
     [649, 714],
     [527, 703],
     [543, 609],
     [555, 609],
     [563, 564],
     [564, 562],
     [575, 563],
     [587, 502],
     [671, 509]
    ]
   ]
  },
  {
   "color": "#0012ffff",
   "pois": [{"id": "centroid", "point": [671, 418]}],
   "box": [575, 333, 774, 502],
   "polygons": [
    [
     [774, 429],
     [691, 450],
     [689, 457],
     [663, 463],
     [663, 479],
     [661, 497],
     [659, 502],
     [575, 495],
     [585, 434],
     [597, 431],
     [605, 391],
     [651, 380],
     [653, 371],
     [648, 357],
     [648, 355],
     [651, 354],
     [740, 333]
    ]
   ]
  },
  {
   "color": "#fffe00ff",
   "pois": [{"id": "centroid", "point": [849, 383]}],
   "box": [750, 298, 944, 470],
   "polygons": [
    [
     [944, 388],
     [938, 422],
     [874, 439],
     [871, 449],
     [869, 450],
     [789, 470],
     [776, 436],
     [779, 425],
     [778, 421],
     [750, 344],
     [805, 331],
     [807, 330],
     [811, 321],
     [905, 298]
    ]
   ]
  }
 ],
 [
  {
   "color": "#08ff00ff",
   "pois": [{"id": "centroid", "point": [787, 651]}],
   "box": [677, 548, 888, 758],
   "polygons": [
    [
     [888, 591],
     [877, 633],
     [862, 733],
     [855, 758],
     [748, 730],
     [745, 728],
     [747, 712],
     [677, 693],
     [725, 548]
    ]
   ]
  },
  {
   "color": "#ffa200ff",
   "pois": [{"id": "centroid", "point": [595, 589]}],
   "box": [500, 478, 684, 694],
   "polygons": [
    [
     [684, 499],
     [615, 694],
     [502, 665],
     [500, 663],
     [534, 574],
     [547, 574],
     [562, 534],
     [576, 533],
     [598, 478]
    ]
   ]
  },
  {
   "color": "#0012ffff",
   "pois": [{"id": "centroid", "point": [697, 408]}],
   "box": [587, 334, 795, 488],
   "polygons": [
    [
     [795, 434],
     [707, 442],
     [707, 449],
     [687, 450],
     [678, 452],
     [677, 464],
     [668, 488],
     [662, 488],
     [641, 482],
     [632, 481],
     [587, 469],
     [609, 412],
     [623, 410],
     [637, 372],
     [687, 368],
     [688, 359],
     [686, 343],
     [780, 334]
    ]
   ]
  },
  {
   "color": "#fffe00ff",
   "pois": [{"id": "centroid", "point": [870, 397]}],
   "box": [788, 325, 963, 477],
   "polygons": [
    [
     [963, 375],
     [957, 378],
     [953, 391],
     [941, 388],
     [939, 390],
     [920, 456],
     [892, 459],
     [887, 461],
     [886, 469],
     [881, 470],
     [801, 477],
     [795, 439],
     [799, 439],
     [801, 431],
     [788, 348],
     [849, 342],
     [853, 334],
     [858, 333],
     [952, 325]
    ]
   ]
  }
 ],
 [
  {
   "color": "#08ff00ff",
   "pois": [{"id": "centroid", "point": [752, 653]}],
   "box": [646, 544, 844, 772],
   "polygons": [
    [
     [844, 599],
     [824, 638],
     [821, 647],
     [816, 691],
     [811, 763],
     [809, 772],
     [707, 725],
     [706, 723],
     [709, 717],
     [709, 707],
     [707, 705],
     [646, 678],
     [722, 544],
     [844, 597]
    ]
   ]
  },
  {
   "color": "#ffa200ff",
   "pois": [{"id": "centroid", "point": [588, 564]}],
   "box": [482, 456, 692, 670],
   "polygons": [
    [
     [692, 490],
     [586, 670],
     [482, 622],
     [531, 542],
     [545, 541],
     [568, 504],
     [572, 506],
     [581, 506],
     [612, 456]
    ]
   ]
  },
  {
   "color": "#0012ffff",
   "pois": [{"id": "centroid", "point": [723, 403]}],
   "box": [603, 337, 820, 477],
   "polygons": [
    [
     [820, 351],
     [815, 442],
     [727, 438],
     [725, 439],
     [724, 443],
     [693, 443],
     [692, 455],
     [679, 477],
     [603, 446],
     [635, 395],
     [637, 393],
     [651, 393],
     [671, 359],
     [707, 361],
     [723, 359],
     [725, 337],
     [820, 342]
    ]
   ]
  },
  {
   "color": "#fffe00ff",
   "pois": [{"id": "centroid", "point": [891, 411]}],
   "box": [812, 352, 994, 490],
   "polygons": [
    [
     [994, 427],
     [992, 431],
     [989, 429],
     [984, 420],
     [973, 419],
     [973, 424],
     [971, 428],
     [968, 431],
     [964, 430],
     [961, 428],
     [963, 412],
     [951, 406],
     [939, 405],
     [934, 412],
     [933, 417],
     [923, 417],
     [893, 475],
     [888, 484],
     [885, 486],
     [884, 490],
     [812, 486],
     [814, 448],
     [819, 448],
     [821, 439],
     [825, 356],
     [883, 359],
     [892, 357],
     [893, 352],
     [895, 352],
     [991, 357],
     [993, 358]
    ]
   ]
  }
 ],
 [
  {
   "color": "#08ff00ff",
   "pois": [{"id": "centroid", "point": [717, 649]}],
   "box": [620, 539, 799, 776],
   "polygons": [
    [
     [799, 602],
     [763, 647],
     [762, 650],
     [760, 776],
     [673, 717],
     [669, 712],
     [674, 707],
     [674, 696],
     [620, 659],
     [721, 539],
     [799, 590]
    ]
   ]
  },
  {
   "color": "#ffa200ff",
   "pois": [{"id": "centroid", "point": [587, 539]}],
   "box": [472, 437, 702, 641],
   "polygons": [
    [
     [702, 482],
     [564, 641],
     [562, 641],
     [490, 592],
     [474, 581],
     [472, 578],
     [536, 508],
     [542, 511],
     [551, 509],
     [578, 478],
     [584, 481],
     [593, 479],
     [631, 437]
    ]
   ]
  },
  {
   "color": "#0012ffff",
   "pois": [{"id": "centroid", "point": [751, 401]}],
   "box": [624, 337, 857, 468],
   "polygons": [
    [
     [857, 359],
     [832, 454],
     [745, 436],
     [743, 438],
     [743, 441],
     [713, 437],
     [708, 449],
     [691, 468],
     [624, 426],
     [667, 379],
     [683, 379],
     [707, 351],
     [751, 359],
     [759, 357],
     [765, 337],
     [856, 355]
    ]
   ]
  },
  {
   "color": "#fffe00ff",
   "pois": [{"id": "centroid", "point": [926, 429]}],
   "box": [821, 370, 1028, 503],
   "polygons": [
    [
     [1028, 397],
     [1010, 488],
     [1009, 491],
     [1001, 496],
     [993, 490],
     [976, 481],
     [978, 477],
     [975, 470],
     [971, 471],
     [970, 466],
     [965, 465],
     [962, 462],
     [961, 456],
     [957, 452],
     [946, 451],
     [944, 458],
     [941, 460],
     [935, 456],
     [937, 441],
     [923, 432],
     [914, 430],
     [906, 439],
     [903, 439],
     [900, 442],
     [851, 503],
     [821, 496],
     [830, 458],
     [834, 459],
     [839, 450],
     [860, 370],
     [917, 382],
     [927, 381],
     [927, 378],
     [929, 376],
     [1024, 395]
    ]
   ]
  }
 ],
 [
  {
   "color": "#08ff00ff",
   "pois": [{"id": "centroid", "point": [682, 639]}],
   "box": [599, 535, 752, 772],
   "polygons": [
    [
     [752, 565],
     [749, 573],
     [749, 604],
     [710, 638],
     [704, 644],
     [703, 647],
     [710, 772],
     [637, 700],
     [635, 697],
     [641, 692],
     [644, 681],
     [606, 645],
     [599, 638],
     [599, 636],
     [720, 535],
     [742, 554]
    ]
   ]
  },
  {
   "color": "#ffa200ff",
   "pois": [{"id": "centroid", "point": [590, 514]}],
   "box": [472, 421, 713, 611],
   "polygons": [
    [
     [713, 476],
     [547, 611],
     [472, 536],
     [546, 477],
     [549, 477],
     [554, 482],
     [563, 479],
     [567, 474],
     [592, 454],
     [594, 453],
     [599, 458],
     [611, 455],
     [614, 451],
     [653, 421]
    ]
   ]
  },
  {
   "color": "#0012ffff",
   "pois": [{"id": "centroid", "point": [778, 403]}],
   "box": [649, 343, 892, 467],
   "polygons": [
    [
     [892, 375],
     [847, 467],
     [802, 451],
     [787, 444],
     [773, 440],
     [763, 439],
     [761, 441],
     [758, 441],
     [745, 436],
     [732, 434],
     [727, 443],
     [705, 460],
     [649, 410],
     [651, 407],
     [699, 370],
     [705, 372],
     [717, 371],
     [744, 348],
     [756, 351],
     [785, 362],
     [794, 361],
     [801, 347],
     [804, 343],
     [806, 343],
     [890, 373]
    ]
   ]
  },
  {
   "color": "#fffe00ff",
   "pois": [{"id": "centroid", "point": [958, 463]}],
   "box": [832, 389, 1041, 564],
   "polygons": [
    [
     [1041, 436],
     [1013, 539],
     [1003, 564],
     [976, 541],
     [976, 531],
     [974, 531],
     [974, 528],
     [965, 526],
     [961, 528],
     [941, 511],
     [942, 508],
     [939, 500],
     [935, 501],
     [932, 493],
     [928, 493],
     [926, 484],
     [923, 479],
     [914, 477],
     [911, 484],
     [909, 484],
     [903, 478],
     [904, 465],
     [892, 453],
     [881, 453],
     [874, 459],
     [871, 464],
     [832, 497],
     [844, 472],
     [851, 472],
     [891, 389],
     [947, 409],
     [957, 407],
     [958, 405],
     [960, 405],
     [1041, 434]
    ]
   ]
  }
 ],
 [
  {
   "color": "#08ff00ff",
   "pois": [{"id": "centroid", "point": [646, 627]}],
   "box": [582, 530, 726, 757],
   "polygons": [
    [
     [726, 544],
     [698, 561],
     [699, 599],
     [691, 605],
     [679, 610],
     [666, 620],
     [647, 631],
     [661, 757],
     [607, 679],
     [606, 675],
     [615, 670],
     [617, 662],
     [582, 611],
     [721, 530],
     [725, 536]
    ]
   ]
  },
  {
   "color": "#ffa200ff",
   "pois": [{"id": "centroid", "point": [598, 490]}],
   "box": [480, 409, 725, 578],
   "polygons": [
    [
     [725, 471],
     [538, 578],
     [536, 577],
     [480, 497],
     [480, 494],
     [563, 448],
     [568, 452],
     [578, 452],
     [613, 432],
     [617, 437],
     [626, 437],
     [677, 409]
    ]
   ]
  },
  {
   "color": "#0012ffff",
   "pois": [{"id": "centroid", "point": [803, 409]}],
   "box": [675, 350, 922, 478],
   "polygons": [
    [
     [922, 398],
     [865, 476],
     [862, 478],
     [857, 472],
     [857, 470],
     [847, 468],
     [839, 472],
     [816, 459],
     [807, 452],
     [802, 451],
     [789, 444],
     [776, 444],
     [775, 442],
     [758, 434],
     [751, 434],
     [746, 441],
     [721, 455],
     [675, 397],
     [733, 365],
     [739, 369],
     [749, 369],
     [783, 350],
     [819, 370],
     [829, 369],
     [841, 353],
     [921, 396]
    ]
   ]
  },
  {
   "color": "#fffe00ff",
   "pois": [{"id": "centroid", "point": [954, 501]}],
   "box": [866, 412, 1033, 603],
   "polygons": [
    [
     [1033, 465],
     [995, 603],
     [929, 566],
     [917, 553],
     [914, 553],
     [898, 534],
     [897, 524],
     [894, 523],
     [887, 512],
     [884, 501],
     [876, 498],
     [871, 501],
     [866, 495],
     [866, 483],
     [919, 412],
     [971, 440],
     [981, 439],
     [983, 437]
    ]
   ]
  }
 ],
 [
  {
   "color": "#08ff00ff",
   "pois": [{"id": "centroid", "point": [608, 609]}],
   "box": [571, 555, 652, 725],
   "polygons": [
    [
     [652, 587],
     [640, 592],
     [635, 592],
     [595, 610],
     [596, 623],
     [613, 725],
     [582, 654],
     [583, 650],
     [591, 647],
     [595, 639],
     [571, 584],
     [648, 555]
    ]
   ]
  },
  {
   "color": "#ffa200ff",
   "pois": [{"id": "centroid", "point": [611, 469]}],
   "box": [495, 401, 738, 546],
   "polygons": [
    [
     [738, 468],
     [534, 546],
     [499, 468],
     [495, 456],
     [585, 422],
     [589, 428],
     [598, 428],
     [635, 414],
     [639, 419],
     [647, 421],
     [703, 401]
    ]
   ]
  },
  {
   "color": "#0012ffff",
   "pois": [{"id": "centroid", "point": [828, 420]}],
   "box": [703, 358, 948, 500],
   "polygons": [
    [
     [948, 425],
     [868, 500],
     [820, 461],
     [817, 460],
     [809, 452],
     [798, 450],
     [796, 451],
     [789, 444],
     [779, 438],
     [770, 437],
     [762, 443],
     [737, 452],
     [703, 389],
     [765, 366],
     [767, 366],
     [773, 371],
     [783, 371],
     [813, 359],
     [819, 358],
     [851, 383],
     [861, 383],
     [876, 369],
     [942, 419]
    ]
   ]
  },
  {
   "color": "#fffe00ff",
   "pois": [{"id": "centroid", "point": [940, 532]}],
   "box": [841, 438, 1017, 639],
   "polygons": [
    [
     [1017, 486],
     [977, 639],
     [901, 578],
     [892, 576],
     [890, 578],
     [888, 577],
     [881, 569],
     [877, 568],
     [851, 547],
     [848, 539],
     [841, 531],
     [844, 522],
     [853, 513],
     [864, 503],
     [873, 503],
     [942, 438],
     [990, 475],
     [999, 475],
     [1001, 473]
    ]
   ]
  }
 ],
 [
  {
   "color": "#08ff00ff",
   "pois": [{"id": "centroid", "point": [586, 562]}],
   "box": [565, 548, 608, 577],
   "polygons": [
    [[608, 567], [596, 571], [579, 571], [578, 573], [570, 577], [565, 557], [605, 548]]
   ]
  },
  {
   "color": "#ffa200ff",
   "pois": [{"id": "centroid", "point": [628, 450]}],
   "box": [516, 396, 751, 513],
   "polygons": [
    [
     [751, 468],
     [542, 513],
     [537, 513],
     [516, 422],
     [519, 420],
     [608, 401],
     [611, 401],
     [614, 409],
     [662, 400],
     [665, 409],
     [667, 409],
     [730, 396]
    ]
   ]
  },
  {
   "color": "#0012ffff",
   "pois": [{"id": "centroid", "point": [851, 433]}],
   "box": [733, 370, 969, 518],
   "polygons": [
    [
     [969, 455],
     [874, 518],
     [822, 461],
     [814, 458],
     [811, 459],
     [807, 452],
     [803, 450],
     [803, 448],
     [797, 442],
     [787, 442],
     [783, 445],
     [757, 450],
     [753, 452],
     [734, 391],
     [733, 384],
     [797, 371],
     [801, 372],
     [807, 379],
     [811, 379],
     [852, 370],
     [881, 401],
     [889, 401],
     [907, 390]
    ]
   ]
  },
  {
   "color": "#fffe00ff",
   "pois": [{"id": "centroid", "point": [926, 559]}],
   "box": [833, 468, 992, 672],
   "polygons": [
    [
     [992, 504],
     [951, 672],
     [887, 599],
     [884, 596],
     [879, 596],
     [865, 582],
     [833, 544],
     [868, 521],
     [872, 524],
     [881, 521],
     [882, 519],
     [959, 468],
     [984, 493],
     [992, 502]
    ]
   ]
  }
 ]
]