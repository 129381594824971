import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { useSelector } from 'react-redux'
import FloorSelectSlider from './FloorSelectSlider'
import FloorSelectLabels from './FloorSelectLabels'
import { houseFloorsSelector } from '../../../../modules/reducerHouses'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: 20,
    right: 20,
    backgroundColor: theme.palette.primary.mainTransparent,
    display: 'flex',
    flexFlow: 'column',
    justifuContent: 'center',
    alignItems: 'center',
    width: 180
  },
  title: { color: theme.palette.primary.contrastText, paddingTop: 12 },
  fakeSliderWrapper: {
    boxSizing: 'border-box',
    padding: 20,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
    alignItems: 'stretch'
  }
}))

const FloorSelect = () => {
  const classes = useStyles()
  const houseFloors = useSelector(houseFloorsSelector)

  return houseFloors.length > 0 ? (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        Selecciona la planta
      </Typography>
      <div className={classes.fakeSliderWrapper}>
        <FloorSelectLabels />
        <FloorSelectSlider />
      </div>
    </div>
  ) : null
}
export default FloorSelect
//
