import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import GradientText from '../Common/GradientText'
const useStyles = makeStyles((theme) => ({
  root: {},
  title: { color: theme.palette.secondary.main },
  paper: { backgroundColor: theme.palette.primary.light },
  text: { color: theme.palette.primary.contrastText }
}))
const CFPrivacyPolicyDialog = ({ open, onClose }) => {
  const classes = useStyles()
  return (
    <Dialog classes={{ paper: classes.paper }} open={open} onClose={onClose}>
      <DialogTitle className={classes.title} disableTypography>
        <GradientText variant="h2">Política de privacidad.</GradientText>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.text}>
          {[...new Array(50)]
            .map(
              () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`
            )
            .join('\n')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default CFPrivacyPolicyDialog
