import { createSelector } from 'reselect'
import { dataSelector, DATA_TYPE, MEDIA_TYPE } from './reducerRead'
import { findById } from '../utils/utils'

export const GALLERY_SET_EXPANDED = 'GALLERY_SET_EXPANDED'
export const GALLERY_SET_CURRENT_GALLERY = 'GALLERY_SET_CURRENT_GALLERY'
export const GALLERY_SET_CURRENT_IMAGE_IDX = 'GALLERY_SET_CURRENT_IMAGE_IDX'

const initialState = {
  currentGallery: '',
  currentImageIdx: 0,
  expanded: false
}

const changeState = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case GALLERY_SET_EXPANDED:
      return { ...state, expanded: action.expanded }
    case GALLERY_SET_CURRENT_GALLERY:
      return { ...state, currentGallery: action.id, currentImageIdx: 0 }
    case GALLERY_SET_CURRENT_IMAGE_IDX:
      const length = action.gallery ? action.gallery.images.length : 0
      return {
        ...state,
        currentImageIdx: Math.min(Math.max(0, action.idx), length - 1)
      }

    default:
      return state
  }
}
export default changeState

export const setCurrentGallery = (id) => ({
  type: GALLERY_SET_CURRENT_GALLERY,
  id
})

export const setExpanded = (expanded) => ({
  type: GALLERY_SET_EXPANDED,
  expanded
})

export const setCurrentImageIdx = (idx) => (dispatch, getState) => {
  const state = getState()
  const gallery = getCurrentGallery(state)
  dispatch({
    type: GALLERY_SET_CURRENT_IMAGE_IDX,
    idx,
    gallery
  })
}

export const setNextImage = () => (dispatch, getState) => {
  const state = getState()
  const gallery = getCurrentGallery(state)
  dispatch({
    type: GALLERY_SET_CURRENT_IMAGE_IDX,
    idx: currentImageIdxSelector(state) + 1,
    gallery
  })
}

export const setPreviousImage = () => (dispatch, getState) => {
  const state = getState()
  const gallery = getCurrentGallery(state)
  dispatch({
    type: GALLERY_SET_CURRENT_IMAGE_IDX,
    idx: currentImageIdxSelector(state) - 1,
    gallery
  })
}

// export const imagesSelector = (state) => state.gallery.images
// export const galleriesSelector = (state) => state.gallery.galleries

export const imagesSelector = createSelector([dataSelector], (data) => {
  const media = findById(data, DATA_TYPE.MEDIA)

  return media
    ? media.data.reduce((acc, m) => {
        if (m.media_type === 'image') {
          acc.push({
            id: MEDIA_TYPE.IMAGE + m.id,
            name: m.title.rendered,
            src: m.source_url
          })
        }
        return acc
      }, [])
    : []
})

export const galleriesSelector = createSelector([dataSelector], (data) => {
  const galleries = findById(data, DATA_TYPE.GALLERY)

  return galleries
    ? galleries.data.map((g) => {
        return {
          id: DATA_TYPE.GALLERY + g.id,
          name: g.title.rendered,
          images: Object.keys(g.acf.images_relation).map(
            (key) => MEDIA_TYPE.IMAGE + g.acf.images_relation[key]
          )
        }
      })
    : []
})

export const expandedSelector = (state) => state.gallery.expanded
export const currentGallerySelector = (state) => state.gallery.currentGallery
export const currentImageIdxSelector = (state) => state.gallery.currentImageIdx

export const getImageById = () =>
  createSelector([imagesSelector, (_, id) => id], (images, id) => {
    return findById(images, id)
  })

const createGallery = (galleries, images, galleryId) => {
  const gallery = findById(galleries, galleryId)
  if (!gallery) {
    return null
  }
  const galleryImages = gallery.images.reduce((acc, imgId) => {
    const img = findById(images, imgId)
    if (img) {
      acc.push(img)
    }
    return acc
  }, [])
  return {
    id: galleryId,
    name: gallery.name,
    images: galleryImages
  }
}

export const getGallery = () =>
  createSelector(
    [galleriesSelector, imagesSelector, (_, id) => id],
    (galleries, images, id) => {
      return createGallery(galleries, images, id)
    }
  )

export const getCurrentGallery = createSelector(
  [galleriesSelector, imagesSelector, currentGallerySelector],
  (galleries, images, currentGallery) => {
    return createGallery(galleries, images, currentGallery)
  }
)
